import { MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bladeConnect, BladeProps, openBlade } from '../../../blade';
import {
  createWorkgroup,
  enterPickMode,
  cleanupCreateWorkgroup,
  fetchBrands,
  fetchTemplateLibraries,
} from '../../duck/actions';
import { areaName, WorkgroupCreateObject } from '../../duck/types';
import {
  selectPickSelectionWorkgroup,
  selectPickSelectionPathWorkgroup,
  selectPickSelectionTreeItemId,
  selectSavingState,
} from '../../duck/selectors';
import {
  WorkgroupCreate,
  WorkgroupCreateActions,
  WorkgroupCreateProps,
} from './workgroupCreate';
import { BladeActions } from '../../../blade';

import {
  SelectionListBladeType,
  selectedItems,
} from '../../../components/selectionBlade';
import i18n from 'i18next';
import { EntityType } from '../../../dataStore';
import { getClosingState } from '../../../blade/duck/selectors';
import { markWaitClose, markShouldClose } from '../../../blade/duck/actions';
import { WorkgroupListBladeType } from '../workgroupList';
import { BladeState } from '../../../blade/duck/types';
import { DataGridSelectionType } from '../../../components/dataGrid';

const mapStateToProps: MapStateToProps<
  WorkgroupCreateProps,
  BladeProps,
  any
> = (state, ownProps) => {
  const brandSelection = selectedItems(state, 'create_workgroup_brands');
  const templateLibrariesSelection = selectedItems(
    state,
    'create_workgroup_template_libraries'
  );
  return {
    workgroupParent: selectPickSelectionWorkgroup(state),
    brand:
      brandSelection && brandSelection.length > 0
        ? brandSelection[0]
        : undefined,
    templateItems: templateLibrariesSelection,
    workgroupParentPath: selectPickSelectionPathWorkgroup(state),
    workgroupParentTreeItemId: selectPickSelectionTreeItemId(state),
    ...selectSavingState(state),
    isClosing: getClosingState(state,ownProps.bladeId),
    isDirty: (state.blade as BladeState).items[ownProps.bladeId].isDirty
  };

};

const mapDispatchToProps: MapDispatchToProps<
  WorkgroupCreateActions,
  BladeProps
> = (dispatch, ownProps) => {
  return {
    selectTemplateLibraries: () => {
      openBlade(
        WorkgroupCreateBladeType,
        SelectionListBladeType,
        {
          title: i18n.t('select.templateLibraries'),
          entityType: EntityType.TemplateLibrary,
          selectionKey: 'create_workgroup_template_libraries',
          selectionType: DataGridSelectionType.Multiple
        },
        dispatch
      );
      dispatch<any>(fetchTemplateLibraries());
    },
    selectBrand: () => {
      openBlade(
        WorkgroupCreateBladeType,
        SelectionListBladeType,
        {
          title: i18n.t('select.brands'),
          entityType: EntityType.Brand,
          selectionKey: 'create_workgroup_brands',
          selectionType: DataGridSelectionType.Single
        },
        dispatch
      );
      dispatch<any>(fetchBrands());
    },
    closeBlade: () => {
      dispatch<any>(cleanupCreateWorkgroup());
      dispatch(BladeActions.closeBlade(WorkgroupCreateBladeType));
    },
    onBladeClose: () => dispatch<any>(cleanupCreateWorkgroup()),
    forceCloseBlade: () => dispatch(BladeActions.forceCloseBlade(WorkgroupCreateBladeType)),
    createWorkgroup: (
      wkCreateObj: WorkgroupCreateObject,
      treeId: string,
      createAnother: boolean
    ) => {
      dispatch<any>(createWorkgroup(wkCreateObj, treeId, createAnother));
    },
    toggleParentSelectionMode: () => dispatch<any>(enterPickMode(WorkgroupListBladeType)),
    setWaitClose: () => dispatch<any>(markWaitClose(ownProps.bladeId)),
    setShouldClose: () => dispatch<any>(markShouldClose(ownProps.bladeId)),
    setDirty: (dirty: boolean) => dispatch<any>(BladeActions.setBladeDirtyState(ownProps.bladeId, dirty))
  };
};

export const WorkgroupCreateBladeType = 'WORKGROUP_CREATE';

const bladeConfig = {
  size: { defaultWidth: 400, minWidth: 400 },
  bladeType: WorkgroupCreateBladeType,
  id: (_: any) => WorkgroupCreateBladeType,
  title: (_: any) => 'Create Workgroup',
  allowMultipleInstances: false,
};

export const WorkgroupCreateContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(WorkgroupCreate);
