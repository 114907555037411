import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) => createStyles({
  list: {
    overflowX: 'hidden',
    '&-mainToolbar': {
      flexGrow: 1,
      overflow: 'hidden'
    }
  },

  icon: { marginLeft: '8px'}
});

export const useStyles = makeStyles(styles);

