import { createSelector } from 'reselect';
import { getDataStore } from './genericSelectors';
import memoizeOne from 'memoize-one';
import { Site } from '../types';

export const selectSites = (state: any) => getDataStore(state).site.items;

export const selectSiteItems = createSelector(
  [selectSites],
  sites => {
    return Object.keys(sites).map(key => sites[Number(key)]);
  }
);

export const selectSitesForWorkgroups = createSelector(
  [selectSiteItems],
  (sites: Site[]) =>
    memoizeOne((workgroupIds: number[]) =>
      sites.filter(site => workgroupIds.indexOf(site.workgroupId) !== -1)
    )
);
