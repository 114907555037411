import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  UnfoldMore,
  UnfoldLess,
  CheckBox,
  CheckBoxOutlineBlank,
  Search,
  Favorite,
  Input
} from '@material-ui/icons';
import { useStyles } from './workgroupToolbar.jss';

export interface WorkgroupToolbarProps {
  workgroupPickMode: boolean;
  expandAll: () => void;
  collapseAll: () => void;
  selectAll: () => void;
  unselectAll: () => void;
  toggleSearch: () => void;
}

export const WorkgroupToolbar = (props: WorkgroupToolbarProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.workgroupsToolbar}>
      <Tooltip title={t('workgroups.expand.tooltip')}>
        <IconButton
          color="secondary"
          className={classes.workgroupsToolbarIconButton}
          onClick={props.expandAll}
        >
          <UnfoldMore fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('workgroups.collapse.tooltip')}>
        <IconButton
          color="secondary"
          className={classes.workgroupsToolbarIconButton}
          onClick={props.collapseAll}
        >
          <UnfoldLess fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('workgroups.select.tooltip')}>
        <IconButton
          disabled={props.workgroupPickMode}
          color="secondary"
          className={classes.workgroupsToolbarIconButton}
          onClick={props.selectAll}
        >
          <CheckBox fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('workgroups.unselect.tooltip')}>
        <IconButton
          disabled={props.workgroupPickMode}
          color="secondary"
          className={classes.workgroupsToolbarIconButton}
          onClick={props.unselectAll}
        >
          <CheckBoxOutlineBlank fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('search')}>
        <IconButton
          color="secondary"
          className={classes.workgroupsToolbarIconButton}
          onClick={props.toggleSearch}
        >
          <Search fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('favorites')}>
        <IconButton
          color="secondary"
          className={classes.workgroupsToolbarIconButton}
        >
          <Favorite fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('workgroups.export.tooltip')}>
        <IconButton
          color="secondary"
          disabled={props.workgroupPickMode}
          className={classes.workgroupsToolbarIconButton}
        >
          <Input fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};
