import Button from '@material-ui/core/Button';
import React from 'react';

export const FieldButton: React.FunctionComponent<{
  title: string;
  onClick: () => void;
}> = React.memo(props => (
  <Button variant="text" size="small" color="secondary" onClick={props.onClick}>
    {props.title}
  </Button>
));
