import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  }
}));
