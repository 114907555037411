import React from "react";
import { ContentWrapper } from "./contentWrapper";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LocationSearchFields } from "../../../duck/types";
import { useStyles } from "./tabs.jss";
import { TextFieldWrapper } from "./textFieldWrapper";
import { NCompany, IdName } from "../../../../dataStore";
import { Autocomplete } from "../../../../components/autocomplete";

export interface LocationTabProps {
  onChange(key: string, value: any): void;
  companies: IdName[];
}

export const LocationTab = (props: LocationSearchFields & LocationTabProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <ContentWrapper>
      <TextFieldWrapper
        value={props.locationNumber}
        onChange={e => props.onChange("locationNumber", e.currentTarget.value)}
        label={t("newnity.search.locationNumber")}
      />
      <TextFieldWrapper
        value={props.locationName}
        onChange={e => props.onChange("locationName", e.currentTarget.value)}
        label={t("newnity.search.locationName")}
      />
      <TextFieldWrapper
        value={props.deviceNumber}
        onChange={e => props.onChange("deviceNumber", e.currentTarget.value)}
        label={t("newnity.search.deviceNumber")}
      />
      <TextFieldWrapper
        value={props.serial}
        onChange={e => props.onChange("serial", e.currentTarget.value)}
        label={t("newnity.search.serial")}
      />
      <TextFieldWrapper
        value={props.siteNumber}
        onChange={e => props.onChange("siteNumber", e.currentTarget.value)}
        label={t("newnity.search.siteNumber")}
      />
      <FormControl className={classes.selectMargin}>
        <Autocomplete
          items={props.companies}
          initialValue={props.company}
          minCharInput={2}
          textFieldProps={{name: "something", label: t("newnity.search.company") }}
          onChange={(item: IdName) =>
            props.onChange("company", item ? item.name : '')
          }
        />
      </FormControl>
    </ContentWrapper>
  );
};
