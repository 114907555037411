import { MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  bladeConnect,
  BladeProps,
  openBlade,
  BladeActions,
  BladeConfig,
} from '../../../../blade';
import {
  selectCompanyId,
  selectSavingLocationData,
} from '../../../duck/selectors';
import {
  DataStoreSelectors,
  EntityType,
  NLocation,
} from '../../../../dataStore';
import {
  LocationEditActions,
  LocationEditProps,
  LocationEdit,
} from './locationEdit';
import { saveLocationData, fetchLocation } from '../../../duck/thunks';
import i18n from 'i18next';

export interface LocationEditContainerProps extends BladeProps {
  locationSiteId?: number;
  locationName?: string;
}

let emptyLocation: NLocation = {
  address: '',
  city: '',
  clientSiteId: '',
  country: '',
  id: 0,
  name: '',
  phoneNumber: '',
  oracleNumber: '',
  state: '',
  timezone: '',
  workgroupId: 0,
  zipCode: '',
  deviceSettings: {
    enableSoundVolumeOverride: false,
    audioVolume: '',
    enableNetworkSettingsOverride: false,
    enableWifi: false,
    enableEthernet: false,
    enableProxy: false,
  },
};

const getLocation = (state: any, id: number | undefined) => {
  if (!id) {
    return {...emptyLocation};
  }
  
  let location = 
    DataStoreSelectors.NLocation.selectLocationById(state, id);

  if (location === undefined) {
    location = {...emptyLocation};
    location.id = id;
  }

  return location;
}

const mapStateToProps: MapStateToProps<
  LocationEditProps,
  LocationEditContainerProps,
  any
> = (state, ownProps) => {
  return {
    location: getLocation(state, ownProps.locationSiteId),
    companyId: selectCompanyId(state),
    isSaving: selectSavingLocationData(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  LocationEditActions,
  LocationEditContainerProps
> = (dispatch, ownProps) => {
  return {
    configureWiFiSettings: () =>
      openBlade(LocationEditBladeType, '', {}, dispatch),
    createLocation: (companyId: number, location: NLocation) => {
      dispatch<any>(saveLocationData(companyId, location, bladeId(ownProps)));
    },
    fetchLocation: id => {
      dispatch<any>(fetchLocation(id));
    },
    closeBlade: () => dispatch(BladeActions.forceCloseBlade(bladeId(ownProps))),
  };
};

export const LocationEditBladeType = 'NEWNITY_LOCATION_EDIT';

const bladeId = (ownProps: LocationEditContainerProps) =>
  ownProps.locationSiteId
    ? `${LocationEditBladeType}_${ownProps.locationSiteId}`
    : LocationEditBladeType;

const bladeConfig: BladeConfig<LocationEditContainerProps> = {
  size: { defaultWidth: 400, minWidth: 400 },
  bladeType: LocationEditBladeType,
  id: bladeId,
  title: ownProps =>
    ownProps.locationName
      ? ownProps.locationName
      : i18n.t('newnity.edit.location.blade.create.title'),
  allowMultipleInstances: false,
};

export const LocationEditContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(LocationEdit);
