import i18next from 'i18next';
import { Dispatch } from 'redux';
import { openBlade } from '../../blade';
import { closeBlade, setTitle, setBladeDirtyState } from '../../blade/duck/actions';
import { pushNotification } from '../../components/notifier';
import { CompanyBladeType } from '../blades/company';
import { NewnityApi } from './api';
import { initialSearchFields } from './reducer';
import { NewnityState, SearchEntity, SearchFields } from './types';
import {
  DataStoreActions,
  EntityType,
  NLocation,
  NCompany,
  DataStoreSelectors,
} from '../../dataStore';
import {
  searchSetSearchedEntity,
  companyDataFetchRequest,
  companyDataFetchSuccess,
  companyDataFetchError,
  locationSaveRequest,
  locationSaveSuccessful,
  locationSaveError,
  companySaveRequest,
  companySaveSuccess,
  companySaveError,
  locationDeleteRequest,
  locationDeleteSuccess,
  locationDeleteError,
} from './actions';
import i18n from '../../../localization/i18n';

export function fetchCompanies() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.NCompany));
    try {
      const companies = await NewnityApi.search(
        initialSearchFields,
        SearchEntity.Company
      );
      dispatch(
        DataStoreActions.entityListSuccess(EntityType.NCompany, companies)
      );
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NCompany, err));
      dispatch(pushNotification(`Could not fetch companies: ${err}`, 'error'));
    }
  };
}
export function fetchCompanyInfo(companyId: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(companyDataFetchRequest());
    try {
      const companyInfo = await NewnityApi.getCompanyInfo(companyId);
      const updatedCompany = NewnityApi.dtoToCompany(companyInfo);
      dispatch(
        DataStoreActions.entityUpsert(EntityType.NCompany, updatedCompany)
      );
      dispatch(companyDataFetchSuccess());
    } catch (err) {
      dispatch(companyDataFetchError(err));
      dispatch(
        pushNotification(`Could not fetch company info: ${err}`, 'error')
      );
    }
  };
}

export function saveCompanyInfo(companyData: NCompany) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(companySaveRequest());
    try {
      const createMode = companyData.id === 0;
      const companyId = await NewnityApi.saveCompanyInfo(
        companyData,
        createMode
      );
      const updatedCompany = { ...companyData, id: companyId };
      
      dispatch( DataStoreActions.entityUpsert(EntityType.NCompany, updatedCompany));
      dispatch(companySaveSuccess(companyId));
      
      dispatch(setTitle(CompanyBladeType, updatedCompany.name));
      dispatch(setBladeDirtyState(CompanyBladeType, false));

      const notificationMessageKey = createMode
        ? 'newnity.edit.company.toast.create.success'
        : 'newnity.edit.company.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));
    } catch (err) {
      dispatch(companySaveError(err));
      dispatch(
        pushNotification(`Could not save company info: ${err}`, 'error')
      );
    }
  };
}

export function openCompanyEditBlade(
) {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const state = getState() as { newnity: NewnityState };
    const companyId = state.newnity.currentCompany.companyId;
    const fetchingData = state.newnity.currentCompany.fetchingData;
    const title = companyId === 0 
      ? i18n.t('newnity.edit.company.blade.create.title')
      : DataStoreSelectors.getDataStoreItems(state, EntityType.NCompany)[companyId].name;

    openBlade('', CompanyBladeType, { title }, dispatch);

    if ( companyId > 0 && !fetchingData) {
      dispatch(fetchCompanyInfo(companyId));
    }
  };
}

export const fetchLocations = (companyId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.NLocation));
    try {
      const locations = await NewnityApi.getLocations(companyId);
      dispatch(
        DataStoreActions.entityListSuccess(EntityType.NLocation, locations)
      );
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NLocation, err));
      dispatch(pushNotification(`Could not fetch locations: ${err}`, 'error'));
    }
  };
};

export const fetchLocation = (id: number) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const location = await NewnityApi.getLocation(id);
      dispatch(
        DataStoreActions.entityUpsert(EntityType.NLocation, location)
      );
    } catch (err) {
      dispatch(pushNotification(`Could not fetch location: ${err}`, 'error'));
    }
  };
};

export const fetchDevices = (companyId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(EntityType.NDevice));
    try {
      const devices = await NewnityApi.getDevices();
      dispatch(DataStoreActions.entityListSuccess(EntityType.NDevice, devices));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(EntityType.NDevice, err));
      dispatch(pushNotification(`Could not fetch devices: ${err}`, 'error'));
    }
  };
};

export const search = (fields: SearchFields, entity: EntityType) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(DataStoreActions.entityListRequest(entity));
    dispatch(searchSetSearchedEntity(entity));
    try {
      const results = await NewnityApi.search(
        fields,
        entityToSearchEntity(entity)
      );
      dispatch(DataStoreActions.entityListSuccess(entity, results));
    } catch (err) {
      dispatch(DataStoreActions.entityListError(entity, err));
      dispatch(
        pushNotification(`Could not fetch search results: ${err}`, 'error')
      );
    }
  };
};

const entityToSearchEntity = (entity: EntityType) => {
  switch (entity) {
    case EntityType.NCompanySearchResult:
      return SearchEntity.Company;
    case EntityType.NLocationSearchResult:
      return SearchEntity.Location;
    case EntityType.NDeviceSearchResult:
      return SearchEntity.Device;
    default:
      return SearchEntity.Company;
  }
};

export const saveLocationData = (
  companyId: number,
  location: NLocation,
  bladeId?: string
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(locationSaveRequest(companyId, location));
      const createMode = location.workgroupId === 0;
      const savedLocation = await NewnityApi.saveLocation(
        companyId,
        location,
        createMode
      );
      dispatch(
        DataStoreActions.entityUpsert(EntityType.NLocation, savedLocation)
      );
      dispatch(locationSaveSuccessful(companyId, savedLocation));

      const notificationMessageKey = createMode
        ? 'newnity.edit.location.toast.create.success'
        : 'newnity.edit.location.toast.success';
      dispatch(pushNotification(i18next.t(notificationMessageKey), 'success'));
      if (bladeId) {
        dispatch(closeBlade(bladeId));
      }
    } catch (err) {
      dispatch(locationSaveError(companyId, location, err));
      dispatch(
        pushNotification(`Could not fetch search results: ${err}`, 'error')
      );
    }
  };
};

export const deleteLocation = (locationId: number, locationName: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(locationDeleteRequest(locationId, locationName));
      const status = await NewnityApi.deleteLocation(locationId);
      if (status === 200) {
        dispatch(DataStoreActions.entityDelete(EntityType.NLocation, {id: locationId, name: locationName}));
        dispatch(locationDeleteSuccess(locationId, locationName));
      } else {
        dispatch(locationDeleteError(locationId, locationName, ''));
        dispatch(
          pushNotification(`Could not delete location ${locationName}`, 'error')
        );
      }
    } catch (err) {
      dispatch(locationDeleteError(locationId, locationName, err));
      dispatch(
        pushNotification(
          `Could not delete location ${locationName}: ${err}`,
          'error'
        )
      );
    }
  };
};
