import { MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bladeConnect, BladeProps, openBlade } from '../../../blade';
import {
  fetchWorkgroups,
  setWorkgroupAsFavorite,
  removeWorkgroupAsFavorite,
  leavePickMode,
  cleanupCreateWorkgroup,
} from "../../duck/actions";
import {
  selectFavoriteWorkgroups,
  selectPickSelectionMode,
} from '../../duck/selectors';
import { DataStoreSelectors, EntityType } from '../../../dataStore';
import { WorkgroupDetailsBladeName } from '../workgroupDetails/workgroupDetailsContainer';
import {
  WorkgroupList,
  WorkgroupListActions,
  WorkgroupListProps,
  WORKGROUP_TREE_NAME,
} from './workgroupList';
import { areaName } from '../../duck/types';
import { TreeActions } from '../../../components/tree';

const mapStateToProps: MapStateToProps<WorkgroupListProps, BladeProps, any> = (
  state,
  _
) => {
  return {
    itemsFetching: DataStoreSelectors.getDataStoreItemsFetching(state, EntityType.Workgroup).isFetching,
    pickSelectionModeOn: selectPickSelectionMode(state),
    favoriteItems: selectFavoriteWorkgroups(state),
    filterValue: state[areaName][WORKGROUP_TREE_NAME].filterValue,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  WorkgroupListActions,
  BladeProps
> = (dispatch, _) => {
  return {
    fetchWorkgroups: () => dispatch<any>(fetchWorkgroups()),
    openBladeDetails: (workgroupId: number, workgroupName: string) =>
      openBlade(
        WorkgroupListBladeType,
        WorkgroupDetailsBladeName,
        {
          workgroupId,
          workgroupName,
        },
        dispatch
      ),
    openBladeCreate:  (type: string, props: object) => openBlade(WorkgroupListBladeType, type, props, dispatch),
    setWorkgroupAsFavorite: workgroupId => dispatch<any>(setWorkgroupAsFavorite(workgroupId)),
    removeWorkgroupAsFavorite: workgroupId => dispatch<any>(removeWorkgroupAsFavorite(workgroupId)),
    expandAll: () => dispatch(TreeActions.expandAll(areaName, WORKGROUP_TREE_NAME)),
    collapseAll: () => dispatch(TreeActions.collapseAll(areaName, WORKGROUP_TREE_NAME)),
    selectAll: () => dispatch(TreeActions.selectAll(areaName, WORKGROUP_TREE_NAME)),
    unselectAll: () => dispatch(TreeActions.unselectAll(areaName, WORKGROUP_TREE_NAME)),
    filter: value => dispatch(TreeActions.filter(areaName, WORKGROUP_TREE_NAME, value)),
    leavePickMode: (workgroupId: number, treeItemId: string) => dispatch<any>(leavePickMode(workgroupId, treeItemId)),
    onBladeClose: () => dispatch<any>(cleanupCreateWorkgroup()),
  };
};

export const WorkgroupListBladeType = 'WORKGROUP_LIST';

const bladeConfig = {
  size: { defaultWidth: 400, minWidth: 400 },
  bladeType: WorkgroupListBladeType,
  id: (_: any) => WorkgroupListBladeType,
  title: (_: any) => 'Workgroups',
  allowMultipleInstances: false,
};

export const WorkgroupListContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(WorkgroupList);
