import { createSelector } from 'reselect';
import { areaName, CurrentCompanyState, NewnityState } from './types';

export const selectNewnityState = (state: any): NewnityState =>
  state[areaName] as NewnityState;

export const selectCurrentCompanyInfo = createSelector(
  [selectNewnityState],
  (state: NewnityState) => state.currentCompany
);

export const selectCompanyDataIsFetching = createSelector(
  [selectCurrentCompanyInfo],
  (state: CurrentCompanyState) => state.fetchingData
);

export const selectCompanyIsSaving = (state: any) => selectNewnityState(state).currentCompany.isSaving;

// export const selectCompanyIsSaving = createSelector(
//   [selectCurrentCompanyInfo],
//   (state: CurrentCompanyState) => state.isSaving
// );

export const selectCompanySavingError = createSelector(
  [selectCurrentCompanyInfo],
  (state: CurrentCompanyState) => state.savingError
);

export const selectCompanyId = createSelector(
  [selectNewnityState],
  state => state.currentCompany.companyId
);
export const selectSavingLocationData = createSelector(
  [selectNewnityState],
  state => state.currentLocation.savingData
);
export const selectDeletingLocation = createSelector(
  [selectNewnityState],
  state => state.currentLocation.deletingLocation
);
