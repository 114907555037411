import Switch from "@material-ui/core/Switch";
import React, { useState } from "react";
import {
  TabItem,
  TabsHeaderAndContent
} from "../../../components/bladeLayout/tabsHeaderAndContent";
import { Workgroup } from "../../../dataStore";
import { useStyles } from "./workgroupDetails.jss";

export interface WorkgroupDetailsProps {
  workgroup: Workgroup;
}

export interface WorkgroupDetailsActions {
  fetchWorkgroupUsers: () => void;
  setDirtyState: (isDirty: boolean) => void;
}

type Props = WorkgroupDetailsProps & WorkgroupDetailsActions;

export const WorkgroupDetails: React.FunctionComponent<Props> = props => {
  const [dirty, setDirty] = useState(false);
  const classes = useStyles();

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setDirty(checked);
    props.setDirtyState(checked);
  };

  const tabs: TabItem[] = ["Properties", "Users", "Sites", "Hardware"].map<
    TabItem
  >(label => ({ label }));
  const content = (selectedTabIndex: number): JSX.Element => {
    switch (selectedTabIndex) {
      case 0:
        return (
          <div className={classes.workgroup}>
            <p>{props.workgroup.name}</p>
            <p>{props.workgroup.id}</p>
            <p>{props.workgroup.parentId}</p>
            <Switch checked={dirty} onChange={handleChange} value="isDirty" />
          </div>
        );

      default:
        return <div>{tabs[selectedTabIndex].label}</div>;
    }
  };
  return <TabsHeaderAndContent tabs={tabs} content={content} />;
};
