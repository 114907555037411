import React from 'react';
import { ContentWrapper } from './contentWrapper';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DeviceSearchFields } from '../../../duck/types';
import { useStyles } from './tabs.jss';
import { TextFieldWrapper } from './textFieldWrapper';

export interface DeviceTabProps {
  onChange(key: string, value: any): void;
}

export const DeviceTab = (
  props: DeviceSearchFields & DeviceTabProps
) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <ContentWrapper>
      <TextFieldWrapper value={props.deviceNumber}
        onChange={e => props.onChange('deviceNumber', e.currentTarget.value)}
        label={t('newnity.search.deviceNumber')}
        >
      </TextFieldWrapper>
      <TextFieldWrapper
        value={props.deviceDescription}
        onChange={e =>
          props.onChange('deviceDescription', e.currentTarget.value)
        }
        label={t('newnity.search.deviceDescription')}
      />
      <TextFieldWrapper
        value={props.serial}
        onChange={e => props.onChange('serial', e.currentTarget.value)}
        label={t('newnity.search.serial')}
      />
      <TextFieldWrapper
        value={props.locationNumber}
        onChange={e => props.onChange('locationNumber', e.currentTarget.value)}
        label={t('newnity.search.locationNumber')}
      />
      <TextFieldWrapper
        style={{ fontSize: '12px' }}
        value={props.locationName}
        onChange={e => props.onChange('locationName', e.currentTarget.value)}
        label={t('newnity.search.locationName')}
      />
      <TextFieldWrapper
        value={props.siteNumber}
        onChange={e => props.onChange('siteNumber', e.currentTarget.value)}
        label={t('newnity.search.siteNumber')}
      />
      <FormControl className={classes.selectMargin}>
        <InputLabel>{t('newnity.search.company')}</InputLabel>
        <Select
          value={props.company}
          onChange={e => props.onChange('company', e.target.value)}
          autoComplete="something"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'10'}>Ten</MenuItem>
          <MenuItem value={'20'}>Twenty</MenuItem>
          <MenuItem value={'30'}>Thirty</MenuItem>
        </Select>
      </FormControl>
      <TextFieldWrapper
        value={props.salesOrder}
        onChange={e => props.onChange('salesOrder', e.currentTarget.value)}
        label={t('newnity.search.salesOrder')}
      />
    </ContentWrapper>
  );
};
