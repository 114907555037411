import { createSelector } from 'reselect';
import { getDataStore } from './genericSelectors';
import memoizeOne from 'memoize-one';
import { Workgroup, EntityType, NLocation, EntityState } from '../types';

export const selectLocations = (state: any) =>
  getDataStore(state)[EntityType.NLocation];

export const selectLocationItems = createSelector(
  [selectLocations],
  locations => {
    return Object.keys(locations.items).map<NLocation>(
      key => locations.items[Number(key)]
    );
  }
);

export const selectLocationById = (state: any, locationId: number) => selectLocations(state).items[locationId];
