import './muiBootstrap';
import * as App from './app';
import * as serviceWorker from './serviceWorker';
import IMVisionAdmin from './app/v6/types';
import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import {
  applyMiddleware,
  combineReducers,
  createStore,
  Unsubscribe
  } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import './localization/i18n';
import { initObservers as initNewnityObservers } from './app/stateObservers/newnity';

// tslint:disable-next-line:no-implicit-dependencies

const DEV_ENV_NEWNITY_ENABLED = true;

const createDefaultStore = (newnityEnabled: boolean) => {
  App.Newnity.setInitialState(newnityEnabled);
  
  return createStore(
    combineReducers({
      [App.DataStore.areaName]: App.DataStore.reducer,
      [App.Notifier.areaName]: App.Notifier.reducer,
      [App.Blade.areaName]: App.Blade.reducer,
      [App.Workgroup.areaName]: App.Workgroup.reducer,
      [App.Newnity.areaName]: App.Newnity.reducer,
      [App.Selection.areaName]: App.Selection.reducer
    }),
    composeWithDevTools(applyMiddleware(thunk))
  );
}

const defaultStore = createDefaultStore(DEV_ENV_NEWNITY_ENABLED);
let newnityUnsubscribe: Unsubscribe;

export const MAD: IMVisionAdmin = {
    open: (rootNodeId: string = 'root', newnityEnabled: boolean = false) => {
      const store = createDefaultStore(newnityEnabled);
      startReact(rootNodeId, store);
    },
    homeFn: () => {}
};

  // wrapper for ReactDOM.render() call
  const startReact = (rootNode = 'root', appStore = defaultStore) => {

    // unsubscribe from state listeners just in case...
    if (newnityUnsubscribe) {
      newnityUnsubscribe();
    }

    newnityUnsubscribe = initNewnityObservers(appStore);
    ReactDOM.render((
        <Provider store={appStore}>
            <App.AppComponent />
        </Provider>
    ), document.getElementById(rootNode));
  };
  
  // we can remove this after we migrate mvision-html/MVision.UI to webpack
  (() => {

    interface IWindow extends Window {
      mvision: {admin: {MAD_REQUIRED: boolean}};
      MAD: IMVisionAdmin;
    }
  
    const localWindow = window as IWindow;
  
    const setGlobalMAD = () => {
      // set AP as a global property
      localWindow.MAD = MAD;
    };
  
    const onDOMContentLoaded = (event: Event) => {
      // remove the event listener
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);
  
      const mvisionLoaded: boolean =
        typeof localWindow.mvision === 'object' &&
        typeof localWindow.mvision.admin === 'object' &&
        localWindow.mvision.admin.MAD_REQUIRED;
  
      if (!mvisionLoaded) {
        // fallback to the "natural" react code
        startReact();

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: http://bit.ly/CRA-PWA
        serviceWorker.unregister();
        return;
      }
  
      setGlobalMAD();
    };
  
    /**
     * If the document and the mvision.services namespace are already loaded
     * we set the global AP object...
     */
    if (document.readyState === 'complete') {
      const mvisionLoaded: boolean =
        typeof localWindow.mvision === 'object' &&
        typeof localWindow.mvision.admin === 'object' &&
        localWindow.mvision.admin.MAD_REQUIRED;
  
      if (mvisionLoaded) {
        setGlobalMAD();
      }
    } else {
      // ...otherwise we listen for the document to be ready
      document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
    }
  })();

