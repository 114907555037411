import React from "react";
import { SelectedItem, CheckboxState } from "./duck/types";
import CheckboxComponent from "@material-ui/core/Checkbox";
import {
  CheckBox,
  IndeterminateCheckBox,
  IndeterminateCheckBoxOutlined,
  CheckBoxOutlined,
} from "@material-ui/icons";

interface SelectionCheckboxProps {
  id: string;
  item: SelectedItem;
}

export const SelectionCheckbox = ({ item, id }: SelectionCheckboxProps) => {
  const color = item && item.selected ? "secondary" : "primary";

  return (
    <CheckboxComponent
      disableRipple={true}
      color={color}
      checkedIcon={getIcon(item)}
      checked={item !== undefined}
      style={{ paddingLeft: '8px' }}
    />
  );
};

const getIcon = (item: SelectedItem) => {
  if (!item) {
    return <CheckBox />;
  }

  switch (item.state) {
    case CheckboxState.SelectedWithChildren:
      return item.isIndeterminate ? <IndeterminateCheckBox /> : <CheckBox />;
    case CheckboxState.Selected:
      return item.isIndeterminate ? (
        <IndeterminateCheckBoxOutlined />
      ) : (
        <CheckBoxOutlined />
      );
  }
};
