import { TreeState, TreeItem, NormalizedTreeItems } from "../types";
import { denormalize } from "../../../../utils/extensions";
import { batchUpdate, getExpandedOfTree } from "./treeExpansion";

export const filter = (state: TreeState, filterValue: string): TreeState =>
    filterValue ?
      applyFilter(state, filterValue) :
      clearFilter(state);

const clearFilter = (state: TreeState): TreeState => {
  if (state.filterValue === '') {
    return state;
  }

  clearMatchProperties(denormalize(state.items));
  const nextState = batchUpdate(state, state.expandedBeforeFilter);

  return {...nextState,
    filterValue: '',
    expandedBeforeFilter: []
  };
}

const applyFilter = (state: TreeState, filterValue: string): TreeState => {
  const normalizedItems = state.items;
  const items = denormalize(normalizedItems);
  clearMatchProperties(items);
  
  const previousExpanded = getExpandedOfTree(state);
  const matches = match(items, filterValue);
  const nextExpanded = findParentsOfMatches(normalizedItems, matches);
  let nextState = batchUpdate(state, nextExpanded);

  nextState.filterValue = filterValue;
  if (state.filterValue === '') {
    nextState.expandedBeforeFilter = [...previousExpanded];
  }
  return nextState;
}

/**
 * Do not use outside of module.
 * Only exported for testing purposes.
 */
export const match = (items: TreeItem[], filterValue: string): TreeItem[] => {
  const value = filterValue.toLowerCase();
  
  return items.reduce((o, item) => {
    const matchIndex = item.label.toLowerCase().indexOf(value);
    
    if (matchIndex !== -1) {
      item.match = [matchIndex, matchIndex + value.length - 1]
      o.push(item);
    }
    
    return o;
  }, [] as TreeItem[]);
};

/**
 * Do not use outside of module.
 * Only exported for testing purposes.
 */
export const findParentsOfMatches = (normalizedItems: NormalizedTreeItems, matches: TreeItem[]): string[] => {
  let result = []; 
  
  for (let i = 0; i < matches.length; i++) {
    const item = matches[i];
    
    if (!item.treeParentId) {
      continue;
    }
    
    const parent = normalizedItems[item.treeParentId];
    parent.hasChildrenMatched = true;
    if (result.indexOf(parent.treeId) === -1) {
      result.push(parent.treeId);
    }

    matches.push(parent);
  }

  return result.sort();
};

const clearMatchProperties = (items: TreeItem[]) => {
  items.forEach(item => {
    item.match = null;
    item.hasChildrenMatched = false;
  });
};