import React from 'react';
import { Header } from './header';
import { useStyles } from './headerAndContent.jss';
import classNames from 'classnames';
import { Footer } from './footer';
import { CircularProgress } from '@material-ui/core';

export interface HeaderAndContentProps {
  header?: () => JSX.Element;
  content: () => JSX.Element;
  footer?: () => JSX.Element;
  disabled?: boolean;
  isBusy?: boolean;
  removePadding?: boolean;
}

export const HeaderAndContent = (props: HeaderAndContentProps) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, {[classes.disabled]: props.disabled || props.isBusy})}>
      <Header>{props.header && props.header()}</Header>
      <div
        className={classNames(classes.content, {
          [classes.contentWithPadding]: !props.removePadding,
        })}
      >
        {props.isBusy && <CircularProgress className={classes.loadingProgress} size={60} />}
        {props.content()}
      </div>
      {props.footer && <Footer>{props.footer()}</Footer>}
    </div>
  );
};
