import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) =>
createStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "sticky",
    bottom: 0,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    
    marginTop: theme.spacing.unit * 2,
    minHeight: "48px",
    boxSizing: 'content-box'
  },
  containerExtendMargins: {
    marginLeft: -theme.spacing.unit * 2,
    marginRight: -theme.spacing.unit * 2,
  },
  stickyHelper: {
    height: "1px",
    bottom: 0
  },
});
export const useStyles = makeStyles(styles);
