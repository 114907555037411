import React, { RefObject } from 'react';
import {
  connect,
  InferableComponentEnhancerWithProps,
  MapDispatchToProps,
  MapStateToProps,
  MergeProps,
} from 'react-redux';
import { Dispatch } from 'redux';
import { createBlade } from './actions';
import { BladeConfig, BladeInstance, BladeProps } from './types';

interface BladeCache {
  [bladeType: string]: {
    componentFactory: React.ComponentFactory<
      any,
      React.Component<any, any, any>
    >;
    config: BladeConfig<any>;
  };
}

const bladeCache: BladeCache = {};

export const cache = (): BladeCache => bladeCache;

export const registerBladeType = <TProps>(
  config: BladeConfig<TProps>,
  component: any
) => {
  bladeCache[config.bladeType] = {
    componentFactory: React.createFactory(component),
    config,
  };
};

export const openBlade = <T = {}>(
  parentBladeId: string,
  childBladeType: string,
  childBladeInstanceProps: T,
  dispatch: Dispatch
) => {
  const blade = bladeCache[childBladeType];
  if (!blade) {
    throw new Error(`No blade defined for type ${childBladeType}`);
  }

  const id = blade.config.id(childBladeInstanceProps);
  const title = blade.config.title(childBladeInstanceProps);
  dispatch(
    createBlade({
      parentId: parentBladeId,
      id,
      type: childBladeType,
      props: childBladeInstanceProps,
      isDirty: false,
      title,
      newlyCreated: true,
      isClosing: false,
      shouldClose: false,
      waitOnClose: false,
      closeParentId: '',
      frozen: false,
    })
  );
};

export const getBladeContent = (
  bladeInfo: BladeInstance,
  setBladeDirty: (bladeId: string, dirty: boolean) => void,
  onClose: (canClose: () => boolean) => void
) => {
  const bladeType = bladeCache[bladeInfo.type];
  if (!bladeType) {
    throw new Error(`No blade defined for type ${bladeInfo.type}`);
  }
  const component = bladeType.componentFactory({
    ...bladeInfo.props,
    bladeId: bladeInfo.id,
    onClose,
    isDirty: bladeInfo.isDirty,
    setDirty: (dirty: boolean) => setBladeDirty(bladeInfo.id, dirty),
  });
  return { component, config: bladeType.config };
};

export const bladeConnect = <TProps, TActions, TOwnProps extends BladeProps>(
  mapStateToProps: MapStateToProps<TProps, TOwnProps, any>,
  mapDispatchToProps: MapDispatchToProps<TActions, TOwnProps>,
  bladeConfig: BladeConfig<TOwnProps>
) => {
  const mergeProps: MergeProps<
    TProps,
    TActions,
    TOwnProps,
    TProps & TActions & BladeProps
  > = (stateProps, dispatchProps, ownProps) => {
    const mergedProps = {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
    };
    return mergedProps;
  };
  const connectWithRegisterBlade: InferableComponentEnhancerWithProps<
    TProps & TActions & BladeProps,
    TOwnProps
  > = component => {
    const containerComponent = connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps,
      { forwardRef: false }
    )(component);
    registerBladeType(bladeConfig, containerComponent);
    return containerComponent;
  };
  return connectWithRegisterBlade;
};
