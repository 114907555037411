import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) =>
  createStyles({
    blade: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      position: "relative"
    },
    paper: {
      height: "100%"
    },
    content: {
      flexGrow: 1
    },
    dragHandle: {
      width: "4px",
      height: "100%",
      zIndex: 100,
      cursor: "col-resize",
      right: "-2px",
      position: "absolute",
      top: 0,
      "&:hover": {
        backgroundColor: theme.palette.grey[500],
      }
    },
    dragHandleActive: {
      zIndex: 100,
      backgroundColor: theme.palette.grey[500],
    },
    dragHandleActiveLessThanMin: {
      backgroudColor: "rgba(255, 0, 0, 0.2)"
    }
  });

export const errorBoundaryStyles = (theme: Theme) =>
  createStyles({
    error: {
      background: theme.palette.error.dark,
      display: "flex",
      alignItems: "center"
    },
    icon: {
      color: "#ffffff",
      marginLeft: theme.spacing.unit * 2
    },
    message: {
      color: "#ffffff",
      marginLeft: theme.spacing.unit
    },
    stack: {
      color: "#ffffff",
      marginLeft: theme.spacing.unit * 6
    }
  });

export interface ErrorBoundaryStylesProps {
  classes: {
    error: string;
    icon: string;
    message: string;
    stack: string;
  };
}

export const useStyles = makeStyles(styles);