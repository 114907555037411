import React from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import { TextField } from "@material-ui/core";

export const TextFieldWrapper = (props: TextFieldProps) => {
  return ( 
    <TextField
      margin="normal"
      inputProps={{maxLength: 200}}
      autoComplete="something"
      {...props}
    />
  )
};