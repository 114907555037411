import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = () =>
  createStyles({
    bladeContainer: {
      display: 'flex',
      alignItems: 'stretch',
      flexGrow: 1,
      overflowX: 'auto',
      overflowY: 'hidden',
      scrollBehavior: 'smooth'
    },
    '@keyframes fade': {
      from: { opacity: 1 },
      to: { opacity: 0.4 }
    },
    faded: {
      animationName: '$fade',
      animationDuration: '0.5s',
      animationFillMode: 'forwards',
      pointerEvents: "none"
    },
    bufferSpace: {
      flexGrow: 1,
      flexBasis: '200px',
      width: '200px'
    }
  });

export const useStyles = makeStyles(styles);
