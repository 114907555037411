import {
  BladeInstance,
  BladeState,
  areaName,
  NormalizedBlades
} from "./types";
import { denormalize } from "../../utils/extensions";

export const selectActiveBlades = (state: any): NormalizedBlades =>
  (state[areaName] as BladeState).items;

export const selectActiveBladesDenormalized = (state: any): BladeInstance[] => {
  const items = selectActiveBlades(state);
  return denormalize(items); 
};

export const selectBladeById = (state: any, bladeId: string): BladeInstance => {
  const items = selectActiveBlades(state);
  return items[bladeId];
};

export const getClosingState = (state: any, bladeId: string): boolean => {
  const items = selectActiveBlades(state);
  return items[bladeId].isClosing;
};

export const selectFrozenBlades = (state: any) => {
  const bladeState = state[areaName] as BladeState;
  const items = selectActiveBladesDenormalized(state);
  return items.filter(b=>b.frozen).map(b=>b.id);
}

export const selectBladeClosing = (state: any) => {
  const bladeState = state[areaName] as BladeState;

  return {
    bladeClosing: {
      bladeToClose: bladeState.items[bladeState.dirtyClosing.bladeIdForClosing],
      dirtyChildren: bladeState.dirtyClosing.dirtyChildrenIds.map(b => bladeState.items[b])
    }
  };
};

export const selectBladeDirtyState = (state: any, bladeId: string): boolean => {
  const blade = selectBladeById(state, bladeId);
  return blade.isDirty;
}


