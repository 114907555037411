import { List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./toolbar.jss";
import { WorkgroupListBladeType } from "../../workgroup/blades/workgroupList";
import { GroupWork, People, Home, Router } from "@material-ui/icons";
import classNames from "classnames";

export interface AdminAppToolbarActions {
  openBlade: (bladeType: string, props: object) => void;
  exitApp: () => void;
}

export const AdminAppToolbar = (props: AdminAppToolbarActions) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <React.Fragment>
      <List>
        <ListItem
          className={classes.list}
          button
          key={'home'}
          onClick={() => props.exitApp()}
        >
          <ListItemIcon>
            <Home className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={t('home')} />
        </ListItem>
      </List>
      <Divider />
      <List className={classNames(classes.list, `${classes.list}-mainToolbar`)}>
        <ListItem
          button
          key={'workgroups'}
          onClick={() => props.openBlade(WorkgroupListBladeType, {})}
        >
          <ListItemIcon className={classes.icon}>
            <GroupWork />
          </ListItemIcon>
          <ListItemText primary={t('workgroups')} />
        </ListItem>
        <ListItem button key={'users'}>
          <ListItemIcon className={classes.icon}>
            <People />
          </ListItemIcon>
          <ListItemText primary={t('users')} />
        </ListItem>
        <ListItem button key={'players'}>
          <ListItemIcon className={classes.icon}>
            <Router />
          </ListItemIcon>
          <ListItemText primary={t('players')} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};