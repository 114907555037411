import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) =>
  createStyles({
    matchedText: {
      color: theme.palette.secondary.main,
      fontWeight: "bold"
    }
  });

export const useStyles = makeStyles(styles);
