import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) => {
  return createStyles({
    workgroupsContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column"
    },
    headerButton: {
      width: '120px',
      marginRight: theme.spacing.unit / 2
    },
    headerButtonLast: {
      marginRight: 0
    },
    headerButtonText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    headerButtonIconSmall: {
      fontSize: 20,
      marginRight: theme.spacing.unit
    },
    pickSelectionModeContainer: {
      backgroundColor: theme.palette.secondary.main,
      paddingLeft: theme.spacing.unit,
      display: "flex",
      flexDirection: "row",
      alignItems: 'center'
    },
    pickSelectionModeContent: {
      flexGrow: 1,
    },
    workgroupsTree: {
      flexGrow: 1,
      minHeight: "1px",
    },
    loadingProgress: {
      top: '50%',
      position: 'absolute',
      alignSelf: 'center'
    }
  });
};

export const useStyles = makeStyles(styles);