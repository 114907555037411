import { Button } from "@material-ui/core";
import { FieldButtonProps } from "./types";
import React from 'react';

const FieldButton: React.FunctionComponent<FieldButtonProps> = props => (
  <Button variant="text" size="small" color="secondary" onClick={props.onClick}>
    {props.title}
  </Button>
);

const propsAreEqual = () => true;

const MemoFieldButton = React.memo(FieldButton, propsAreEqual);

export { MemoFieldButton as FieldButton };