import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) =>
createStyles({
  workgroup: {
  }
});

export const useStyles = makeStyles(styles);

