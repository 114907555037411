import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    gridContainer: {
      width: "100%",
      flexGrow: 1
    }
  });
};

export const useStyles = makeStyles(styles);
