import { action } from 'typesafe-actions';
import { ActionTypes, SearchFields } from './types';
import { EntityType, NLocation, IdName } from '../../dataStore';

export const companyDataFetchRequest = () =>
  action(ActionTypes.COMPANY_DATA_FETCH_REQUEST);

export const companyDataFetchSuccess = () =>
  action(ActionTypes.COMPANY_DATA_FETCH_SUCCESS);

export const companyDataFetchError = (error: string) =>
  action(ActionTypes.COMPANY_DATA_FETCH_ERROR, error);

export const companySaveRequest = () =>
  action(ActionTypes.SAVE_COMPANY_REQUEST);

export const companySaveSuccess = (id: number) =>
  action(ActionTypes.SAVE_COMPANY_SUCCESS, id);

export const companySaveError = (err: string) =>
  action(ActionTypes.SAVE_COMPANY_ERROR, err);

export const setCompany = (companyId: number) =>
  action(ActionTypes.SET_COMPANY, companyId);

export const searchSetFields = (fields: SearchFields) =>
  action(ActionTypes.SEARCH_SET_FIELDS, fields);

export const searchSetCurrentEntity = (entity: EntityType) =>
  action(ActionTypes.SEARCH_SET_CURRENT_ENTITY, entity);

export const searchSetSearchedEntity = (entity: EntityType) =>
  action(ActionTypes.SEARCH_SET_SEARCHED_ENTITY, entity);

export const searchSetTabIndex = (index: number) =>
  action(ActionTypes.SEARCH_SET_TAB_INDEX, index);

export const locationSaveRequest = (companyId: number, location: NLocation) =>
  action(ActionTypes.SAVE_LOCATION_REQUEST, { companyId, location });

export const locationSaveSuccessful = (
  companyId: number,
  location: NLocation
) => action(ActionTypes.SAVE_LOCATION_SUCCESS, { companyId, location });

export const locationSaveError = (
  companyId: number,
  location: NLocation,
  error: string
) => action(ActionTypes.SAVE_LOCATION_ERROR, { companyId, location, error });

export const setInCompanyMode = (value: boolean) =>
  action(ActionTypes.SET_IN_COMPANY_MODE, value);

export const setSelectedLocations = (items: IdName[]) =>
  action(ActionTypes.SET_SELECTED_LOCATIONS, items);
export const locationDeleteRequest = (
  locationId: number,
  locationName: string
) => action(ActionTypes.DELETE_LOCATION_REQUEST, { locationId, locationName });
export const locationDeleteSuccess = (
  locationId: number,
  locationName: string
) => action(ActionTypes.DELETE_LOCATION_SUCCESS, { locationId, locationName });
export const locationDeleteError = (
  locationId: number,
  locationName: string,
  error: string
) =>
  action(ActionTypes.DELETE_LOCATION_ERROR, {
    locationId,
    locationName,
    error,
  });
