import { connect, MapStateToProps } from "react-redux";
import { Toolbar, ToolbarProps } from "./toolbar";
import { FromLayoutToolbarProps } from "../layout";


const mapStateToProps: MapStateToProps<ToolbarProps, FromLayoutToolbarProps, any> = (state, ownProps) => {
  return {
    newnityEnabled: state.newnity.enabled,
  };
};

export const ToolbarContainer = connect(
  mapStateToProps,
  null
)(Toolbar);
