import * as Actions from './actions';
import { ActionType } from 'typesafe-actions';
import { NotificationsState, ActionTypes } from './types';
import { Reducer } from 'redux';

type NotificationActions = ActionType<typeof Actions>;

const initialState: NotificationsState = {
  items: []
};

export const notificationsReducer: Reducer<
  NotificationsState,
  NotificationActions
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PUSH_NOTIFICATION: {
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    }
    case ActionTypes.REMOVE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.items.filter(
          notification => notification.key !== action.payload
        )
      };
    }
    default:
      return state;
  }
};
