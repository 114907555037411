import { createAction } from 'typesafe-actions';
import { EntityType, IdName } from './types';
import * as ActionTypes from './actionTypes';

export const entityListRequest = createAction(
  ActionTypes.ENTITY_LIST_REQUEST,
  action => (entityType: EntityType) => action({ entityType })
);

export const entityListSuccess = createAction(
  ActionTypes.ENTITY_LIST_SUCCESS,
  action => (entityType: EntityType, items: IdName[]) =>
    action({ entityType, items })
);

export const entityListError = createAction(
  ActionTypes.ENTITY_LIST_ERROR,
  action => (entityType: EntityType, error: string) =>
    action({ entityType, error })
);

export const entityUpsert = createAction(
  ActionTypes.ENTITY_UPSERT,
  action => (entityType: EntityType, item: IdName) =>
    action({ entityType, item })
);

export const entityDelete = createAction(
  ActionTypes.ENTITY_DELETE,
  action => (entityType: EntityType, item: IdName) =>
    action({ entityType, item })
);
