import * as DataStoreActions from './actions';
import * as DataStoreActionTypes from './actionTypes';
import * as GenericSelectors from './selectors/genericSelectors';
import * as PlayerSelectors from './selectors/playerSelectors';
import * as SiteSelectors from './selectors/siteSelectors';
import * as WorkgroupSelectors from './selectors/workgroupSelectors';
import * as NLocationSelectors from './selectors/newnityLocations';
import * as NCompanySelectors from './selectors/newnityCompanies';
import {
  IdName,
  NormalizedIdNames,
  Player,
  Brand,
  TemplateLibrary,
  PlayerType,
  NCompany,
  NDevice,
  NLocation,
  Site,
  Workgroup,
  Stream,
  CountryInfo
} from './types';
export { areaName, EntityType } from './types';

export type NormalizedIdNames<T extends IdName> = NormalizedIdNames<T>;
export type IdName = IdName;
export type Workgroup = Workgroup;
export type Player = Player;
export type TemplateLibrary = TemplateLibrary;
export type Site = Site;
export type Stream = Stream;
export type Brand = Brand;
export type NCompany = NCompany;
export type NLocation = NLocation;
export type NDevice = NDevice;
export type CountryInfo = CountryInfo;
export { PlayerType };
export const DataStoreSelectors = {
  ...GenericSelectors,
  Site: { ...SiteSelectors },
  Player: { ...PlayerSelectors },
  Workgroup: { ...WorkgroupSelectors },
  NLocation: {...NLocationSelectors},
  NCompany: {...NCompanySelectors}
};
export { DataStoreActionTypes };
export { dataStoreReducer as reducer } from './reducer';
export { DataStoreActions };
