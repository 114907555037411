export const areaName = 'dataStore';

export interface IdName {
  id: number;
  name: string;
}

export interface Player extends IdName {
  serial: string;
  siteId: number;
  workgroupId: number;
}

export enum PlayerType {
  Unknown = 0,
  Windows = 1,
  Android = 2,
  Brightsign = 3,
  SamsungSSP = 4,
}

export interface Site extends IdName {
  workgroupId: number;
  oracleNumber: string;
  clientSiteId: string;
  timezone: string;
  country: string;
  city: string;
  phoneNumber: string;
  state: string;
  zipCode: string;
  address: string;
}

export interface Stream extends IdName {
  x: number;
  y: number;
}

export interface Workgroup extends IdName {
  parentId: number;
}

export interface TemplateLibrary extends IdName {}

export interface Brand extends IdName {
  logoPath: string;
}

export interface NCompany extends Workgroup {
  number: string;

  // Address fields
  country: string;
  city: string;
  state: string;
  zipCode: string;
  streetAddress: string;

  // Contact fields
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  deviceSettings: DeviceSettings
}

export interface NLocation extends Site {
  deviceSettings: DeviceSettings
}

export interface DeviceSettings {
  // Audio settings fields
  enableSoundVolumeOverride: boolean;
  audioVolume: string;

  // Network settings fields
  enableNetworkSettingsOverride: boolean;
  enableWifi: boolean;
  enableEthernet: boolean;
  enableProxy: boolean;
}

export interface NDevice extends IdName{}

export interface NCompanySearchResult extends IdName {
  name: string;
  number: string;
  city: string;
  country: string;
}

export interface NLocationSearchResult extends IdName {
  locationNumber: string;
  locationName: string;
  siteNumber: string;
  companyName: string;
  companyId: number;
}

export interface NDeviceSearchResult extends IdName {
  serial: string;
  salesOrder: string;
  deviceDescription: string;
  locationName: string;
  locationNumber: string;
  siteNumber: string;
  companyName: string;
  companyId: number;
}


export interface NormalizedIdNames<T extends IdName> {
  [id: number]: T;
}

export interface EntityState<T extends IdName> {
  items: NormalizedIdNames<T>;
  itemsFetching: DataItemsFetchStatus;
}

export interface DataItemsFetchStatus {
  isFetching: boolean;
  error: string;
  fetchComplete: boolean;
}

export enum EntityType {
  Workgroup = 'workgroup',
  Brand = "brand",
  TemplateLibrary = "templateLibrary",
  Player = 'player',
  Site = 'site',
  Stream = 'stream',
  Channel = "channel",
  NCompany = "nCompany",
  NDevice = "nDevice",
  NLocation = "nLocation",
  NCompanySearchResult = "nCompanySearchResult",
  NLocationSearchResult = "nLocationSearchResult",
  NDeviceSearchResult = "nDeviceSearchResult",
}

export interface CountryInfo extends IdName {
  code: string;
}

export interface DataStoreState {
  [EntityType.Player]: EntityState<Player>;
  [EntityType.Site]: EntityState<Site>;
  [EntityType.Workgroup]: EntityState<Workgroup>;
  [EntityType.Brand]: EntityState<Brand>;
  [EntityType.TemplateLibrary]: EntityState<TemplateLibrary>;

  [EntityType.NCompany]: EntityState<NCompany>;
  [EntityType.NLocation]: EntityState<NLocation>;
  [EntityType.NDevice]: EntityState<TemplateLibrary>;

  [EntityType.NCompanySearchResult]: EntityState<NCompanySearchResult>;
  [EntityType.NLocationSearchResult]: EntityState<NLocationSearchResult>;
  [EntityType.NDeviceSearchResult]: EntityState<NDeviceSearchResult>;
}
