import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Dispatch } from 'redux';
import {
  BladeActions,
  bladeConnect,
  BladeConfig,
  BladeProps,
} from '../../../blade';
import { DataStoreSelectors } from '../../../dataStore';
import {
  WorkgroupDetails,
  WorkgroupDetailsActions,
  WorkgroupDetailsProps,
} from './workgroupDetails';

export interface WorkgroupDetailsContainerProps {
  workgroupId: number;
  workgroupName: string;
}

const mapStateToProps: MapStateToProps<
  WorkgroupDetailsProps,
  WorkgroupDetailsContainerProps & BladeProps,
  any
> = (state, ownProps) => {
  return {
    workgroup: DataStoreSelectors.Workgroup.selectWorkgroupById(
      state,
      ownProps.workgroupId
    ),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  WorkgroupDetailsActions,
  WorkgroupDetailsContainerProps & BladeProps
> = (dispatch: Dispatch, ownProps: WorkgroupDetailsContainerProps) => {
  return {
    fetchWorkgroupUsers: () => {},
    setDirtyState: (isDirty: boolean) =>
      dispatch(BladeActions.setBladeDirtyState(bladeId(ownProps), isDirty)),
  };
};

export const WorkgroupDetailsBladeName = 'WORKGROUP_DETAILS';

const bladeId = (props: WorkgroupDetailsContainerProps) =>
  `${WorkgroupDetailsBladeName}_${props.workgroupId}`;

const bladeConfig: BladeConfig<WorkgroupDetailsContainerProps> = {
  bladeType: WorkgroupDetailsBladeName,
  size: { defaultWidth: 800, minWidth: 400 },
  id: props => bladeId(props),
  title: props => props.workgroupName,
  allowMultipleInstances: false,
};

export const WorkgroupDetailsContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(WorkgroupDetails);
