import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    centerContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& > div': {
        alignSelf: 'center',
      },
    },
    resultsContainer: {
      width: '100%',
      overflowX: 'auto',
      boxShadow: '-1px 0px 2px 0px rgba(0,0,0,0.2)',
    },
    gridContainer: {
      display: 'flex',
      height: '100%',
    },
    progress: {
      alignSelf: 'center',
      margin: '0 auto',
    },
  });
};

export const useStyles = makeStyles(styles);
