import TextField from '@material-ui/core/TextField';
import { FastField, FieldProps } from 'formik';
import i18next from 'i18next';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '../../../../components/autocomplete';
import { IdName, NLocation } from '../../../../dataStore';
import { countries } from '../../../../dataStore/countries';
import { timezones } from '../../../../dataStore/timezones';
import { CountryInfo } from '../../../../dataStore/types';
import { helperText, validateEmptyField } from '../../../../utils/formik';
import { useStyles } from './locationDetails.jss';
import {
  Checkbox,
  InputAdornment,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from '@material-ui/core';

export interface LocationDetailsProps {}

const fieldLabel = (field: { name: string }, t: i18next.TFunction) =>
  t(`newnity.edit.location.tab.details.${field.name}`);

export const LocationDetails: React.FunctionComponent<
  LocationDetailsProps
> = props => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <>
      <FastField
        name="oracleNumber"
        render={({ field, form }: FieldProps<NLocation>) => (
          <TextField
            label={fieldLabel(field, t)}
            {...field}
            margin="normal"
            error={validateEmptyField(form, field)}
            helperText={helperText(form, field, form.errors.oracleNumber)}
          />
        )}
      />
      <FastField
        name="name"
        render={({ field, form }: FieldProps<NLocation>) => (
          <TextField
            label={fieldLabel(field, t)}
            {...field}
            margin="normal"
            error={validateEmptyField(form, field)}
            helperText={helperText(form, field, form.errors.name)}
            autoComplete="something"
          />
        )}
      />
      <FastField
        name="clientSiteId"
        render={({ field, form }: FieldProps<NLocation>) => (
          <TextField
            label={fieldLabel(field, t)}
            {...field}
            margin="normal"
            error={validateEmptyField(form, field)}
            helperText={helperText(form, field, form.errors.clientSiteId)}
          />
        )}
      />
      <FastField
        name="timezone"
        render={({ field, form }: FieldProps<NLocation>) => (
            <Autocomplete
              items={timezones}
              compareMethod="contains"
              initialValue={form.values.timezone}
              minCharInput={4}
              textFieldProps={{
                name: 'something',
                label: fieldLabel(field, t),
                error: validateEmptyField(form, field),
                helperText: helperText(form, field, form.errors.timezone),
              }}
              onChange={(selectedItem: IdName | undefined) =>
                form.setFieldValue(
                  'timezone',
                  selectedItem ? selectedItem.name : ''
                )
              }
            />
        )}
      />
      <FastField
        name="country"
        render={({ field, form }: FieldProps<NLocation>) => (
            <Autocomplete
              items={countries}
              initialValue={form.values.country}
              minCharInput={2}
              textFieldProps={{
                name: 'something',
                label: fieldLabel(field, t),
                error: validateEmptyField(form, field),
                helperText: helperText(form, field, form.errors.country),
              }}
              onChange={(selectedItem: CountryInfo | undefined) =>
                form.setFieldValue(
                  'country',
                  selectedItem ? selectedItem.name : ''
                )
              }
            />
        )}
      />
      <FastField
        name="city"
        render={({ field, form }: FieldProps<NLocation>) => (
          <TextField
            label={fieldLabel(field, t)}
            {...field}
            margin="normal"
            error={validateEmptyField(form, field)}
            helperText={helperText(form, field, form.errors.city)}
            autoComplete="something"
          />
        )}
      />
      <FastField
        name="state"
        render={({ field, form }: FieldProps<NLocation>) => (
          <TextField
            label={fieldLabel(field, t)}
            {...field}
            margin="normal"
            error={validateEmptyField(form, field)}
            helperText={helperText(form, field, form.errors.state)}
            autoComplete="something"
          />
        )}
      />
      <FastField
        name="zipCode"
        render={({ field, form }: FieldProps<NLocation>) => (
          <TextField label={fieldLabel(field, t)} {...field} margin="normal" autoComplete="something" />
        )}
      />
      <FastField
        name="address"
        render={({ field, form }: FieldProps<NLocation>) => (
          <TextField
            label={fieldLabel(field, t)}
            {...field}
            margin="normal"
            autoComplete="something"
            multiline
          />
        )}
      />
      <Button variant="contained" className={classes.devicesButton}>
        {t('newnity.edit.location.tab.details.devices')}
      </Button>
    </>
  );
};
