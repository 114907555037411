import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) => {
  return createStyles({
    contentContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
    },
    searchContainer: {
      display: "flex",
      flexDirection: "column",
    },
    entitySwitch: {
      alignSelf: "center",
      marginBottom: theme.spacing.unit * 2,
    },
    searchButton: {
      borderRadius: 0,
    },
  });
};

export const useStyles = makeStyles(styles);
