import { useTranslation } from 'react-i18next';
import { useStyles } from './toolbar.jss';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import {
  Search,
  Add,
  Info,
  SurroundSound,
  LocationOn,
  Home,
  Router,
  ArrowBack,
  TouchApp,
} from '@material-ui/icons';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { SearchBladeType } from '../../newnity/blades/search/searchContainer';
import { LocationListBladeType } from '../../newnity/blades/location/list/locationListContainer';
import { FromLayoutToolbarProps } from '../layout';
import { IdName } from '../../dataStore';
import { Autocomplete } from '../../components/autocomplete';
import { CompanyBladeType } from '../../newnity/blades/company';

export interface NewnityToolbarActions {
  enterCompanyMode: (id: number) => void;
  leaveCompanyMode: () => void;
  openLocationList: () => void;
  openCompanyEditBlade: () => void;
  openBlade: (parent: string, bladeType: string, props: object) => void;
  getCompanies: () => void;
  exitApp: () => void;
}

export interface NewnityToolbarProps {
  inCompanyMode: boolean;
  companies: IdName[];
  disableEntityButtons: boolean;
}

type Props = NewnityToolbarActions &
  NewnityToolbarProps &
  FromLayoutToolbarProps;

const CompanyMode = (props: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <React.Fragment>
      <List>
        <ListItem button key={'home'} onClick={() => props.exitApp()}>
          <ListItemIcon className={classes.icon}>
            <Home />
          </ListItemIcon>
          <ListItemText primary={t('home')} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={'back'} onClick={() => {
          props.leaveCompanyMode();
        }}>
          <ListItemIcon className={classes.icon}>
            <ArrowBack />
          </ListItemIcon>
          <ListItemText primary={t('newnity.toolbar.back')} />
        </ListItem>
      </List>
      <Divider />
      <List className={classNames(classes.list, `${classes.list}-mainToolbar`)}>
        <ListItem 
          button key={'info'} 
          onClick={props.openCompanyEditBlade} 
          disabled={props.disableEntityButtons}
        >
          <ListItemIcon className={classes.icon}>
            <Info />
          </ListItemIcon>
          <ListItemText primary={t('newnity.toolbar.info')} />
        </ListItem>
        <ListItem button key={'stations'} disabled={props.disableEntityButtons}>
          <ListItemIcon className={classes.icon}>
            <SurroundSound />
          </ListItemIcon>
          <ListItemText primary={t('newnity.stations')} />
        </ListItem>
        <ListItem
          button
          key={'location'}
          onClick={() => props.openLocationList()}
          disabled={props.disableEntityButtons}
        >
          <ListItemIcon className={classes.icon}>
            <LocationOn />
          </ListItemIcon>
          <ListItemText primary={t('newnity.locations')} />
        </ListItem>
        <ListItem button key={'devices'} disabled={props.disableEntityButtons}>
          <ListItemIcon className={classes.icon}>
            <Router />
          </ListItemIcon>
          <ListItemText primary={t('newnity.devices')} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};

const SearchMode = (props: Props) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <React.Fragment>
      <List>
        <ListItem button key={'home'} onClick={() => props.exitApp()}>
          <ListItemIcon className={classes.icon}>
            <Home />
          </ListItemIcon>
          <ListItemText primary={t('home')} />
        </ListItem>
      </List>
      <Divider />
      <List className={classNames(classes.list, `${classes.list}-mainToolbar`)}>
        <ListItem
          button={!props.toolbarExpanded}
          key={'jump'}
          onClick={props.expandToolbar}
        >
          <ListItemIcon className={classes.icon}>
            <TouchApp />
          </ListItemIcon>
          <Autocomplete
            items={props.companies}
            minCharInput={3}
            onChange={(selectedCompany: IdName) => {
              props.enterCompanyMode(selectedCompany.id);
            }}
            textFieldProps={{
              placeholder: t('newnity.toolbar.jump'),
              style: { marginLeft: '16px' },
            }}
          />
        </ListItem>
        <ListItem
          button
          key={'search'}
          onClick={() => props.openBlade('', SearchBladeType, {})}
        >
          <ListItemIcon className={classes.icon}>
            <Search />
          </ListItemIcon>
          <ListItemText primary={t('newnity.toolbar.search')} />
        </ListItem>
        <ListItem
          button
          key={'add'}
          onClick={() => {
            props.enterCompanyMode(0);
          }}
        >
          <ListItemIcon className={classes.icon}>
            <Add />
          </ListItemIcon>
          <ListItemText primary={t('newnity.toolbar.add')} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};

export const NewnityToolbar = (props: Props) => {
  useEffect(() => {
    props.getCompanies();
  }, []);

  return props.inCompanyMode ? (
    <CompanyMode {...props} />
  ) : (
    <SearchMode {...props} />
  );
};
