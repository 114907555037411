import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as Actions from './actions';
import {
  ActionTypes,
  BladeInstance,
  BladeState,
  NormalizedBlades,
} from './types';

type BladeActions = ActionType<typeof Actions>;

const initialState: BladeState = {
  items: {},
  dirtyClosing: {
    bladeIdForClosing: '',
    dirtyChildrenIds: [],
  },
};

const oldCloseBlade = (
  state: BladeState,
  bladeIdForClosing: string
): BladeState => {
  let newState = { ...state };
  delete newState.items[bladeIdForClosing];
  Object.keys(newState.items).forEach(k => {
    if (newState.items[k] && newState.items[k].parentId === bladeIdForClosing) {
      newState = oldCloseBlade(newState, newState.items[k].id);
      delete newState.items[k];
    }
  });
  return newState;
};

const shouldDeleteBlades = (
  newState: BladeState,
  bladeIdForClosing: string
) => {
  let allowedToCloseBlades: boolean = true;
  const parentClosingBlade = newState.items[bladeIdForClosing].closeParentId;
  Object.keys(newState.items).forEach(k => {
    //a ramas ceva asincron de rulat pe stack
    if (
      newState.items[k].closeParentId === parentClosingBlade &&
      newState.items[k].isClosing
    ) {
      if (newState.items[k].waitOnClose && !newState.items[k].shouldClose) {
        allowedToCloseBlades = false;
      }
    }
  });

  return allowedToCloseBlades;
};

const noBladeIsWaiting = (newState: BladeState, bladeId: string) => {
  let res:boolean = true;
  Object.keys(newState.items).forEach(k => {
    if (newState.items[k].waitOnClose && newState.items[k].closeParentId === newState.items[k].closeParentId ) {
      res = false;
    }
  });
  return res;
}

const freezeBladeIds = (newState: BladeState, bladeToFreeze: string[]) => {
  bladeToFreeze.forEach(bladeId => {
    if (newState.items[bladeId]) {
      newState.items[bladeId].frozen = true;
    }
  });

  return newState;
};

const clearFrozenBlades = (newState: BladeState) => {
  Object.keys(newState.items).forEach(bladeId => {
    newState.items[bladeId].frozen = false;
  });

  return newState;
};

const setParentIdClosing = (
  newState: BladeState,
  bladeIdForClosing: string
) => {
  const closing = newState.items[bladeIdForClosing];
  closing.closeParentId = bladeIdForClosing;

  Object.keys(newState.items).forEach(k => {
    const item = newState.items[k];
    const parentItem = newState.items[item.parentId];

    if (item.id !== closing.id && parentItem && parentItem.closeParentId) {
      item.closeParentId = bladeIdForClosing;
    }
  });

  return newState;
};

const deleteMarkedBladesWithParent = (
  newState: BladeState,
  bladeIdForClosing: string
) => {
  const closingParentId = newState.items[bladeIdForClosing].closeParentId;
  Object.keys(newState.items).forEach(k => {
    if (newState.items[k].closeParentId === closingParentId) {
      delete newState.items[k];
    }
  });

  return newState;
};

const closeBlade = (
  state: BladeState,
  bladeIdForClosing: string
): BladeState => {
  let newState = { ...state };
  if(!newState.items[bladeIdForClosing])
    return newState;

  //a close action was dispatched, set the parent ID on myself and all other blades that are connected
  if (newState.items[bladeIdForClosing].closeParentId === '') {
    setParentIdClosing(state, bladeIdForClosing);
  }

  //bladeidfor closing
  if (newState.items[bladeIdForClosing].isClosing) {
    if (shouldDeleteBlades(state, bladeIdForClosing)) {
      newState = deleteMarkedBladesWithParent(newState, bladeIdForClosing);
    }
    //vedem daca sunt toate gata
    //daca sunt toate gata sa facem close blade pe bladeifforclosing din state si sa le stergem frumos
    return newState;
  } else {
    //start closing our blade
    queueClose(newState, bladeIdForClosing);
    //start closing blades to the right
    newState = queueCloseChildren(newState, bladeIdForClosing);

    return newState;
  }
};

const closeBladeWithDirtyCheckReducer = (
  state: BladeState,
  bladeIdForClosing: string
): BladeState => {
  let dirtyBlades: BladeInstance[] = [];
  if (state.items[bladeIdForClosing] && state.items[bladeIdForClosing].isDirty) {
    dirtyBlades.push(state.items[bladeIdForClosing]);
  }
  dirtyBlades = dirtyBlades.concat(
    getAllDirtyChildren(state.items, bladeIdForClosing)
  );
  if (dirtyBlades.length > 0) {
    return {
      ...state,
      dirtyClosing: {
        bladeIdForClosing,
        dirtyChildrenIds: dirtyBlades.map(b => b.id),
      },
    };
  }
  return closeBlade(state, bladeIdForClosing);
};

const getAllDirtyChildren = (
  blades: NormalizedBlades,
  bladeId: string
): BladeInstance[] => {
  let dirtyBlades: BladeInstance[] = [];
  Object.keys(blades).forEach(k => {
    if (blades[k].parentId === bladeId) {
      if (blades[k].isDirty) {
        dirtyBlades.push(blades[k]);
      }
      dirtyBlades = dirtyBlades.concat(
        getAllDirtyChildren(blades, blades[k].id)
      );
    }
  });
  return dirtyBlades;
};

const updateBladeProperty = <T>(
  state: BladeState,
  bladeId: string,
  prop: string,
  value: T
) => {
  const blade = state.items[bladeId];
  return {
    ...state,
    items: {
      ...state.items,
      [bladeId]: {
        ...blade,
        [prop]: value,
      },
    },
  };
};

export const bladeReducer: Reducer<BladeState, BladeActions> = (
  state: BladeState = initialState,
  action: BladeActions
) => {
  switch (action.type) {
    case ActionTypes.CREATE_BLADE: {
      if (state.items[action.payload.id]) {
        return updateBladeProperty<boolean>(
          state,
          action.payload.id,
          'newlyCreated',
          true
        );
      } else {
        return {
          ...state,
          items: { ...state.items, [action.payload.id]: action.payload },
        };
      }
    }
    case ActionTypes.CLOSE_BLADE: {
      return closeBladeWithDirtyCheckReducer(state, action.payload);
    }
    case ActionTypes.FORCE_CLOSE_BLADE: {
      if (!state.items[action.payload]) {
        return state;
      }
      const newState = closeBlade(state, action.payload);
      newState.dirtyClosing.bladeIdForClosing = '';
      newState.dirtyClosing.dirtyChildrenIds = [];
      return newState;
    }
    case ActionTypes.MARK_SHOULD_CLOSE: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload]: {
            ...state.items[action.payload],
            shouldClose: true,
          },
        },
      };
    }
    case ActionTypes.MARK_WAIT_CLOSE: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload]: {
            ...state.items[action.payload],
            waitOnClose: true,
          },
        },
      };
    }
    case ActionTypes.CANCEL_DIRTY_BLADE_CLOSE: {
      return {
        ...state,
        dirtyClosing: { bladeIdForClosing: '', dirtyChildrenIds: [] },
      };
    }
    case ActionTypes.SET_BLADE_DIRTY_STATE: {
      if (state.items[action.payload.bladeId].isDirty !== action.payload.isDirty) {
        return updateBladeProperty<boolean>(
          state,
          action.payload.bladeId,
          'isDirty',
          action.payload.isDirty
        );
      } else return state;
    }
    case ActionTypes.SET_BLADE_NEWLY_CREATED_STATE: {
      return updateBladeProperty<boolean>(
        state,
        action.payload.bladeId,
        'newlyCreated',
        action.payload.isNewlyCreated
      );
    }
    case ActionTypes.HIGHLIGHT_BLADES: {
      return freezeBladeIds(state, action.payload);
    }
    case ActionTypes.CLEAR_HIGHLIGHT_BLADES: {
      return clearFrozenBlades(state);
    }
    case ActionTypes.SET_TITLE: {
      return updateBladeProperty<string>(
        state, 
        action.payload.bladeId, 
        'title', 
        action.payload.value
      );
    }
    default:
      return state;
  }
};
const queueCloseChildren = (newState: BladeState, bladeIdForClosing: string) => {
  Object.keys(newState.items).forEach(k => {
    if (newState.items[k] && newState.items[k].parentId === bladeIdForClosing) {
      newState = closeBlade(newState, newState.items[k].id);
      queueClose(newState, k);
    }
  });
  return newState;
}

const onlyOneBladeClosed = (newState: BladeState, bladeIdForClosing: string) => {
  let count: number = 0;
  Object.keys(newState.items).forEach(k => {
    if (newState.items[k].closeParentId === bladeIdForClosing) {
      count += 1;
    }
  });
  return count === 1;
}

const queueClose = (newState: BladeState, k: string) => {
  if (!newState.items[k]) return newState;

  newState.items[k].isDirty = false;
  if (!newState.items[k].waitOnClose || newState.items[k].shouldClose) {
    newState.items[k].isClosing = true;

    if (onlyOneBladeClosed(newState, k)) {
      delete newState.items[k];
    } else {
      newState.items[k].frozen = true;
      if (noBladeIsWaiting(newState, k)) {
        oldCloseBlade(newState,k);
      }
    }
  } else {
    newState.items[k].isClosing = true;
    newState.items[k].frozen = true;
  }
}