import { Dispatch } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { withSnackbar, WithSnackbarProps, OptionsObject } from 'notistack';
import { Notifier, NotifierProps, NotifierActions } from './notifier';
import { removeNotification } from './duck/actions';

const mapStateToProps: MapStateToProps<NotifierProps, any, any> = state => ({
  notifications: state.notifications.items
});

const mapDispatchToProps: MapDispatchToProps<
  NotifierActions,
  any
> = dispatch => ({
  removeNotification: (notificationKey: number) =>
    dispatch(removeNotification(notificationKey))
});

export const NotifierContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Notifier));
