import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { BladeInstance } from "../duck/types";

export interface BladeClosingProps {
  opened: boolean;
  bladeToClose: BladeInstance;
  dirtyChildren: BladeInstance[];
  onCloseAll: () => void;
  onCancel: () => void;
}

export const BladeClosingDialog: React.FunctionComponent<
  BladeClosingProps
> = props => {
  const [t] = useTranslation();

  return (
    <Dialog open={props.opened}>
      <DialogTitle>
        {t("blade.close.confirmation", { bladeName: props.bladeToClose.title })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("blade.close.dirty")}</DialogContentText>
        <List>
          {props.dirtyChildren.map(b => (
            <ListItem key={b.id}>
              <ListItemText primary={b.title} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary" autoFocus>
          {t("cancel")}
        </Button>
        <Button onClick={props.onCloseAll} color="primary">
          {t("blade.close.all")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
