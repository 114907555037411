import React, { useCallback } from 'react';
import { BladeProps } from '../../../../blade';
import { CompanyDetailsFields } from './companyDetailsFields';
import { debounce } from 'debounce';
import { DeviceSettingsContainer } from '../../../components/deviceSettings/deviceSettingsContainer';
import { FooterSubmit } from '../../../../components/bladeLayout/footerSubmit';
import { Form, Formik, FormikActions, FormikErrors, FormikProps } from 'formik';
import { NCompany } from '../../../../dataStore';
import { TabsHeaderAndContent } from '../../../../components/bladeLayout/tabsHeaderAndContent';
import { useStyles } from './company.jss';
import { useTranslation } from 'react-i18next';

export interface CompanyInfoProps {
  isSaving: boolean;
  isFetching: boolean;
  company: NCompany;
}

export interface CompanyInfoActions {
  saveCompany: (companyData: NCompany) => void;
  forceCloseBlade: () => void;
}

type Props = CompanyInfoProps & CompanyInfoActions & BladeProps;

export const CompanyInfo: React.FunctionComponent<Props> = props => {
  const [t] = useTranslation();
  const classes = useStyles();

  const conditionalDirty = () => {
    /* This is a very rudimentary dirty logic.
     * We should check initial field valus with current values.
     */

    if (props.isDirty) {
      return;
    }

    props.setDirty(true);
  };

  const content = useCallback(
    (tabIndex: number) => {
      switch (tabIndex) {
        case 0:
          return <CompanyDetailsFields {...props} />;
        case 1:
          return <DeviceSettingsContainer parentBladeId={props.bladeId} />;
        default:
          return <div>Really?</div>;
      }
    },
    [props.company.id]
  );

  const footer = useCallback(
    () => (
      <FooterSubmit
        hasCancelButton={true}
        cancelButtonLabel={t('footer.cancel')}
        cancel={() => {
          props.forceCloseBlade();
        }}
        submitButtonLabel={t(props.company.id ? 'footer.save' : 'footer.create')}
        submit={() => {
          // Todo
        }}
        submitInProgress={props.isSaving}
      />
    ),
    [props.company.id]
  )

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validate={(values: NCompany) => {
        let errors: FormikErrors<NCompany> = {};

        if (!values.name) {
          errors.name = t('newnity.company.field.error');
        }

        if (!values.number) {
          errors.number = t('newnity.company.field.error');
        }

        return errors;
      }}
      initialValues={props.company}
      onSubmit={(values: NCompany, actions: FormikActions<NCompany>) => {
        props.saveCompany(values);
      }}
      render={(formikBag: FormikProps<NCompany>) => {
        return (
          <Form
            className={classes.form}
            onChange={props.isDirty ? undefined : debounce(conditionalDirty, 250)}
            autoComplete="new-password"
          >
            <TabsHeaderAndContent
              content={content}
              tabs={[{ label: 'Company Details' }, { label: 'Device Default Settings' }]}
              footer={footer}
              isBusy={props.isFetching || props.isSaving}
            />
          </Form>
        );
      }}
    />
  );
};
