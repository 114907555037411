import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { openBlade } from '../../blade';
import { MAD } from '../../..';
import {
  NewnityToolbarActions,
  NewnityToolbar,
  NewnityToolbarProps,
} from './newnityToolbar';
import { setCompany, setInCompanyMode, setSelectedLocations } from '../../newnity/duck/actions';
import { FromLayoutToolbarProps } from '../layout';
import { closeBlade } from '../../blade/duck/actions';
import { SearchBladeType } from '../../newnity/blades/search/searchContainer';
import { CompanyBladeType } from '../../newnity/blades/company';
import { openCompanyEditBlade } from '../../newnity/duck/thunks';
import { NewnityState } from '../../newnity/duck/types';
import { fetchCompanies, LocationListBladeType } from '../../newnity';
import { DataStoreSelectors, EntityType } from '../../dataStore';

const mapStateToProps: MapStateToProps<
  NewnityToolbarProps,
  FromLayoutToolbarProps,
  any
> = state => {
  const companies = DataStoreSelectors.getDataStoreItemsDenormalized(state, EntityType.NCompany);
  return {
    inCompanyMode: (state.newnity as NewnityState).inCompanyMode,
    companies,
    disableEntityButtons: (state.newnity as NewnityState).currentCompany.companyId <= 0
  };
};

const mapDispatchToProps: MapDispatchToProps<
  NewnityToolbarActions,
  any
> = dispatch => {
  return {
    enterCompanyMode: (id) => {
      dispatch(setCompany(id));
      dispatch(setInCompanyMode(true));
      dispatch(closeBlade(SearchBladeType));
      dispatch<any>(openCompanyEditBlade());
    },
    leaveCompanyMode: () => {
      dispatch(setCompany(0));
      dispatch(setInCompanyMode(false));
      dispatch(closeBlade(CompanyBladeType));
    },
    openCompanyEditBlade: () => dispatch<any>(openCompanyEditBlade()),
    openLocationList: () => {
      dispatch(setSelectedLocations([]));
      openBlade(CompanyBladeType, LocationListBladeType, {}, dispatch);
    },
    openBlade: (parent, type, props) => openBlade(parent, type, props, dispatch),
    getCompanies: () => dispatch<any>(fetchCompanies()),
    exitApp: () => MAD.homeFn(),
  };
};

export const NewnityToolbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewnityToolbar);
