import { MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { BladeActions, bladeConnect, BladeProps } from '../../blade';
import {
  SelectionBlade,
  SelectionBladeActions,
  SelectionBladeProps,
} from './selectionBlade';
import { DataStoreSelectors, IdName, EntityType } from '../../dataStore';
import * as SelectionActions from './duck/actions';
import { selectedItems } from './duck/selectors';
import { DataGridSelectionType } from '../dataGrid';

export interface SelectionBladeContainerProps extends BladeProps {
  title: string;
  selectionKey: string;
  entityType: EntityType;
  selectedIds?: IdName[];
  items?: IdName[];
  selectionType?: DataGridSelectionType;
}

const mapStateToProps: MapStateToProps<
  SelectionBladeProps,
  SelectionBladeContainerProps & BladeProps,
  any
> = (state, ownProps) => {
  let selectedIds = ownProps.selectedIds
    ? ownProps.selectedIds
    : selectedItems(state, ownProps.selectionKey);

  return {
    entityType: ownProps.entityType,
    items: ownProps.items,
    itemsFetching: DataStoreSelectors.getDataStoreItemsFetching(
      state,
      ownProps.entityType
    ).isFetching,
    selectedIds,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  SelectionBladeActions,
  SelectionBladeContainerProps & BladeProps
> = (dispatch: Dispatch, ownProps) => ({
  cancel: () => dispatch<any>(BladeActions.closeBlade(bladeId(ownProps))),
  selectionSubmitted: (items: IdName[]) => {
    dispatch(SelectionActions.updateSelection(ownProps.selectionKey, items));
    dispatch(BladeActions.closeBlade(bladeId(ownProps)));
  },
  closeBlade: () => dispatch<any>(BladeActions.forceCloseBlade(bladeId(ownProps))),
  setDirty: (dirty: boolean) => dispatch<any>(BladeActions.setBladeDirtyState(bladeId(ownProps), dirty))
});

export const SelectionListBladeType = 'GENERIC_SELECTION_LIST';

const bladeId = (props: SelectionBladeContainerProps) =>
  `${SelectionListBladeType}_${props.selectionKey}`;

const bladeConfig = {
  size: { defaultWidth: 600, minWidth: 600 },
  bladeType: SelectionListBladeType,
  id: (props: any) => bladeId(props),
  title: (props: any) => props.title,
  allowMultipleInstances: false,
};

export const SelectionBladeContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(SelectionBlade);
