import React from "react";
import { useStyles } from "./results.jss";
import { useTranslation } from "react-i18next";

export const Placeholder = () => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.centerContainer}>
      <div>
        {t("newnity.search.resultsPlaceholder")}
      </div>
    </div>
  );
};