import React from "react";
import { ContentWrapper } from "./contentWrapper";
import { useTranslation } from "react-i18next";
import { CompanySearchFields } from "../../../duck/types";
import { TextFieldWrapper } from "./textFieldWrapper";

export interface CompanyTabProps {
  onChange(key: string, value: any): void;
}

export const CompanyTab = (props: CompanySearchFields & CompanyTabProps) => {
  const [t] = useTranslation();
  
  return (
    <ContentWrapper>
      <TextFieldWrapper
        value={props.number}
        onChange={e => props.onChange("number", e.currentTarget.value)}
        label={t("newnity.search.companyNumber")}
      />
      <TextFieldWrapper
        value={props.name}
        onChange={e => props.onChange("name", e.currentTarget.value)}
        label={t("newnity.search.companyName")}
      />
    </ContentWrapper>
  );
};
