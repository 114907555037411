import { BladeActions, bladeConnect, BladeProps } from '../../../../blade';
import { CompanyInfo, CompanyInfoActions, CompanyInfoProps } from './company';
import { DataStoreSelectors, NCompany } from '../../../../dataStore';
import { Dispatch } from 'redux';
import { MapDispatchToProps, MapStateToProps } from 'react-redux';
import { saveCompanyInfo } from '../../../duck/thunks';
import {
  selectCompanyDataIsFetching,
  selectCompanyIsSaving,
  selectCompanyId,
} from '../../../duck/selectors';

interface CompanyContainerProps {
  title: string;
}

export const emptyCompany = {
  id: 0,
  name: '',
  number: '',
  parentId: 1,
  country: '',
  city: '',
  state: '',
  zipCode: '',
  streetAddress: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  deviceSettings: {
    enableSoundVolumeOverride: false,
    audioVolume: '100',
    enableNetworkSettingsOverride: false,
    enableWifi: false,
    enableEthernet: false,
    enableProxy: false,
  },
};

const mapStateToProps: MapStateToProps<
  CompanyInfoProps,
  BladeProps,
  any
> = (state) => {
  let company: NCompany;
  const companyId = selectCompanyId(state);

  if (companyId) {
    company = DataStoreSelectors.NCompany.selectCompanyById(
      state,
      companyId);
  } else {
    company = emptyCompany;
  }

  return {
    isSaving: selectCompanyIsSaving(state),
    isFetching: selectCompanyDataIsFetching(state),
    company,
  };
};

const mapDispatchToProps: MapDispatchToProps<CompanyInfoActions, BladeProps> = (
  dispatch: Dispatch) => ({
  closeBlade: () => dispatch<any>(BladeActions.forceCloseBlade(CompanyBladeType)),
  saveCompany: (companyData: NCompany) => dispatch<any>(saveCompanyInfo(companyData)),
  forceCloseBlade: () => dispatch(BladeActions.forceCloseBlade(CompanyBladeType)),
});

export const CompanyBladeType = 'COMPANY_INFO';

const bladeConfig = {
  size: { defaultWidth: 800, minWidth: 800 },
  bladeType: CompanyBladeType,
  id: () => CompanyBladeType,
  title: (ownProps: CompanyContainerProps & BladeProps) => ownProps.title, 
  allowMultipleInstances: false,
};

export const CompanyBladeContainer = bladeConnect(mapStateToProps, mapDispatchToProps, bladeConfig)(
  CompanyInfo
);
