import React from "react";
import { ContentWrapper } from "./contentWrapper";
import { useTranslation } from "react-i18next";
import { TextFieldWrapper } from "./textFieldWrapper";

export interface PhoneTabProps {
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  onChange(key: string, value: any): void;
}

export const PhoneTab = (props: PhoneTabProps) => {
  const [t] = useTranslation();

  return (
    <ContentWrapper>
      <TextFieldWrapper
        value={props.phone}
        onChange={e => props.onChange("phone", e.currentTarget.value)}
        label={t("newnity.search.phone")}
      />
      <TextFieldWrapper
        value={props.email}
        onChange={e => props.onChange("email", e.currentTarget.value)}
        label={t("newnity.search.email")}
      />
      <TextFieldWrapper
        value={props.firstName}
        onChange={e => props.onChange("firstName", e.currentTarget.value)}
        label={t("newnity.search.firstName")}
      />
      <TextFieldWrapper
        value={props.lastName}
        onChange={e => props.onChange("lastName", e.currentTarget.value)}
        label={t("newnity.search.lastName")}
      />
    </ContentWrapper>
  );
};
