import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux';
import { ResultsActions, Results, ResultsProps } from './results';
import { SearchBladeType } from '../searchContainer';
import { SearchState } from '../../../duck/types';
import { forceCloseBlade } from '../../../../blade/duck/actions';
import { setCompany, setInCompanyMode, setSelectedLocations } from '../../../duck/actions';
import { DataStoreSelectors } from '../../../../dataStore';
import { openCompanyEditBlade } from '../../../duck/thunks';
import { openBlade } from '../../../../blade';

const mapStateToProps: MapStateToProps<ResultsProps, any, any> = state => {
  const searchedEntity = (state.newnity.search as SearchState).searchedEntity;
  
  return {
    searchedEntity: searchedEntity,
    isFetching:
      searchedEntity === undefined
        ? false
        : DataStoreSelectors.getDataStoreItemsFetching(state, searchedEntity).isFetching,
    itemsCount: 
      searchedEntity === undefined 
      ? 0
      : DataStoreSelectors.getDataStoreItemsDenormalized(state, searchedEntity).length,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  ResultsActions,
  any
> = dispatch => {
  return {
    enterCompanyMode: (id) => {
      dispatch(setCompany(id));
      dispatch(setInCompanyMode(true));
      dispatch(forceCloseBlade(SearchBladeType));
      dispatch<any>(openCompanyEditBlade());
    },
    openBlade: (parent, type, props) => openBlade(parent, type, props, dispatch),
    setSelectedLocations: items => dispatch(setSelectedLocations(items)),
  };
};

export const ResultsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Results);
