import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { MuiThemeProvider } from "@material-ui/core/styles";
import classNames from 'classnames';
import React, { useState, useCallback } from 'react';
import { moodThemeDark } from '../app.jss';
import { useStyles } from './layout.jss';
import { ToolbarContainer } from './toolbar/toolbarContainer';

export interface FromLayoutToolbarProps {
  expandToolbar: () => void;
  toolbarExpanded: boolean;
}

export const Layout: React.FunctionComponent<{}> = (
  React.memo(props => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const classes = useStyles();

    const handleDrawerOpen = useCallback(() => {
      setDrawerOpen(true);
    }, []);
    
    const handleDrawerClose = () => {
      setDrawerOpen(false);
    };
    
    return (
      <div className={classes.app}>
        <CssBaseline />
        <MuiThemeProvider theme={moodThemeDark}>
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
                [classes.drawerPaper]: true
              })
            }}
            open={drawerOpen}
          >
            <ToolbarContainer expandToolbar={handleDrawerOpen} toolbarExpanded={drawerOpen}/>
            {!drawerOpen ? (
              <IconButton
              className={classes.expandCollapseButton}
                aria-label="Open drawer"
                onClick={handleDrawerOpen}
              >
                <ChevronRightIcon />
              </IconButton>
            ) : (
              <IconButton className={classes.expandCollapseButton} onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            )}
          </Drawer>
        </MuiThemeProvider>
        <main className={classes.content}>
          {props.children}
        </main>
      </div>
    );
  })
);
