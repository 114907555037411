import IconButton from '@material-ui/core/IconButton';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import Help from '@material-ui/icons/HelpOutline';
import Warning from '@material-ui/icons/Warning';
import React from 'react';
import { moodThemeDark } from '../app.jss';
import { useStyles } from './bladeHeader.jss';
import { BladeInstance } from './duck/types';

export interface BladeHeaderProps {
  bladeInstance: BladeInstance;
  canCloseRef: React.MutableRefObject<() => boolean>;
}
export interface BladeHeaderActions {
  closeBlade: (bladeId: string) => void;
}

type Props = BladeHeaderActions & BladeHeaderProps;

export const BladeHeader: React.FunctionComponent<Props> = React.memo(
  props => {
    const closeButtonClick = (_: React.MouseEvent<HTMLButtonElement>) => {
      
      if (props.canCloseRef.current()) {
        props.closeBlade(props.bladeInstance.id);  
      }
    };
    const classes = useStyles();

    return (
      <MuiThemeProvider theme={moodThemeDark}>
        <div className={classes.header}>
          {props.bladeInstance.isDirty && (
            <Warning color="secondary" className={classes.dirtyIcon} />
          )}
          <Typography variant="h5" className={classes.title}>
            {props.bladeInstance.title}
          </Typography>
          <IconButton className={classes.closeButton}>
            <Help />
          </IconButton>
          <IconButton
            className={classes.closeButton}
            onClick={closeButtonClick}
          >
            <Close />
          </IconButton>
        </div>
      </MuiThemeProvider>
    );
  },
  (prevProps: Props, nextProps: Props) =>
    prevProps.bladeInstance.id === nextProps.bladeInstance.id &&
    prevProps.bladeInstance.isDirty === nextProps.bladeInstance.isDirty &&
    prevProps.bladeInstance.title === nextProps.bladeInstance.title
);
