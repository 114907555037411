import { createSelector } from 'reselect';
import { getDataStore } from './genericSelectors';
import { EntityType, NCompany } from '../types';

export const selectCompanies = (state: any) =>
  getDataStore(state)[EntityType.NCompany];

export const selectCompanyItems = createSelector(
  [selectCompanies],
  companies => {
    return Object.keys(companies.items).map<NCompany>(
      key => companies.items[Number(key)]
    );
  }
);

export const selectCompanyById = (state: any, locationId: number) => selectCompanies(state).items[locationId];
