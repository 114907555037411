import { TreeState, NormalizedTreeItems, TreeItem } from "../types"; 
import { denormalize, normalize } from "../../../../utils/extensions";

export const expandItem = (state: TreeState, id: string): TreeState => {
  const unfilteredTree = batchExpand(state.items, state.unfilteredTree, [
    id
  ]);
  const filteredTree = getFilteredTree(state.items, unfilteredTree);
  
  return { ...state, unfilteredTree, filteredTree };
};

export const collapseItem = (state: TreeState, id: string): TreeState => {
  const unfilteredTree = batchCollapse(state.items, state.unfilteredTree, [
    id
  ]);
  const filteredTree = getFilteredTree(state.items, unfilteredTree);

  return { ...state, unfilteredTree, filteredTree };
};

export const expandAll = (state: TreeState): TreeState => {
  const nextExpanded = Object.keys(state.items).filter(
    k => state.items[k].hasChildren
  );

  return batchUpdate(state, nextExpanded);
};

export const collapseAll = (state: TreeState): TreeState => {
  const nextExpanded = state.expandedDefault;
  
  return batchUpdate(state, nextExpanded);
};

export const batchUpdate = (state: TreeState, expandedIds: string[]): TreeState => {
  const previousExpanded = getExpandedOfTree(state);
  const toCollapse = previousExpanded._difference(expandedIds);
  const toExpand = expandedIds._difference(previousExpanded);

  let unfilteredTree = batchCollapse(
    state.items,
    state.unfilteredTree,
    toCollapse
  );
  unfilteredTree = batchExpand(
    state.items, 
    unfilteredTree, 
    toExpand);
  
  const filteredTree = getFilteredTree(state.items, unfilteredTree);

  return { ...state, unfilteredTree, filteredTree };
};

/**
 * Do not use outside of module.
 * Only exported for testing purposes.
 */
export const batchExpand = (
  items: NormalizedTreeItems,
  tree: string[],
  ids: string[]
): string[] => {
  const itemsIds = Object.keys(items);
  let result = [...tree];

  ids.reverse().map(id => {
    if (result.indexOf(id) === -1) {
      items[id].isExpanded = true;
    } else {
      result = main.expandRow(items, itemsIds, result, id);
    }
  });

  return result;
};

/**
 * Do not use outside of module.
 * Only exported for testing purposes.
 */
export const batchCollapse = (
  items: NormalizedTreeItems,
  tree: string[],
  ids: string[]
): string[] => {
  let result = [...tree];

  ids.map(id => {
    if (result.indexOf(id) === -1) {
      items[id].isExpanded = false;
    } else {
      result = main.collapseRow(items, result, id);
    }
  });

  return result;
};

/**
 * Do not use outside of module.
 * Only exported for testing purposes.
 */
export const expandRow = (
  normalizedTreeItems: NormalizedTreeItems,
  itemsIds: string[],
  tree: string[],
  id: string
): string[] => {
  const parent = normalizedTreeItems[id];

  if (!parent) {
    return tree;
  }

  const childNodes = [];
  let i = parent.flatListIndex + 1;

  const parentStack: TreeItem[] = [parent];
  let parentStackExpandedCount = 1;

  parent.isExpanded = true;

  while (i < itemsIds.length) {
    const currentNode = normalizedTreeItems[itemsIds[i]];
    if (currentNode.level <= parent.level) break;

    // current parent is the last in the stack
    let currentParent = parentStack[parentStack.length - 1];

    // if the current parent is not a direct child of the current parent then we have to remove the current parent from the stack
    while (currentNode.level <= currentParent.level) {
      parentStack.pop();
      if (currentParent.isExpanded) {
        parentStackExpandedCount--;
      }
      currentParent = parentStack[parentStack.length - 1];
    }

    // every parent in the stack is expanded only if the expanded count is equal to the stack count
    const allParentsExpanded = parentStackExpandedCount === parentStack.length;

    // add the current node only if
    if (
      currentNode.treeParentId === currentParent.treeId &&
      allParentsExpanded
    ) {
      childNodes.push(currentNode);
    }

    // if the current node is a parent itself, then add it to the stack of parents
    if (currentNode.hasChildren) {
      parentStack.push(currentNode);

      // if it's also expanded then increase the count of expanded parents
      if (currentNode.isExpanded) {
        parentStackExpandedCount++;
      }
    }

    i++;
  }

  let result = [...tree];
  result.splice(
    result.indexOf(id) + 1,
    0,
    ...childNodes.map(i => i.treeId)
  );

  return result;
};

/**
 * Do not use outside of module.
 * Only exported for testing purposes.
 */
export const collapseRow = (
  normalizedTreeItems: NormalizedTreeItems,
  tree: string[],
  id: string
): string[] => {
  const rowIndex = tree.indexOf(id);

  if (rowIndex === -1) {
    return tree;
  }

  const item = normalizedTreeItems[tree[rowIndex]];
  const startIndex = rowIndex + 1;
  let stopIndex = -1;
  let i = startIndex;
  let endNotFound = true;

  if (normalizedTreeItems[tree[startIndex]].level <= item.level) {
    return tree;
  }

  while (endNotFound) {
    i++;

    if (i === tree.length) {
      stopIndex = i;
      endNotFound = false;
      continue;
    }

    if (normalizedTreeItems[tree[i]].level <= item.level) {
      stopIndex = i;
      endNotFound = false;
    }
  }
  item.isExpanded = false;

  let result = [...tree];
  result.splice(startIndex, stopIndex - startIndex);
  return result;
};

export const getExpandedOfTree = (state: TreeState) => {
  return denormalize(state.items)
    .filter(i => i.isExpanded)
    .map(i => i.treeId);
};

export const getFilteredTree = (items: NormalizedTreeItems, unfilteredTree: string[]) => {
  return unfilteredTree.filter(i => {
    const item = items[i];
    return item.match !== null || item.hasChildrenMatched;
  });
};

const main = {
  expandRow,
  collapseRow
}
export default main;
