import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { moodThemeDark } from "../../app.jss";
import { useStyles } from "./header.jss";

export const Header = (props: any) => {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={moodThemeDark}>
      <div className={classes.header}>{props.children}</div>
    </MuiThemeProvider>
  );
};
