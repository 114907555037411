import { MapDispatchToProps, MapStateToProps } from "react-redux";
import { bladeConnect, BladeProps } from "../../../blade";
import { Search, SearchActions, SearchProps } from "./search";
import { searchSetFields, searchSetCurrentEntity, searchSetTabIndex } from "../../duck/actions";
import { search } from "../../duck/thunks";
import { SearchState } from "../../duck/types";
import { DataStoreSelectors, EntityType } from "../../../dataStore";

const mapStateToProps: MapStateToProps<SearchProps, BladeProps,any
> = (state) => {
  const searchState = (state.newnity.search as SearchState);
  const companies = DataStoreSelectors.getDataStoreItemsDenormalized(state, EntityType.NCompany);
  
  return {
    currentEntity: searchState.currentEntity,
    fields: searchState.fields,
    tabIndex: searchState.tabIndex,
    companies
  };
};

const mapDispatchToProps: MapDispatchToProps<
  SearchActions,
  BladeProps
> = dispatch => {
  return {
    setFields: fields => dispatch(searchSetFields(fields)),
    setCurrentEntity: entity => dispatch(searchSetCurrentEntity(entity)),
    setTabIndex: index => dispatch(searchSetTabIndex(index)),
    search: (fields, entity) => dispatch<any>(search(fields, entity)),
  };
};

export const SearchBladeType = "NEWNITY_SEARCH";

const bladeConfig = {
  size: { defaultWidth: 1050, minWidth: 600 },
  bladeType: SearchBladeType,
  id: (_: any) => SearchBladeType,
  title: (_: any) => "Search",
  allowMultipleInstances: false,
};

export const SearchContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(Search);
