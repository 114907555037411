import React, { useState, useCallback } from 'react';
import { useStyles } from './selectionBlade.jss';
import { HeaderAndContent } from '../bladeLayout/headerAndContent';
import { CircularProgress } from '@material-ui/core';
import { IdName, NormalizedIdNames, EntityType } from '../../dataStore';
import { DataGridContainer, DataGridSelectionType } from '../dataGrid';
import { FooterSubmit } from '../bladeLayout/footerSubmit';
import { useTranslation } from 'react-i18next';
import { composeInitialProps } from 'react-i18next';
import { BladeProps } from '../../blade';

export interface SelectionBladeProps {
  itemsFetching: boolean;
  entityType: EntityType;
  selectedIds?: IdName[];
  selectionType?: DataGridSelectionType;
  items?: IdName[];
}

export interface SelectionBladeActions {
  selectionSubmitted: (items: IdName[]) => void;
  cancel: () => void;
  setDirty: (dirty: boolean) => void;
}

type Props = SelectionBladeProps & SelectionBladeActions;

export const SelectionBlade: React.FunctionComponent<Props> = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [selectedItems, setSelectedItems] = useState<IdName[]>(
    props.selectedIds ? props.selectedIds : []
  );

  const onSelect = useCallback(
    (items: IdName[]) => setSelectedItems(items),
    []
  );
  const onSubmit = () => props.selectionSubmitted(selectedItems);

  const content = () => {
    return props.itemsFetching ? (
      <CircularProgress className={classes.loadingProgress} size={60} />
    ) : (
      <DataGridContainer
        entityType={props.entityType}
        onSelect={onSelect}
        selectedIds={props.selectedIds}
        items={props.items}
        selectionType={
          props.selectionType ? props.selectionType : DataGridSelectionType.None
        }
      />
    );
  };

  const footer = () => (
    <FooterSubmit
      hasCancelButton={true}
      cancel={props.cancel}
      cancelButtonLabel={t('cancel')}
      submitButtonLabel={t('save')}
      submit={onSubmit}
      submitInProgress={false}
    />
  );
  return <HeaderAndContent removePadding content={content} footer={footer} />;
};
