import { TreeItem, ItemWithParent, NormalizedTreeItems } from "../types";

interface Node {
  item: TreeItem;
  children: Node[];
}

export const build = (dataItems: ItemWithParent[]) => {
  const firstLevel = buildTree(dataItems);
  let treeItems: TreeItem[] = [];
  firstLevel.forEach(
    node => (treeItems = [...treeItems, node.item, ...depthFirst(node)])
  );

  const items = treeItems.reduce<NormalizedTreeItems>(
    (obj, treeItem, index) => {
      treeItem.flatListIndex = index;
      obj[treeItem.treeId] = treeItem;
      return obj;
    },
    {}
  );

  return items;
};

const buildTree = (list: ItemWithParent[]): Node[] => {
  const lookup: { [id: string]: Node } = {};
  const firstLevel: Node[] = [];

  list.forEach(item => {
    if (lookup[item.id]) return;

    const parent = lookup[item.parentId];
    if (parent) {
      const child: TreeItem = {
        modelId: item.id,
        modelParentId: item.parentId,
        hasChildren: false,
        isExpanded: false,
        label: item.name,
        level: parent.item.level + 1,
        treeId: `${parent.item.treeId}/${parent.children.length + 1}`,
        treeParentId: parent.item.treeId,
        flatListIndex: 0,
        totalChildCount: 0,
        match: null,
        hasChildrenMatched: false
      };
      const node = { item: child, children: [] };
      parent.children.push(node);
      parent.item.hasChildren = true;
      lookup[item.id] = node;
      updateParentsChildCount(lookup, parent);
    } else {
      lookup[item.id] = {
        item: {
          modelId: item.id,
          modelParentId: item.parentId,
          hasChildren: false,
          isExpanded: false,
          label: item.name,
          level: 1,
          treeId: `/${firstLevel.length + 1}`,
          treeParentId: "",
          flatListIndex: 0,
          totalChildCount: 0,
          match: null,
          hasChildrenMatched: false
        },
        children: []
      };
      firstLevel.push(lookup[item.id]);
    }
  });

  return firstLevel;
};

const updateParentsChildCount = (
  lookup: { [id: string]: Node },
  node: Node
) => {
  while (node) {
    node.item.totalChildCount++;
    node = lookup[node.item.modelParentId];
  }
};

const depthFirst = (node: Node): TreeItem[] => {
  let subtree: TreeItem[] = [];
  for (const child of node.children) {
    subtree.push(child.item);
    subtree = subtree.concat(depthFirst(child));
  }
  return subtree;
};
