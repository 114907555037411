import classNames from "classnames";
import React, { useState } from "react";
import Measure, { ContentRect } from "react-measure";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import {
  NormalizedSelection,
  NormalizedTreeItemActions,
  SelectionMode,
  TreeItem,
  TreeItemAction,
  SelectedItem,
  NormalizedTreeItems
} from "./duck/types";
import { useStyles } from "./tree.jss";
import { SelectionCheckbox } from "./selectionCheckbox";
import { ExpandButton } from "./expandButton";
import { Label } from "./label";

export interface TreeProps {
  treeName: string;
  areaName: string;
  onSelect?: (modelId: number, treeItemId: string) => void;
  itemRenderer?: (item: TreeItem) => React.ReactElement<any>;
  actions?: TreeItemAction[];
}

export interface TreeStateProps {
  items: NormalizedTreeItems;
  visibleTree: string[];
  selectionMode?: SelectionMode;
  selectedItems: NormalizedSelection;
  actionState: NormalizedTreeItemActions;
}

export interface TreeActions {
  expand: (treeItemId: string) => void;
  collapse: (treeItemId: string) => void;
  select: (treeItemId: string) => void;
  unselect: (treeItemId: string) => void;
}

type Props = TreeStateProps & TreeActions & TreeProps;

export const Tree = (props: Props) => {
  const classes = useStyles();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const multipleSelection =
    props.selectionMode === SelectionMode.MultipleSelection ||
    props.selectionMode === SelectionMode.MultipleWithInheritanceSelection;
  const items = props.visibleTree.map(i => props.items[i]);

  const expandChanged = (e: any, item: TreeItem) => { 
    e.stopPropagation();

    if (item.isExpanded) {
      props.collapse(item.treeId);
    } else {
      props.expand(item.treeId);
    }
  };

  const sizeChanged = (contentRect: ContentRect) => {
    if (contentRect && contentRect.bounds) {
      setSize({
        width: contentRect.bounds.width,
        height: contentRect.bounds.height
      });
    }
  };

  const selectionChanged = (e: any, item: SelectedItem, treeItem: TreeItem) => {
    e.stopPropagation();

    const previouslySelected = item && item.selected;

    if (previouslySelected) {
      props.unselect(treeItem.treeId);
    } else {
      props.select(treeItem.treeId);
      if(props.onSelect) {
        props.onSelect(treeItem.modelId, treeItem.treeId);
      }
    }
  };

  const itemKey = (index: number, data: any[]) => data[index].treeId;

  return (
    <Measure bounds onResize={sizeChanged}>
      {({ measureRef }) => (
        <div ref={measureRef} className={classes.treeMeasure}>
          <FixedSizeList
            height={size.height}
            width={size.width}
            itemCount={items.length}
            itemData={items}
            itemSize={40}
            itemKey={itemKey}
            overscanCount={20}
          >
            {(itemProps: ListChildComponentProps) => {
              const treeItem = itemProps.data[itemProps.index] as TreeItem;
              const selectedItem = props.selectedItems[treeItem.treeId];
              const selected = selectedItem && selectedItem.selected;

              const treeItemStyles = {
                ...itemProps.style,
                paddingLeft:
                  20 * treeItem.level - 8 + (treeItem.hasChildren ? 0 : 28)
              };

              return (
                <div
                  style={treeItemStyles}
                  className={classNames(classes.treeItem, {
                    [classes.selected]: selected
                  })}
                  onClick={e =>
                    selectionChanged(e, selectedItem, treeItem)
                  }
                >
                  {treeItem.hasChildren && (
                    <ExpandButton
                      item={treeItem}
                      onClick={e => expandChanged(e, treeItem)}
                    />
                  )}
                  {multipleSelection && (
                    <SelectionCheckbox
                      item={selectedItem}
                      id={treeItem.treeId}
                    />
                  )}
                  <Label item={treeItem} />
                </div>
              );
            }}
          </FixedSizeList>
        </div>
      )}
    </Measure>
  );
};
