import { FormControlLabel, Checkbox, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { FastField, FieldProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../blades/company/edit/company.jss';
import { HasDeviceSettings } from '../../duck/types';
import classNames from 'classnames';
import {
  NetworkWifi,
  SettingsInputHdmi,
  DeveloperBoard,
} from '@material-ui/icons';

const MIN_AUDIO_VOLUME = 0;
const MAX_AUDIO_VOLUME = 100;
export interface DeviceSettingsProps {
}

export interface DeviceSettingsActions {
  editWifiSettings: () => void;
  editEthernetSettings: () => void;
  editProxySettings: () => void;
}

type Props = DeviceSettingsProps & DeviceSettingsActions;

export const DeviceSettings: React.FunctionComponent<Props> = React.memo(props => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.fieldsContainer}>
      <FastField
        name="deviceSettings.enableSoundVolumeOverride"
        render={({ field, form }: FieldProps<HasDeviceSettings>) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(_, checked) =>
                    form.setFieldValue(field.name, checked)
                  }
                />
              }
              label={t('newnity.tab.defaultSettings.volumeOverride')}
            />
          );
        }}
      />
      <FastField
        name="deviceSettings.audioVolume"
        render={({ field, form }: FieldProps<HasDeviceSettings>) => {
          return (
            <TextField
              {...field}
              label={`${t('newnity.tab.defaultSettings.audioVolume')} (0-100)`}
              type={'number'}
              inputProps={{ min: '0', max: '100', step: '1' }}
              onChange={e => {
                if (Number(e.target.value) > MAX_AUDIO_VOLUME) {
                  e.target.value = String(MAX_AUDIO_VOLUME);
                }

                if (Number(e.target.value) < MIN_AUDIO_VOLUME) {
                  e.target.value = String(MIN_AUDIO_VOLUME);
                }

                field.onChange(e);
              }}
              className={classNames([classes.formField, classes.formButton])}
              helperText={
                form.errors.deviceSettings
                  ? form.errors.deviceSettings.audioVolume
                  : undefined
              }
              margin="normal"
            />
          );
        }}
      />
      <FastField
        name="deviceSettings.enableNetworkSettingsOverride"
        render={({ field, form }: FieldProps<HasDeviceSettings>) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(_, checked) =>
                    form.setFieldValue(field.name, checked)
                  }
                />
              }
              label={t('newnity.tab.defaultSettings.networkOverride')}
            />
          );
        }}
      />
      <FastField
        name="deviceSettings.enableWifi"
        render={({ field, form }: FieldProps<HasDeviceSettings>) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(_, checked) =>
                    form.setFieldValue(field.name, checked)
                  }
                />
              }
              label={t('newnity.tab.defaultSettings.enableWifi')}
            />
          );
        }}
      />
      <Button
        className={classes.formButton}
        variant="outlined"
        name="configureWifi"
      >
        <NetworkWifi className={classes.iconLeft} />
        {t('newnity.tab.defaultSettings.configureWifi')}
      </Button>
      <FastField
        name="deviceSettings.enableEthernet"
        render={({ field, form }: FieldProps<HasDeviceSettings>) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(_, checked) =>
                    form.setFieldValue(field.name, checked)
                  }
                />
              }
              label={t('newnity.tab.defaultSettings.configureEthernet')}
            />
          );
        }}
      />
      <Button
        variant="outlined"
        className={classes.formButton}
        name="configureEthernet"
      >
        <SettingsInputHdmi className={classes.iconLeft} />
        {t('newnity.tab.defaultSettings.configureEthernet')}
      </Button>
      <FastField
        name="deviceSettings.enableProxy"
        render={({ field, form }: FieldProps<HasDeviceSettings>) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(_, checked) =>
                    form.setFieldValue(field.name, checked)
                  }
                />
              }
              label={t('newnity.tab.defaultSettings.enableProxy')}
            />
          );
        }}
      />
      <Button
        variant="outlined"
        className={classes.formButton}
        name="configureProxy"
      >
        <DeveloperBoard className={classes.iconLeft} />
        {t('newnity.tab.defaultSettings.configureProxy')}
      </Button>
    </div>
  );
});
