import React from "react";
import { useStyles } from "./tabs.jss";

export const ContentWrapper = ({ children }: any) => {
  const classes = useStyles();

  return (
    <div className={classes.tabContent}>
      {children}
    </div>
  );
};