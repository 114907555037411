import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) => createStyles({
  container: {
    height: "100%",
    // width: "100%",
    // display: "flex",
    // flexDirection: "column"
  },
});

export const useStyles = makeStyles(styles);

