import {IdName} from '../../../dataStore';


export const areaName = "selectionBlade";

export interface SelectionBladeState {
  [id: string]: IdName[]
}

export enum ActionTypes {
  UPDATE_SELECTION = "UPDATE_SELECTION",
  CLEAR_SELECTION = "CLEAR_SELECTION"
}