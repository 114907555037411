export const resources = {
    en: {
        translation: {
            "Welcome to Admin App": "Admin App is awesome y'all",
            "home": "Home",
            "workgroups": "Workgroups",
            "settings": "Settings",
            "users": "Users",
            "players": "Players",
            "sites": "Sites",
            "blade.close.confirmation": "Are you sure you want to close {{bladeName}} ?",
            "blade.close.dirty": "The folowing blades have unsaved data: ",
            "blade.close.all": "Close all",
            "cancel": "Cancel",
            "exit": "Exit",
            "create": "Create",
            "import": "Import",
            "delete": "Delete",
            "favorites": "Favorites",
            "search": "Search",
            "search.placeholder": "Type something for search...",
            "workgroups.pickSelectionModeCaption": "You are now in the Workgroup Pick Mode!",
            "workgroups.expand.tooltip": "Expand all workgroups",
            "workgroups.collapse.tooltip": "Collapse all workgroups",
            "workgroups.select.tooltip": "Select all workgroups",
            "workgroups.unselect.tooltip": "Deselect all workgroups",
            "workgroups.export.tooltip": "Export selected workgroups",
            "name": "Name",
            "save": "Save",
            "edit": "Edit",
            "logo" : "Logo",
            "select.templateLibraries": "Template Libraries",
            "select.brands": "Brands",
            "workgroups.templateLibraries.brand": "Brand",
            "workgroups.templateLibraries.logo": "Logo Path",
            "workgroups.brands.defaultLogo": "Default Logo",
            "workgroups.brands.brandType": "Brand Type",
            "create.workgroup.name": "Workgroup name",
            "create.workgroup.brand_label": "Brand",
            "create.workgroup.brand_ids": "Brand Id's",
            "create.workgroup.template": "Template Libraries",
            "create.workgroup.parent_name" : "Parent workgroup",
            "create.workgroup.edit_btn_label" : "EDIT",
            "create.workgroup.select_btn_label" : "SELECT",
            "create.workgroup.error_name_required" : "Field is required",
            "create.workgroup.create_btn_label" : "CREATE",
            "create.workgroup.cancel_btn_label" : "Cancel",
            "create.workgroup.create_another_btn_label" : "Create another",
            "create.workgroup.error.multiple_workgroups" : "Cannot create workgroup: multiple parents selected",
            "create.workgroup.number_selected": "selected",
            "create.workgroup.properties_header": "Properties",
            "newnity.company": "Company",
            "newnity.device": "Device",
            "newnity.location": "Location",
            "newnity.stations": "Stations",
            "newnity.locations": "Locations",
            "newnity.devices": "Devices",
            "newnity.toolbar.search": "Search",
            "newnity.toolbar.add": "New company",
            "newnity.toolbar.info": "Company Info",
            "newnity.toolbar.back": "Back",
            "newnity.toolbar.jump": "Jump to company",
            "newnity.search": "Search",
            "newnity.address": "Address",
            "newnity.phone": "Phone",
            "newnity.search.address": "Street Address",
            "newnity.search.city": "City",
            "newnity.search.state": "State/Province",
            "newnity.search.zip": "Postal code",
            "newnity.search.country": "Country",
            "newnity.search.companyName": "Company name",
            "newnity.search.companyNumber": "Company number",
            "newnity.search.deviceNumber": "Device number",
            "newnity.search.deviceDescription": "Device number",
            "newnity.search.serial": "Serial number",
            "newnity.search.locationNumber": "Location number",
            "newnity.search.locationName": "Location name",
            "newnity.search.siteNumber": "Site number",
            "newnity.search.company": "Company",
            "newnity.search.salesOrder": "Sales order number",
            "newnity.search.phone": "Phone",
            "newnity.search.email": "Email",
            "newnity.search.firstName": "Contact first name",
            "newnity.search.lastName": "Contact last name",
            "newnity.search.resultsPlaceholder": "Search results",
            "newnity.edit.company.blade.create.title": "Create company",
            "newnity.company.field.error": "Field is required",
            "newnity.company.name": "Company name",
            "newnity.company.number": "Company number",
            "newnity.company.parentWorkgroup": "Parent workgroup",
            "newnity.company.address": "Address",
            "newnity.company.address.country": "Country",
            "newnity.company.address.city": "City",
            "newnity.company.address.state": "State/Province",
            "newnity.company.address.zipCode": "Zip/Postal code",
            "newnity.company.address.streetAddress": "Street address",
            "newnity.company.contact.firstName": "First name",
            "newnity.company.contact.lastName": "Last name",
            "newnity.company.contact.phone": "Phone",
            "newnity.company.contact.email": "Email address",
            "newnity.edit.company.toast.create.success": "Company created successfully",
            "newnity.edit.company.toast.success": "Company data saved",
            "footer.save": "Save",
            "footer.create": "Create",
            "footer.cancel": "Cancel",
            "newnity.tab.defaultSettings.enableDefaultSettings": "Enable device default settings",
            "newnity.tab.defaultSettings.volumeOverride": "Enable Volume Override",
            "newnity.tab.defaultSettings.audioVolume": "Audio volume",
            "newnity.tab.defaultSettings.networkSettings": "Network settings",
            "newnity.tab.defaultSettings.networkOverride": "Enable network settings override",
            "newnity.tab.defaultSettings.enableWifi": "Enable WiFi",
            "newnity.tab.defaultSettings.configureWifi": "Configure WiFi",
            "newnity.tab.defaultSettings.enableEthernet": "Enable Ethernet",
            "newnity.tab.defaultSettings.configureEthernet": "Configure Ethernet",
            "newnity.tab.defaultSettings.enableProxy": "Enable Proxy",
            "newnity.tab.defaultSettings.configureProxy": "Configure Proxy",
            "newnity.create.location.tab.details": "Location details",
            "newnity.create.location.tab.defaultSettings": "Default settings",
            "newnity.create.location.tab.details.oracleNumber": "Oracle location number",
            "newnity.create.location.tab.details.name": "Location name",
            "newnity.create.location.tab.details.siteNumber": "Site number",
            "newnity.create.location.tab.details.timezone": "Timezone",
            "newnity.create.location.tab.details.country": "Country",
            "newnity.create.location.tab.details.city": "City",
            "newnity.create.location.tab.details.state": "State/Province",
            "newnity.create.location.tab.details.zipCode": "Zip/Postal code",
            "newnity.create.location.tab.details.address": "Address",
            "newnity.create.location.tab.details.devices": "Devices...",
            "nCompanySearchResult.columnTitle.name": "Company name",
            "nCompanySearchResult.columnTitle.number": "Company number",
            "nCompanySearchResult.columnTitle.city": "City",
            "nCompanySearchResult.columnTitle.country": "Country",
            "nLocationSearchResult.columnTitle.locationNumber": "Oracle location number",
            "nLocationSearchResult.columnTitle.locationName": "Location name",
            "nLocationSearchResult.columnTitle.siteNumber": "Site number",
            "nLocationSearchResult.columnTitle.companyName": "Company",
            "nDeviceSearchResult.columnTitle.id": "Device number",
            "nDeviceSearchResult.columnTitle.serial": "Serial number",
            "nDeviceSearchResult.columnTitle.salesOrder": "Sales order no",
            "nDeviceSearchResult.columnTitle.deviceDescription": "Device description",
            "nDeviceSearchResult.columnTitle.locationName": "Location name",
            "nDeviceSearchResult.columnTitle.locationNumber": "Location number",
            "nDeviceSearchResult.columnTitle.siteNumber": "Site number",
            "nDeviceSearchResult.columnTitle.companyName": "Company",
            "newnity.search.noresults": "No results found",
            "newnity.search.locationAddress": "Location Address",
            "newnity.edit.location.toast.success": "Location data saved",
            "newnity.edit.location.toast.create.success": "Location created successfully",
            "newnity.edit.location.blade.create.title": "Create location",
            "newnity.edit.location.tab.details": "Location details",
            "newnity.edit.location.tab.defaultSettings": "Default settings",
            "newnity.edit.location.tab.details.oracleNumber": "Oracle location number",
            "newnity.edit.location.tab.details.name": "Location name",
            "newnity.edit.location.tab.details.clientSiteId": "Site number",
            "newnity.edit.location.tab.details.timezone": "Timezone",
            "newnity.edit.location.tab.details.country": "Country",
            "newnity.edit.location.tab.details.city": "City",
            "newnity.edit.location.tab.details.state": "State/Province",
            "newnity.edit.location.tab.details.zipCode": "Zip/Postal code",
            "newnity.edit.location.tab.details.address": "Address",
            "newnity.edit.location.tab.details.devices": "Devices...",
            "newnity.list.location.delete.confirm.title": "Delete location",
            "newnity.list.location.delete.confirm.content": "Are you sure you want to delete {{locationName}}?",
            "nSearchCompany.columnTitle.name": "Company name",
            "nSearchCompany.columnTitle.number": "Company number",
            "nSearchCompany.columnTitle.city": "City",
            "nSearchCompany.columnTitle.country": "Country",
            "nSearchLocation.columnTitle.locationNumber": "Oracle location number",
            "nSearchLocation.columnTitle.locationName": "Location name",
            "nSearchLocation.columnTitle.siteNumber": "Site number",
            "nSearchLocation.columnTitle.companyName": "Company",
            "nSearchDevice.columnTitle.deviceNumber": "Device number",
            "nSearchDevice.columnTitle.serial": "Serial number",
            "nSearchDevice.columnTitle.salesOrder": "Sales order no",
            "nSearchDevice.columnTitle.deviceDescription": "Device description",
            "nSearchDevice.columnTitle.locationName": "Location name",
            "nSearchDevice.columnTitle.locationNumber": "Location number",
            "nSearchDevice.columnTitle.siteNumber": "Site number",
            "nSearchDevice.columnTitle.companyName": "Company",
            "nLocation.columnTitle.name": "Name",
            "nLocation.columnTitle.country": "Country",
            "nLocation.columnTitle.city": "City",
            "nLocation.columnTitle.timezone": "Timezone",
            "newnity.search.tab.company.address": "Company Address"
        }
    },
    fr: {
        translation: {
            "Welcome to Admin App": "Omlette du Admin App avec baguette",
            "home": "Home fr",
            "workgroups": "Workgroups fr",
            "settings": "Settings fr",
            "users": "Users fr",
            "players": "Players fr",
            "blade.close.confirmation": "Are you sure you want to close {{bladeName}} fr?",
            "blade.close.dirty": "The folowing blades have unsaved data: fr",
            "blade.close.all": "Close all fr",
            "cancel": "Cancel fr",
            "create": "Create fr",
            "delete": "Delete fr",
            "favorites": "Favorites fr",
            "search": "Search fr",
            "search.placeholder": "Type something for search fr...",
            "workgroups.expand.tooltip": "Expand all workgroups fr",
            "workgroups.collapse.tooltip": "Collapse all workgroups fr",
            "workgroups.select.tooltip": "Select all workgroups fr",
            "workgroups.unselect.tooltip": "Deselect all workgroups fr",
            "workgroups.export.tooltip": "Export selected workgroups fr",
            "name": "Name",
            "save": "Save",
            "workgroups.templateLibraries.brand": "Brand",
            "workgroups.templateLibraries.logoPath": "Logo",
            "workgroups.brands.defaultLogo": "Default Logo",
            "workgroups.brands.brandType": "Brand Type"
        }
    },
    de: {
        translation: {
            "Welcome to Admin App": "Admin App über alles!!!",
            "home": "Home de",
            "workgroups": "Workgroups de",
            "settings": "Settings de",
            "users": "Users de",
            "players": "Players de",
            "blade.close.confirmation": "Are you sure you want to close {{bladeName}} de?",
            "blade.close.dirty": "The folowing blades have unsaved data: de",
            "blade.close.all": "Close all de",
            "cancel": "Cancel de",
            "create": "Create de",
            "delete": "Delete fr",
            "favorites": "Favorites de",
            "search": "Search de",
            "search.placeholder": "Type something for search de...",
            "workgroups.expand.tooltip": "Expand all workgroups de",
            "workgroups.collapse.tooltip": "Collapse all workgroups de",
            "workgroups.select.tooltip": "Select all workgroups de",
            "workgroups.unselect.tooltip": "Deselect all workgroups de",
            "workgroups.export.tooltip": "Export selected workgroups de",
            "name": "Name",
            "save": "Save",
            "workgroups.templateLibraries.brand": "Brand",
            "workgroups.brands.defaultLogo": "Default Logo",
            "workgroups.brands.brandType": "Brand Type"
        }
    }
}