export interface TreeState {
  treeName: string;
  items: NormalizedTreeItems;
  expandedDefault: string[];
  expandedBeforeFilter: string[];
  unfilteredTree: string[];
  filteredTree: string[];
  selectionMode: SelectionMode;
  selectedItems: NormalizedSelection;
  itemActions: NormalizedTreeItemActions;
  filterValue: string;
}

export interface NormalizedTreeItems {
  [treeId: string]: TreeItem;
}

export interface NormalizedSelection {
  [treeId: string]: SelectedItem
}

export interface SelectedItem {
  state: CheckboxState,
  isIndeterminate: boolean,
  selectedChildrenCount: number,
  selected: boolean
}

export interface NormalizedTreeItemActions {
  [treeItemId: string]: {
    [actionName: string]: {
      inProgress: boolean;
      error: string | null;
    };
  };
}

export enum CheckboxState {
  Selected = "Selected",
  SelectedWithChildren = "SelectedWithChildren",
}

export enum SelectionMode {
  None,
  SingleSelection,
  MultipleSelection,
  MultipleWithInheritanceSelection
}

export interface TreeContainingState {
  [area: string]: {
    [treeName: string]: TreeState;
  };
}

export interface TreeItem {
  treeId: string;
  treeParentId: string; 
  modelId: number;
  modelParentId: number;
  level: number;
  hasChildren: boolean;
  isExpanded: boolean;
  label: string;
  flatListIndex: number;
  totalChildCount: number;
  match: [number, number] | null;
  hasChildrenMatched: boolean; 
}

export interface TreeItemAction {
  name: string;
  content: (
    item: TreeItem
  ) => { component: React.ComponentElement<any, any>; visible: boolean };
  visible: boolean;
}

export interface ItemWithParent {
  id: number;
  parentId: number;
  name: string;
  [key: string]: any;
}

export enum ActionTypes {
  TREE_EXPAND_ITEM = "TREE_EXPAND_ITEM",
  TREE_COLLAPSE_ITEM = "TREE_COLLAPSE_ITEM",
  TREE_EXPAND_ALL = "TREE_EXPAND_ALL",
  TREE_COLLAPSE_ALL = "TREE_COLLAPSE_ALL",
  TREE_SELECT_ITEM = "TREE_SELECT_ITEM",
  TREE_UNSELECT_ITEM = "TREE_UNSELECT_ITEM",
  TREE_SELECT_ALL = "TREE_SELECT_ALL",
  TREE_UNSELECT_ALL = "TREE_UNSELECT_ALL",
  TREE_FILTER = "TREE_FILTER"
}
