import React, { useEffect, useRef } from 'react';
import { Blade } from '../blade';
import { BladeInstance } from '../duck/types';
import { BladeClosingDialog } from './bladeClosingDialog';
import { useStyles } from './bladeRow.jss';
import classNames from 'classnames';

export interface BladeRowProps {
  blades: BladeInstance[];
  highlightedBlades: string[];
  
  bladeClosing: {
    bladeToClose: BladeInstance;
    dirtyChildren: BladeInstance[];
  };
}

export interface BladeRowActions {
  forceCloseBlade: (bladeId: string) => void;
  cancelBladeClose: () => void;
  setBladeNewlyCreateState: (bladeId: string, isNewlyCreated: boolean) => void;
  setBladeDirty: (bladeId: string, dirty: boolean) => void;
}

type Props = BladeRowProps & BladeRowActions;

const BladeRow = (props: Props) => {
  const bladeContainerRef = useRef<HTMLDivElement>(null);
  const newBladeRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  useEffect(() => {
    if (newBladeRef.current && bladeContainerRef.current) {
      const containerDiv = bladeContainerRef.current;
      const newBladeDiv = newBladeRef.current;
      const newBladeId = newBladeDiv.getAttribute('data-key');
      if (!newBladeId) {
        return;
      }

      containerDiv.scrollLeft =
        newBladeDiv.offsetLeft - containerDiv.offsetLeft;
      props.setBladeNewlyCreateState(newBladeId, false);
    }
  });

  const onForceClose = () => {
    props.forceCloseBlade(props.bladeClosing.bladeToClose.id);
  };
  
  const highlights = props.highlightedBlades && props.highlightedBlades.length > 0;
  const blades = props.blades.map((b,i) => (
    <div
      className={classNames({
        [classes.faded]: highlights && props.highlightedBlades.indexOf(b.id) > -1
      })}
      data-key={b.id}
      key={b.id}
      style={{zIndex: 100- i}}
      ref={b.newlyCreated ? newBladeRef : null}
    >
      <Blade instance={b} setBladeDirty={props.setBladeDirty}/>
    </div>
  ));
  blades.push(
    <div key="BUFFER_SPACE">
      <div className={classes.bufferSpace} />
    </div>
  );

  return (
    <div className={classes.bladeContainer} ref={bladeContainerRef}>
      {blades}
      {props.bladeClosing.bladeToClose && (
        <BladeClosingDialog
          bladeToClose={props.bladeClosing.bladeToClose}
          dirtyChildren={props.bladeClosing.dirtyChildren}
          onCloseAll={onForceClose}
          onCancel={props.cancelBladeClose}
          opened={props.bladeClosing.bladeToClose !== undefined}
        />
      )}
    </div>
  );
};

const arePropsEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.blades.length === nextProps.blades.length &&
  prevProps.bladeClosing.bladeToClose === nextProps.bladeClosing.bladeToClose &&
  nextProps.blades.find(b => b.newlyCreated) === undefined &&
  !prevProps.blades.some((bladeInstance, index) => nextProps.blades[index].title !== bladeInstance.title) &&
  prevProps.highlightedBlades.length === nextProps.highlightedBlades.length &&
  compareDirtyState(prevProps.blades, nextProps.blades);

const compareDirtyState = (
  prevBlades: BladeInstance[],
  nextBlades: BladeInstance[]
): boolean => {
  for (let i = 0; i < prevBlades.length; i++) {
    if (prevBlades[i].isDirty !== nextBlades[i].isDirty || prevBlades[i].title !== nextBlades[i].title) {
      return false;
    }
  }
  return true;
};

const MemoBladeRow = React.memo(BladeRow, arePropsEqual);

export { MemoBladeRow as BladeRow };
