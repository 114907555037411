import Paper from '@material-ui/core/Paper';
import React, { useEffect, useRef, useState } from 'react';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { useStyles } from './blade.jss';
import { BladeErrorBoundary } from './bladeErrorBoundary';
import { BladeHeaderContainer } from './bladeHeaderContainer';
import { getBladeContent } from './duck/bladeStore';
import { BladeInstance } from './duck/types';

export interface BladeProps {
  instance: BladeInstance;
  setBladeDirty: (bladeId: string, dirty: boolean) => void;
}

const Blade: React.FunctionComponent<BladeProps> = props => {
  const classes = useStyles();
  
  const canCloseRef = useRef<() => boolean>(() => true);
  const onContentClose = (canClose: () => boolean) => {
    canCloseRef.current = canClose;
  };
  
  const { component, config } = getBladeContent(props.instance, props.setBladeDirty, onContentClose);

  const [width, setWidth] = useState(config.size.defaultWidth);
  const innerBladeDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (innerBladeDivRef.current) {
      setWidth(innerBladeDivRef.current.offsetWidth);
    }
  }, [innerBladeDivRef]);


  const resizeDragStopped: DraggableEventHandler = (e, data) =>
    setWidth(Math.max(config.size.minWidth, width + data.x));
  return (
    <Paper
      square
      className={classes.paper}
      style={width !== 0 ? { width: `${width}px` } : undefined}
    >
      <div ref={innerBladeDivRef} className={classes.blade}>
        <BladeHeaderContainer
          bladeInstance={props.instance}
          canCloseRef={canCloseRef}
        />

        <BladeErrorBoundary>{component}</BladeErrorBoundary>

        <Draggable
          axis="x"
          defaultClassName={classes.dragHandle}
          defaultClassNameDragging={
            width >= config.size.minWidth
              ? classes.dragHandleActive
              : classes.dragHandleActiveLessThanMin
          }
          position={{ x: 0, y: 0 }}
          onStop={resizeDragStopped}
        >
          <div />
        </Draggable>
      </div>
    </Paper>
  );
};

const propsAreEqual = (prevProps: BladeProps, nextProps: BladeProps) =>
  prevProps.instance.id === nextProps.instance.id &&
  prevProps.instance.isDirty === nextProps.instance.isDirty &&
  prevProps.instance.title === nextProps.instance.title;

const MemoBlade = React.memo(Blade, propsAreEqual);

export { MemoBlade as Blade };
