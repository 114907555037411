import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { BladeProps, openBlade } from '../../../blade';
import {
  DeviceSettings,
  DeviceSettingsProps,
  DeviceSettingsActions,
} from './deviceSettings';

export interface DeviceSettingsContainerProps {
  parentBladeId: string;
}

const mapDispatchToProps: MapDispatchToProps<
  DeviceSettingsActions,
  DeviceSettingsContainerProps
> = (dispatch, ownProps) => ({
  editWifiSettings: () =>
    openBlade(ownProps.parentBladeId, 'Wifi_Blade_Type', {}, dispatch),
  editEthernetSettings: () =>
    openBlade(ownProps.parentBladeId, 'Ethernet_Blade_Type', {}, dispatch),
  editProxySettings: () =>
    openBlade(ownProps.parentBladeId, 'Proxy_Blade_Type', {}, dispatch),
});

export const DeviceSettingsContainer = connect(
  null,
  mapDispatchToProps
)(DeviceSettings);
