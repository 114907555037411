import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) => {
  return createStyles({
    loadingProgress: {
      top: '50%',
      position: 'absolute',
      alignSelf: 'center'
    },
    form: {
      paddingBottom: "0px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflowY: "auto"
    },
    vertical: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      width: "50%"
    },
    marginLeft: {
      marginLeft: theme.spacing.unit * 2
    },
    marginRight: {
      marginRight: theme.spacing.unit * 2
    },
    verticalContainer: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap"
    },
    horizontalContainer: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      flexWrap: "nowrap",
      justifyContent: "space-between"
    },
    fieldsContainer: {
      display: "flex",
      flexDirection: "column",
      flex: "0 1 auto"
    },
    formField: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
    formButton: {
      width: 250,
      justifyContent: "left"
    },
    iconLeft: {
      marginRight: theme.spacing.unit * 2
    }
  });
};

export const useStyles = makeStyles(styles);