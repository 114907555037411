import { EntityType, IdName } from "../../dataStore";
import { DeviceSettings } from "../../dataStore/types";

export const areaName = "newnity";

export interface NewnityState {
  enabled: boolean;
  inCompanyMode: boolean;
  selectedLocations: IdName[];
  currentCompany: CurrentCompanyState;
  currentLocation: CurrentLocationState; 
  search: SearchState;
}

export interface CurrentLocationState {
  savingData: boolean;
  deletingLocation: boolean;
}

export interface CurrentCompanyState {
  fetchingData: boolean;
  fetchError: string;
  isSaving: boolean;
  savingError: string;
  companyId: number;
}

export interface HasDeviceSettings {
  [key: string]: any;
  deviceSettings: DeviceSettings
}

export enum ActionTypes {
  SET_COMPANY = "SET_COMPANY",
  COMPANY_DATA_FETCH_REQUEST = "COMPANY_DATA_FETCH_REQUEST",
  COMPANY_DATA_FETCH_SUCCESS = "COMPANY_DATA_FETCH_SUCCESS",
  COMPANY_DATA_FETCH_ERROR = "COMPANY_DATA_FETCH_ERROR",
  SAVE_COMPANY_REQUEST = "SAVE_COMPANY_REQUEST",
  SAVE_COMPANY_SUCCESS = "SAVE_COMPANY_SUCCESS",
  SAVE_COMPANY_ERROR = "SAVE_COMPANY_ERROR",
  SAVE_LOCATION_REQUEST = "SAVE_LOCATION_REQUEST",
  SAVE_LOCATION_SUCCESS = "SAVE_LOCATION_SUCCESS",
  SAVE_LOCATION_ERROR = "SAVE_LOCATION_ERROR",
  SEARCH_SET_FIELDS = "SEARCH_UPDATE_FIELDS",
  SEARCH_SET_CURRENT_ENTITY = "SEARCH_UPDATE_CURRENT_ENTITY",
  SEARCH_SET_SEARCHED_ENTITY = "SEARCH_SET_SEARCHED_ENTITY",
  SEARCH_SET_TAB_INDEX = "SEARCH_SET_TAB_INDEX",
  SET_IN_COMPANY_MODE = "SET_IN_COMPANY_MODE",
  SET_SELECTED_LOCATIONS = "SET_SELECTED_LOCATIONS",
  DELETE_LOCATION_REQUEST = "DELETE_LOCATION_REQUEST",
  DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS",
  DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR"
}

export interface SearchState {
  currentEntity: EntityType;
  searchedEntity?: EntityType;
  fields: SearchFields;
  tabIndex: number;
}

export enum SearchEntity {
  Company,
  Location,
  Device
}

export interface SearchFields {
  company: CompanySearchFields;
  device: DeviceSearchFields;
  location: LocationSearchFields;
}

export interface CompanySearchFields {
  number: string;
  name: string;

  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;

  phone: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface DeviceSearchFields {
  deviceNumber: string;
  deviceDescription: string;
  serial: string;
  locationNumber: string;
  locationName: string;
  siteNumber: string;
  company: string;
  salesOrder: string;
}

export interface LocationSearchFields {
  locationNumber: string;
  locationName: string;
  deviceNumber: string;
  serial: string;
  siteNumber: string;
  company: string;
  
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}