import { action } from 'typesafe-actions';
import { ActionTypes, BladeInstance } from './types';

export const markWaitClose = (bladeId:string) => 
  action(ActionTypes.MARK_WAIT_CLOSE, bladeId);

  export const markShouldClose = (bladeId:string) => 
  action(ActionTypes.MARK_SHOULD_CLOSE, bladeId);

export const createBlade = (blade: BladeInstance) =>
  action(ActionTypes.CREATE_BLADE, blade);
export const closeBlade = (bladeId: string) =>
  action(ActionTypes.CLOSE_BLADE, bladeId);
export const cancelCloseBlade = () =>
  action(ActionTypes.CANCEL_DIRTY_BLADE_CLOSE);
export const forceCloseBlade = (bladeId: string) =>
  action(ActionTypes.FORCE_CLOSE_BLADE, bladeId);
export const setBladeDirtyState = (bladeId: string, isDirty: boolean) =>
  action(ActionTypes.SET_BLADE_DIRTY_STATE, { bladeId, isDirty });
export const setBladeNewlyCreateState = (
  bladeId: string,
  isNewlyCreated: boolean
) =>
  action(ActionTypes.SET_BLADE_NEWLY_CREATED_STATE, {
    bladeId,
    isNewlyCreated
  });
export const highlightBlades = (bladeIds: string[]) =>
  action(ActionTypes.HIGHLIGHT_BLADES, bladeIds);
export const clearHighlightedBlades = () =>
  action(ActionTypes.CLEAR_HIGHLIGHT_BLADES);
export const setTitle = (bladeId: string, value: string) =>
  action(ActionTypes.SET_TITLE, { bladeId, value });
