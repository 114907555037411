import { SearchEntity, SearchFields } from './types';
import {
  apiFetch,
  currentWorkgroupKey,
  getCookieValue,
  fetchHelper,
} from '../../v6/ajax';
import { NLocation, NDevice, NCompany, IdName } from '../../dataStore';
import { DeviceSettings } from '../../dataStore/types';

type CompanyDataWithoutDeviceSettings = Pick<
  NCompany,
  Exclude<keyof NCompany, 'deviceSettings'>
>;
type CompanyDto = {
  company: CompanyDataWithoutDeviceSettings;
  deviceSettings: DeviceSettings;
};

export const NewnityApi = {
  dtoToCompany: (dto: CompanyDto): NCompany => ({
    ...dto.company,
    deviceSettings: dto.deviceSettings,
  }),
  companyToDto: (company: NCompany) => {
    const justCompany = { ...company };
    delete justCompany['deviceSettings'];
    return { company: justCompany, deviceSettings: company.deviceSettings };
  },
  getCompanyInfo: async (
    companyId: number
  ): Promise<{
    company: Pick<NCompany, Exclude<keyof NCompany, 'deviceSettings'>>;
    deviceSettings: DeviceSettings;
  }> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/newnity/company/${companyId}`
    );

    return response.json();
  },
  saveCompanyInfo: async (company: NCompany, createMode: boolean): Promise<number> => {
    let response;
    const url = process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/company`;
    const saveUrl = createMode ? url : `${url}/${company.id}`;
    response = await fetchHelper(
      saveUrl,
      createMode ? 'POST' : 'PUT',
      NewnityApi.companyToDto(company)
    );
    return response.json();
  },

  getComanpies: async (): Promise<IdName[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/company`
    );
    return response.json();
  },

  getDevices: async (): Promise<NDevice[]> => {
    // const response = await apiFetch(
    //   process.env.REACT_APP_ROOT_DOMAIN +
    //     `v6/admin/newnity/devices/${companyId}`
    // );

    // return response.json();
    return new Promise(resolve => {
      setTimeout(() => resolve([]), 2000);
    });
  },

  search: async (
    fields: SearchFields,
    entity: SearchEntity
  ): Promise<IdName[]> => {
    let w: Number;
    if (
      (process.env.REACT_APP_HEADER_01 as string) ===
      'MVision-Current-WorkgroupId'
    ) {
      w = Number(getCookieValue(currentWorkgroupKey));
    } else {
      w = 1;
    }

    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN + `v6/admin/newnity/search`,
      'POST',
      {
        workgroupId: w,
        entity,
        fields,
      }
    );

    return response.json();
  },

  getLocations: async (companyId: number): Promise<NLocation[]> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/newnity/location/list/${companyId}`
    );

    return response.json();
  },

  getLocation: async (id: number): Promise<NLocation> => {
    const response = await apiFetch(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/newnity/location/${id}`
    );

    return response.json();
  },

  saveLocation: async (
    companyId: number,
    location: NLocation,
    create?: boolean
  ): Promise<NLocation> => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/newnity/location/${companyId}`,
      create ? 'POST' : 'PUT',
      location
    );
    return response.json();
  },

  deleteLocation: async(locationId: number) => {
    const response = await fetchHelper(
      process.env.REACT_APP_ROOT_DOMAIN +
        `v6/admin/newnity/location/${locationId}`,
        'DELETE');
    return response.status;
  }
};
