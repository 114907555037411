import React, { useState, useEffect, useCallback } from 'react';
import { HeaderAndContent } from '../../../components/bladeLayout/headerAndContent';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchFields } from '../../duck/types';
import { useStyles } from './search.jss';
import { EntitySwitch } from './entitySwitch';
import { Tabs } from './tabs';
import debounce from 'debounce';
import { ResultsContainer } from './results';
import { EntityType, IdName } from '../../../dataStore';

export interface SearchActions {
  setFields: (fields: SearchFields) => void;
  setCurrentEntity: (entity: EntityType) => void;
  setTabIndex: (index: number) => void;
  search: (fields: SearchFields, entity: EntityType) => void;
}

export interface SearchProps {
  currentEntity: EntityType;
  fields: SearchFields;
  tabIndex: number;
  companies: IdName[];
}

let debounceUpdateFields: any;

export const Search = (props: SearchProps & SearchActions) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [fields, setFields] = useState(props.fields);

  useEffect(() => {
    debounceUpdateFields = debounce(props.setFields, 250);
  }, []);

  const onChange = (key: string, value: any): void => {
    const [key1, key2] = key.split('.');
    const newFields = { ...fields };
    (newFields as any)[key1][key2] = value;
    setFields(newFields);
    debounceUpdateFields(newFields);
  };

  const submit = (e: any) => {
    e.preventDefault();

    props.search(fields, props.currentEntity);
  };

  const content = () => (
    <div className={classes.contentContainer}>
      <form className={classes.searchContainer} onSubmit={submit}>
        <EntitySwitch
          value={props.currentEntity}
          onChange={props.setCurrentEntity}
        />
        <Tabs
          entity={props.currentEntity}
          fields={fields}
          onChange={onChange}
          tabIndex={props.tabIndex}
          onTabChange={props.setTabIndex}
          companies={props.companies}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          className={classes.searchButton}
        >
          {t('newnity.search')}
        </Button>
      </form>
      <ResultsContainer />
    </div>
  );

  return <HeaderAndContent content={content} removePadding />;
};
