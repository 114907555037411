import React, { Component } from 'react';
import { Input, InputAdornment, IconButton, createStyles, Theme, WithStyles } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { debounce } from 'debounce';
import { withStyles } from '@material-ui/styles';
import i18n from '../../../localization/i18n';

export interface SearchPropsFromState {
  value: string;
  placeholder?: string;
  delay?: number;
}

export interface SearchActions {
  onChange: (value: string) => void;
}

export interface SearchState {
  value: string;
}

type SearchProps = SearchPropsFromState & SearchActions & WithStyles<typeof styles>;

const styles = () => createStyles({
  input: {
    paddingLeft: '6px'
  },
  cancelIcon: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

class Search extends Component<SearchProps, SearchState> {
  private debouncedOnChange: any;

  static defaultProps = {
    value: '',
    placeholder: i18n.t('search.placeholder'),
    onChange: () => {},
    delay: 250
  }

  constructor(props: SearchProps) {
    super(props);
    this.state = {
      value: props.value
    };

    if (props.onChange !== undefined) {
      this.debouncedOnChange = debounce(props.onChange, props.delay);
    }
  }

  handleChange = (value: string) => {
    this.setState({
      value
    }, () => {
      this.debouncedOnChange(value);
    });
  }

  render = () => (
    <Input
      type={'text'} 
      value={this.state.value}
      placeholder={this.props.placeholder}
      onChange={e => this.handleChange(e.target.value)}
      autoFocus={true}
      className={this.props.classes.input}
      endAdornment={
        <InputAdornment position="end">
          <IconButton 
            disableRipple={true}
            className={this.props.classes.cancelIcon}
            onClick={_ => this.handleChange('')}>
              <Cancel />
          </IconButton>
        </InputAdornment>
      }/>
  );
}

const styledSearch = withStyles(styles)(Search);

export { styledSearch as Search };