import { Dispatch } from 'redux';
import { createAction } from 'typesafe-actions';
import * as ActionTypes from './actionTypes';
import { IdName } from '../../../dataStore';

export const clearSelection = createAction(
  ActionTypes.CLEAR_SELECTION,
  action => (key: string) => action({ key })
);

export const clearMultipleSelections = createAction(
  ActionTypes.CLEAR_MULTIPLE_SELECTIONS,
  action => (keys: string[]) => action({ keys })
);

export const updateSelection = createAction(
  ActionTypes.UPDATE_SELECTION,
  action => (key: string, items: IdName[]) => action({ key, items })
);