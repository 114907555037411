import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';
import * as Actions from './actions';
import { ActionTypes, NewnityState, SearchFields } from './types';
import { EntityType } from '../../dataStore';

export const initialSearchFields: SearchFields = {
  company: {
    number: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    email: '',
    firstName: '',
    lastName: '',
  },
  device: {
    deviceNumber: '',
    deviceDescription: '',
    serial: '',
    locationNumber: '',
    locationName: '',
    siteNumber: '',
    company: '',
    salesOrder: '',
  },
  location: {
    locationNumber: '',
    locationName: '',
    deviceNumber: '',
    serial: '',
    siteNumber: '',
    company: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
};

export let initialNewnityState: NewnityState = {
  enabled: false,
  inCompanyMode: false,
  selectedLocations: [],
  currentCompany: {
    fetchingData: false,
    companyId: 0,
    fetchError: '',
    isSaving: false,
    savingError: '',
  },
  currentLocation: {
    savingData: false,
    deletingLocation: false,
  },
  search: {
    currentEntity: EntityType.NCompanySearchResult,
    tabIndex: 0,
    fields: initialSearchFields,
  },
};

type NewnityActions = ActionType<typeof Actions>;

export const newnityReducer: Reducer<NewnityState, NewnityActions> = (
  state: NewnityState = initialNewnityState,
  action: NewnityActions
) => {
  switch (action.type) {
    case ActionTypes.SET_COMPANY: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          companyId: action.payload,
        },
      };
    }
    case ActionTypes.COMPANY_DATA_FETCH_REQUEST: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          fetchingData: true,
          fetchComplete: false,
          fetchError: '',
        },
      };
    }
    case ActionTypes.COMPANY_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          fetchingData: false,
          fetchComplete: true,
          fetchError: '',
        },
      };
    }

    case ActionTypes.COMPANY_DATA_FETCH_ERROR: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          fetchingData: false,
          fetchComplete: false,
          fetchError: action.payload,
        },
      };
    }
    case ActionTypes.SAVE_COMPANY_REQUEST: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          isSaving: true,
        },
      };
    }
    case ActionTypes.SAVE_COMPANY_SUCCESS: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          isSaving: false,
          companyId: action.payload
        }

      };
    }
    case ActionTypes.SAVE_COMPANY_ERROR: {
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          isSaving: false,
          savingError: action.payload,
        },
      };
    }
    case ActionTypes.SEARCH_SET_FIELDS: {
      return {
        ...state,
        search: {
          ...state.search,
          fields: action.payload,
        },
      };
    }
    case ActionTypes.SEARCH_SET_CURRENT_ENTITY: {
      return {
        ...state,
        search: {
          ...state.search,
          currentEntity: action.payload,
        },
      };
    }
    case ActionTypes.SEARCH_SET_SEARCHED_ENTITY: {
      return {
        ...state,
        search: {
          ...state.search,
          searchedEntity: action.payload,
        },
      };
    }
    case ActionTypes.SEARCH_SET_TAB_INDEX: {
      return {
        ...state,
        search: {
          ...state.search,
          tabIndex: action.payload,
        },
      };
    }
    case ActionTypes.SAVE_LOCATION_REQUEST: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          savingData: true,
        },
      };
    }
    case ActionTypes.SAVE_LOCATION_ERROR:
    case ActionTypes.SAVE_LOCATION_SUCCESS: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          savingData: false,
        },
      };
    }
    case ActionTypes.DELETE_LOCATION_REQUEST: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          deletingLocation: true,
        },
      };
    }
    case ActionTypes.DELETE_LOCATION_SUCCESS:
    case ActionTypes.DELETE_LOCATION_ERROR: {
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          deletingLocation: false,
        },
      };
    }
    case ActionTypes.SET_IN_COMPANY_MODE: {
      return {
        ...state,
        inCompanyMode: action.payload
      }
    }
    case ActionTypes.SET_SELECTED_LOCATIONS: {
      return {
        ...state,
        selectedLocations: action.payload
      }
    }
    default:
      return state;
  }
};

export const setInitialState = (enabled: boolean) => {
  initialNewnityState = {
    ...initialNewnityState,
    enabled,
  };
};
