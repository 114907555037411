import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Delete, CloudUpload } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BladeProps } from '../../../blade';
import { HeaderAndContent } from '../../../components/bladeLayout/headerAndContent';
import { TreeContainer } from '../../../components/tree';
import { areaName } from '../../duck/types';
import { useStyles } from './workgroupList.jss';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { Search as SearchComponent } from '../../../components/search/search';
import { WorkgroupToolbar } from './workgroupToolbar';
import { WorkgroupCreateBladeType } from '../workgroupCreate/workgroupCreateContainer';

export interface WorkgroupListProps {
  pickSelectionModeOn: boolean;
  favoriteItems: number[];
  itemsFetching: boolean;
  filterValue: string;
}

export interface WorkgroupListActions {
  fetchWorkgroups: () => void;
  openBladeCreate: (bladeType: string, props: object) => void;
  openBladeDetails: (workgroupId: number, workgroupName: string) => void;
  setWorkgroupAsFavorite: (workgroupId: number) => void;
  removeWorkgroupAsFavorite: (workgroupId: number) => void;
  leavePickMode: (workgroupId: number, treeItemId: string) => void;
  expandAll: () => void;
  collapseAll: () => void;
  selectAll: () => void;
  unselectAll: () => void;
  filter: (value: string) => void;
  onBladeClose: () => void;
}

type Props = WorkgroupListProps & WorkgroupListActions & BladeProps;

export const WORKGROUP_TREE_NAME = 'workgroupsTree';

export const WorkgroupList: React.FunctionComponent<Props> = props => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [searchVisible, setSearchVisible] = useState(false);

  props.onClose(() => {
    props.filter('');
    props.unselectAll();
    return true;
  });

  useEffect(() => {
    props.fetchWorkgroups();
  }, []);

  const onWorkgroupSelected = (workgroupId: number, treeItemId: string) => {
    if(props.pickSelectionModeOn) { 
      props.leavePickMode(workgroupId, treeItemId);
    }
  };

  const header = () =>
    props.pickSelectionModeOn ? (
      <Paper color="secondary" className={classes.pickSelectionModeContainer}>
        <Typography
          className={classes.pickSelectionModeContent}
          variant="caption"
        >
          {t('workgroups.pickSelectionModeCaption')}
        </Typography>
        <Button
          size="small"
          variant="text"
          onClick={() => props.leavePickMode(0, '')}
        >
          {t('exit')}
        </Button>
      </Paper>
    ) : (
      <div>
        <Button
          className={classes.headerButton}
          variant="contained"
          color="secondary"
          onClick={() => props.openBladeCreate(WorkgroupCreateBladeType, {})}
        >
          <AddIcon className={classes.headerButtonIconSmall} />
          <span className={classes.headerButtonText}>{t('create')}</span>
        </Button>
        <Button className={classes.headerButton} variant="text">
          <CloudUpload className={classes.headerButtonIconSmall} />
          <span className={classes.headerButtonText}>{t('import')}</span>
        </Button>
        <Button className={`${classes.headerButton} ${classes.headerButtonLast}`} variant="text">
          <Delete className={classes.headerButtonIconSmall} />
          <span className={classes.headerButtonText}>{t('delete')}</span>
        </Button>
      </div>
    );

  const content = () => (
    <div className={classes.workgroupsContainer}>
      <WorkgroupToolbar
        workgroupPickMode={props.pickSelectionModeOn}
        expandAll={props.expandAll}
        collapseAll={props.collapseAll}
        selectAll={props.selectAll}
        unselectAll={props.unselectAll}
        toggleSearch={() => setSearchVisible(!searchVisible)}
      />
      {searchVisible && (
        <SearchComponent value={props.filterValue} onChange={props.filter} />
      )}
      <div className={classes.workgroupsTree}>
        <TreeContainer
          treeName={WORKGROUP_TREE_NAME}
          areaName={areaName}
          onSelect={onWorkgroupSelected}
        />
      </div>
    </div>
  );

  return props.itemsFetching ? (
    <CircularProgress className={classes.loadingProgress} size={60} />
  ) : (
    <HeaderAndContent removePadding header={header} content={content} />
  );
};
