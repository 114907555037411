export const areaName = 'blade';
import React from 'react';

export interface BladeState {
  items: NormalizedBlades;
  dirtyClosing: {
    bladeIdForClosing: string;
    dirtyChildrenIds: string[];
  };
}

export interface NormalizedBlades {
  [id: string]: BladeInstance;
}

export interface BladeInstance {
  parentId: string;
  id: string;
  type: string;
  props: any;
  isDirty: boolean;
  title: string;
  newlyCreated: boolean;
  closeParentId: string;

  frozen: boolean;

  //default false, should set this if we want to run cleanup when closing blade
  waitOnClose: boolean;
  //we run the cleanup inside the blade on prop.isClosing and then call on close
  isClosing: boolean;
  //default false, this tells the blade it can get deleted from the state
  shouldClose: boolean;
}

export interface BladeConfig<TProps> {
  bladeType: string;
  size: { defaultWidth: number; minWidth: number };
  title: (props: TProps) => string;
  id: (props: TProps) => string;
  allowMultipleInstances: boolean;
  numberOfInstances?: number;
}

export interface BladeProps {
  bladeId: string;
  isDirty: boolean;
  setDirty: (dirty: boolean) => void; 
  onClose: (canClose: () => boolean) => void;
}

export enum ActionTypes {
  MARK_SHOULD_CLOSE = 'MARK_SHOULD_CLOSE',
  MARK_WAIT_CLOSE = 'MARK_WAIT_CLOSE',
  CREATE_BLADE = 'CREATE_BLADE',
  CLOSE_BLADE = 'CLOSE_BLADE',
  CANCEL_DIRTY_BLADE_CLOSE = 'CANCEL_DIRTY_BLADE_CLOSE',
  FORCE_CLOSE_BLADE = 'FORCE_CLOSE_BLADE',
  SET_BLADE_DIRTY_STATE = 'SET_BLADE_DIRTY_STATE',
  SET_BLADE_NEWLY_CREATED_STATE = 'SET_BLADE_NEWLY_CREATED_STATE',
  HIGHLIGHT_BLADES = 'HIGHLIGHT_BLADES',
  CLEAR_HIGHLIGHT_BLADES = 'CLEAR_HIGHLIGHT_BLADES',
  SET_TITLE = 'SET_TITLE',
}
