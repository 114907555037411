import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./search.jss";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { EntityType } from "../../../dataStore";

export interface EntitySwitchProps {
  value: EntityType;
  onChange(value: EntityType): void;
}
export const EntitySwitch = React.memo((props: EntitySwitchProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <RadioGroup
      value={props.value.toString()}
      onChange={(e, v) => {
        props.onChange(v as EntityType);
      }}
      row={true}
      classes={{ root: classes.entitySwitch }}
    >
      <FormControlLabel
        value={EntityType.NCompanySearchResult}
        control={<Radio />}
        label={t("newnity.company")}
      />
      <FormControlLabel
        value={EntityType.NLocationSearchResult}
        control={<Radio />}
        label={t("newnity.location")}
      />
      <FormControlLabel
        value={EntityType.NDeviceSearchResult}
        control={<Radio />}
        label={t("newnity.device")}
      />
    </RadioGroup>
  );
});