import Button from '@material-ui/core/Button';
import { Add, Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderAndContent } from '../../../../components/bladeLayout/headerAndContent';
import { EntityType, IdName, NLocation } from '../../../../dataStore';
import { useStyles } from './locationList.jss';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {
  DataGridContainer,
  DataGridSelectionType,
} from '../../../../components/dataGrid';

export interface LocationListProps {
  companyId: number;
  items: NLocation[];
  selected: IdName[];
  locationsFetching: boolean;
  locationDeleting: boolean;
}

export interface LocationListActions {
  fetchLocations: (companyId: number) => void;
  createLocation: () => void;
  editLocation: (id: number, name: string) => void;
  deleteLocation: (id: number, name: string) => void;
  setSelected: (items: IdName[]) => void;
}

type Props = LocationListActions & LocationListProps;

interface DeleteConfirmDialogProps {
  open: boolean;
  locationId: number;
  locationName: string;
  onCancel: () => void;
  onDelete: (id: number, name: string) => void;
}

const DeleteConfirmDialog = React.memo(
  (props: DeleteConfirmDialogProps) => {
    const [t] = useTranslation();
    return (
      <Dialog open={props.open}>
        <DialogTitle>
          {t('newnity.list.location.delete.confirm.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('newnity.list.location.delete.confirm.content', {
              locationName: props.locationName,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} color="primary" autoFocus>
            {t('cancel')}
          </Button>
          <Button
            onClick={() => props.onDelete(props.locationId, props.locationName)}
            color="primary"
          >
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
  (prevProps, nextProps) => prevProps.open === nextProps.open
);

export const LocationList: React.FunctionComponent<Props> = props => {
  useEffect(() => {
    props.fetchLocations(props.companyId);
  }, []);
  const [deleteConfirmLocation, setDeleteConfirmLocation] = useState<{
    id: number;
    name: string;
    delete: boolean;
  }>({ id: 0, name: '', delete: false });

  const classes = useStyles();
  const [t] = useTranslation();

  const edit = () => {
    if(props.selected.length > 0) {
      props.editLocation(props.selected[0].id, props.selected[0].name);
    }
  };

  const deleteLocation = () => {
    if (props.selected.length > 0) {
      const location = props.selected[0];
      setDeleteConfirmLocation({
        id: location.id,
        name: location.name,
        delete: true,
      });
    }
  };
  
  const header = () => (
    <div>
      <Button
        className={classes.headerButton}
        variant="contained"
        color="secondary"
        onClick={props.createLocation}
      >
        <Add className={classes.headerButtonIconSmall} />
        {t('create')}
      </Button>
      <Button className={classes.headerButton} onClick={edit}>
        <Edit className={classes.headerButtonIconSmall} />
        {t('edit')}
      </Button>
      <Button className={classes.headerButton} onClick={deleteLocation}>
        <Delete
          className={classes.headerButtonIconSmall}
        />
        {t('delete')}
      </Button>
    </div>
  );
  const content = () => (
    <div className={classes.container}>
      <DataGridContainer
        entityType={EntityType.NLocation}
        onSelect={(items) => { props.setSelected(items) }}
        items={props.items}
        selectedIds={props.selected}
        selectionType={DataGridSelectionType.SingleOrNone}
        visibleColumns={['name', 'country', 'city', 'timezone']}
      />
      <DeleteConfirmDialog
        open={deleteConfirmLocation.delete}
        locationName={deleteConfirmLocation.name}
        locationId={deleteConfirmLocation.id}
        onDelete={(id, name) => {
          props.deleteLocation(id, name);
          setDeleteConfirmLocation({
            id: 0,
            name: '',
            delete: false,
          });
        }}
        onCancel={() =>
          setDeleteConfirmLocation({
            id: 0,
            name: '',
            delete: false,
          })
        }
      />
    </div>
  );
  return (
    <HeaderAndContent
      header={header}
      content={content}
      removePadding={true}
      isBusy={props.locationsFetching || props.locationDeleting}
    />
  );
};
