import { createSelector } from 'reselect';
import { getDataStore } from './genericSelectors';
import memoizeOne from 'memoize-one';
import { Workgroup } from '../types';

export const selectWorkgroups = (state: any) => getDataStore(state).workgroup.items;

export const selectWorkgroupItems = createSelector(
  [selectWorkgroups],
  workgroups => {
    return Object.keys(workgroups).map(key => workgroups[Number(key)]);
  }
);

export const selectWorkgroupById = (state: any, workgroupId: number) => getDataStore(state).workgroup.items[workgroupId];

// export const selectWorkgroupsForWorkgroups = createSelector(
//   [selectWorkgroupItems],
//   (workgroups: Workgroup[]) =>
//     memoizeOne((workgroupIds: number[]) =>
//       workgroups.filter(workgroup => workgroupIds.indexOf(workgroup.workgroupId) !== -1)
//     )
// );

// export const selectWorkgroupsForSites = createSelector(
//   [selectWorkgroupItems],
//   (workgroups: Workgroup[]) =>
//     memoizeOne((siteIds: number[]) =>
//       workgroups.filter(workgroup => siteIds.indexOf(workgroup.siteId) !== -1)
//     )
// );
