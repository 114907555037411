import React from "react";
import { ContentWrapper } from "./contentWrapper";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./tabs.jss";
import { TextFieldWrapper } from "./textFieldWrapper";
import { countries } from '../../../../dataStore/countries';
import { Autocomplete } from "../../../../components/autocomplete";
import { CountryInfo } from "../../../../dataStore/types";

export interface AddressTabProps {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  onChange(key: string, value: any): void;
}

export const AddressTab = (props: AddressTabProps) => {
  const [t] = useTranslation();
  const classes = useStyles();
  
  return (
    <ContentWrapper>
      <TextFieldWrapper
        value={props.address}
        onChange={e => props.onChange("address", e.currentTarget.value)}
        label={t("newnity.search.address")}
      />
      <TextFieldWrapper
        value={props.city}
        onChange={e => props.onChange("city", e.currentTarget.value)}
        label={t("newnity.search.city")}
      />
      <TextFieldWrapper
        value={props.state}
        onChange={e => props.onChange("state", e.currentTarget.value)}
        label={t("newnity.search.state")}
      />
      <TextFieldWrapper
        value={props.zip}
        onChange={e => props.onChange("zip", e.currentTarget.value)}
        label={t("newnity.search.zip")}
      />
      <FormControl className={classes.selectMargin}>
        <Autocomplete
          items={countries}
          initialValue={props.country}
          minCharInput={2}
          textFieldProps={{name: "something", label: t("newnity.search.country") }}
          onChange={(item: CountryInfo | undefined) =>
            props.onChange("country", item ? item.name : '')
          }
        />
      </FormControl>
    </ContentWrapper>
  );
};
