import React, { useCallback, useMemo, useState, useEffect } from 'react';
import i18next from 'i18next';
import { debounce } from 'debounce';
import { useTranslation } from 'react-i18next';
import { BladeProps } from '../../../../blade';
import { FooterSubmit } from '../../../../components/bladeLayout/footerSubmit';
import { TabsHeaderAndContent } from '../../../../components/bladeLayout/tabsHeaderAndContent';
import { DeviceSettingsContainer } from '../../../components/deviceSettings/deviceSettingsContainer';
import { useStyles } from './locationEdit.jss';
import { LocationDetails } from './locationDetails';
import {
  Form,
  Formik,
  FormikActions,
  FormikErrors,
} from 'formik';
import { NLocation } from '../../../../dataStore';

export interface LocationEditProps {
  location: NLocation;
  companyId: number;
  isSaving: boolean;
}

export interface LocationEditActions {
  configureWiFiSettings: () => void;
  createLocation: (companyId: number, location: NLocation) => void;
  fetchLocation: (id: number) => void;
  closeBlade: () => void;
}

type Props = LocationEditProps & LocationEditActions & BladeProps;

export const LocationEdit: React.FunctionComponent<Props> = props => {
  const [t] = useTranslation();
  // const [createPressed, setEditPressed] = useState(false);
  const classes = useStyles();
  const createMode = props.location.id === 0;

  useEffect(() => {
    // location is not yet in datastore
    if (props.location.id && props.location.workgroupId === 0) {
      props.fetchLocation(props.location.id)
    }
  }, [])
  const tabs = useMemo(
    () => [
      { label: t('newnity.edit.location.tab.details') },
      { label: t('newnity.edit.location.tab.defaultSettings') },
    ],
    []
  );
  const content = useCallback(
    index =>
      index === 0 ? (
        <LocationDetails />
      ) : (
        <DeviceSettingsContainer parentBladeId={props.bladeId} />
      ),
    []
  );
  const footer = useCallback(
    () => (
      <FooterSubmit
        hasCancelButton={true}
        cancelButtonLabel={t('footer.cancel')}
        cancel={props.closeBlade}
        submitButtonLabel={createMode ? t('footer.create') : t('footer.save')}
        submit={() => {}}
        submitInProgress={props.isSaving}
      />
    ),
    []
  );
  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validate={(values: NLocation) => {
        let errors: FormikErrors<NLocation> = {};
        errors = checkRequiredField(errors, 'name', values.name, t);
        errors = checkRequiredField(  
          errors,
          'oracleNumber',
          values.oracleNumber,
          t
        );
        errors = checkRequiredField(
          errors,
          'clientSiteId',
          values.clientSiteId,
          t
        );
        errors = checkRequiredField(errors, 'country', values.country, t);
        errors = checkRequiredField(errors, 'timezone', values.timezone, t);
        errors = checkRequiredField(errors, 'city', values.city, t);
        errors = checkRequiredField(errors, 'state', values.state, t);
        return errors;
      }}
      initialValues={{ ...props.location }}
      onSubmit={(values: NLocation, actions: FormikActions<NLocation>) => {
        props.createLocation(props.companyId, values);
      }}
      render={() => (
        <Form
          autoComplete="off"
          className={classes.container}
          onChange={
            props.isDirty
              ? undefined
              : debounce(() => props.setDirty(true), 250)
          }
        >
          <TabsHeaderAndContent
            disabled={props.isSaving}
            tabs={tabs}
            content={content}
            footer={footer}
          />
        </Form>
      )}
    />
  );
};

const checkRequiredField = (
  errors: FormikErrors<NLocation>,
  name: keyof FormikErrors<NLocation>,
  value: string | undefined,
  t: i18next.TFunction
) => {
  if (!value) {
    errors[name] = t('newnity.company.field.error');
  }
  return errors;
};
