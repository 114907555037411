import { MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bladeConnect, BladeProps, openBlade } from '../../../../blade';
import { fetchLocations, deleteLocation } from '../../../duck/thunks';
import { selectCompanyId, selectDeletingLocation } from '../../../duck/selectors';
import { DataStoreSelectors, EntityType } from '../../../../dataStore';
import {
  LocationListActions,
  LocationListProps,
  LocationList,
} from './locationList';
import {
  LocationEditBladeType,
} from '../edit/locationEditContainer';
import { NewnityState } from '../../../duck/types';
import { setSelectedLocations } from '../../../duck/actions';

const mapStateToProps: MapStateToProps<LocationListProps, BladeProps, any> = (
  state,
  _
) => {
  return {
    companyId: selectCompanyId(state),
    items: DataStoreSelectors.NLocation.selectLocationItems(state),
    locationsFetching: DataStoreSelectors.getDataStoreItemsFetching(
      state,
      EntityType.NLocation
    ).isFetching,
    selected: (state.newnity as NewnityState).selectedLocations,
    locationDeleting: selectDeletingLocation(state)
  };
};

const mapDispatchToProps: MapDispatchToProps<
  LocationListActions,
  BladeProps
> = (dispatch, _) => {
  return {
    fetchLocations: (companyId: number) =>
      dispatch<any>(fetchLocations(companyId)),
    createLocation: () =>
      openBlade(LocationListBladeType, LocationEditBladeType, {}, dispatch),
    editLocation: (id: number, name: string) =>
      openBlade(
        LocationListBladeType,
        LocationEditBladeType,
        { locationSiteId: id, locationName: name },
        dispatch
      ),
    setSelected: items => dispatch(setSelectedLocations(items)),
    deleteLocation: (id: number, name: string) =>
      dispatch<any>(deleteLocation(id, name)),
  };
};

export const LocationListBladeType = 'NEWNITY_LOCATION_LIST';

const bladeConfig = {
  size: { defaultWidth: 600, minWidth: 600 },
  bladeType: LocationListBladeType,
  id: (_: any) => LocationListBladeType,
  title: (_: any) => 'Locations',
  allowMultipleInstances: false,
};

export const LocationListContainer = bladeConnect(
  mapStateToProps,
  mapDispatchToProps,
  bladeConfig
)(LocationList);
