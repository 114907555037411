import { createAction } from 'typesafe-actions';
import { ActionTypes } from './types';

export const expandItem = createAction(
  ActionTypes.TREE_EXPAND_ITEM,
  action => (areaName: string, treeName: string, treeItemId: string) =>
    action({ areaName, treeName, treeItemId })
);

export const collapseItem = createAction(
  ActionTypes.TREE_COLLAPSE_ITEM,
  action => (areaName: string, treeName: string, treeItemId: string) =>
    action({ areaName, treeName, treeItemId })
);

export const expandAll = createAction(
  ActionTypes.TREE_EXPAND_ALL,
  action => (areaName: string, treeName: string) =>
    action({ areaName, treeName })
);

export const collapseAll = createAction(
  ActionTypes.TREE_COLLAPSE_ALL,
  action => (areaName: string, treeName: string) =>
    action({ areaName, treeName })
);

export const selectItem = createAction(
  ActionTypes.TREE_SELECT_ITEM,
  action => (areaName: string, treeName: string, treeItemId: string) =>
    action({ areaName, treeName, treeItemId })
);

export const unselectItem = createAction(
  ActionTypes.TREE_UNSELECT_ITEM,
  action => (areaName: string, treeName: string, treeItemId: string) =>
    action({ areaName, treeName, treeItemId })
);

export const selectAll = createAction(
  ActionTypes.TREE_SELECT_ALL,
  action => (areaName: string, treeName: string) =>
    action({ areaName, treeName })
);

export const unselectAll = createAction(
  ActionTypes.TREE_UNSELECT_ALL,
  action => (areaName: string, treeName: string) =>
    action({ areaName, treeName })
);

export const filter = createAction(
  ActionTypes.TREE_FILTER,
  action => (areaName: string, treeName: string, filterValue: string) =>
    action({ areaName, treeName, filterValue })
);
