import React from "react";
import { useStyles } from "./results.jss";
import { CircularProgress } from "@material-ui/core";

export const Loading = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.centerContainer}>
      <CircularProgress size={60} className={classes.progress} />
    </div>
  );
};