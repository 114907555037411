import { Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import { ActionTypes, Notification, NotificationAction } from './types';
import { VariantType } from 'notistack';

export const pushNotification = (message: string, type: VariantType = 'info', notificationAction?: NotificationAction) => {
  const notification: Notification = {
    key : new Date().getTime() + Math.random(),
    message,
    type,
    action: notificationAction
  };
  return action(ActionTypes.PUSH_NOTIFICATION, notification);
};
export const removeNotification = (notificationKey: number) =>
  action(ActionTypes.REMOVE_NOTIFICATION, notificationKey);
