import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Measure, { ContentRect } from 'react-measure';
import { IdName, NormalizedIdNames, EntityType } from '../../dataStore';
import { useStyles } from './dataGrid.jss';
import {
  Grid,
  TableHeaderRow,
  TableFilterRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Column,
  FilteringState,
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';

export interface DataGridStateProps {
  items: IdName[];
}

export enum DataGridSelectionType {
  None,
  Single,
  SingleOrNone,
  Multiple,
  MultipleOrNone,
}

export interface DataGridProps {
  entityType: EntityType;
  selectionType: DataGridSelectionType;
  onSelect: (items: IdName[]) => void;
  selectedIds?: IdName[];
  visibleColumns?: string[];
  showSelectionColumn?: boolean;
}

export type Props = DataGridProps &
  DataGridStateProps & {
    children?: React.ReactNode;
  };

const columnsBuilder = (
  entity: object | undefined,
  entityType: EntityType,
  t: i18next.TFunction,
  visibleColumns?: string[]
) => {
  const columns: Column[] = [];
  const columnsFiltering: FilteringState.ColumnExtension[] = [];
  if (entity) {
    const keys = visibleColumns !== undefined ? 
      visibleColumns : Object.keys(entity);

    keys.forEach(key => {
      columns.push({
        name: key,
        title: t(`${entityType}.columnTitle.${key}`),
      });
      columnsFiltering.push({ columnName: key, filteringEnabled: true });
    });
  }
  return { columns, columnsFiltering };
};

export const DataGrid: React.FunctionComponent<Props> = React.memo(
  (props: Props) => {
    const classes = useStyles();
    const [t] = useTranslation();
    const [height, setHeight] = useState(0);
    const [selected, setSelected] = useState(
      props.selectedIds ? props.selectedIds.map(item => item.id) : []
    );

    const onSelectionChange = (selectedIds: Array<number | string>) => {
      const gridSelection = selectedIds.map(id => Number(id));
      let newSelection: number[] = [];

      switch (props.selectionType) {
        case DataGridSelectionType.SingleOrNone:
        case DataGridSelectionType.Single: {
          const lastSelected = gridSelection.find(
            id => selected.indexOf(id) === -1
          );
          if (lastSelected !== undefined) {
            newSelection = [lastSelected];
          } else if (
            props.selectionType === DataGridSelectionType.SingleOrNone
          ) {
            newSelection = [];
          }
          break;
        }
        default:
          newSelection = gridSelection;
      }
      
      setSelected(newSelection);
      props.onSelect(
        props.items.filter(
          row => newSelection.findIndex(selectId => selectId === row.id) !== -1
        )
      );
    };

    const sizeChanged = (contentRect: ContentRect) => {
      const h = contentRect.bounds ? contentRect.bounds.height : 0;
      setHeight(h);
    };

    const { columns, columnsFiltering } = columnsBuilder(
      props.items[0],
      props.entityType,
      t,
      props.visibleColumns
    );

    const selectionEnabled = props.selectionType !== DataGridSelectionType.None;
    const multipleSelection =
      props.selectionType === DataGridSelectionType.Multiple ||
      props.selectionType === DataGridSelectionType.MultipleOrNone;
    const getUniqueRowId = (row: IdName) => row.id;
    return (
      <Measure bounds onResize={sizeChanged}>
        {({ measureRef }) => (
          <div ref={measureRef} className={classes.gridContainer}>
            <Grid
              getRowId={getUniqueRowId}
              rows={props.items}
              columns={columns}
            >
              <FilteringState
                defaultFilters={[]}
                columnExtensions={columnsFiltering}
              />
              <IntegratedFiltering />
              {selectionEnabled && (
                <SelectionState
                  onSelectionChange={onSelectionChange}
                  selection={selected}
                />
              )}
              {selectionEnabled && <IntegratedSelection />}
              <SortingState
                defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
              />
              <IntegratedSorting />
              <VirtualTable height={height} estimatedRowHeight={48} />
              <TableHeaderRow showSortingControls />
              {selectionEnabled && (
                <TableSelection
                  showSelectAll={multipleSelection}
                  showSelectionColumn={props.showSelectionColumn !== undefined ? props.showSelectionColumn : true}
                  selectByRowClick={true}
                  highlightRow={true}
                />
              )}
              <TableFilterRow />
            </Grid>
          </div>
        )}
      </Measure>
    );
  }
);
