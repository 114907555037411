import {
  NormalizedTreeItems,
  ItemWithParent,
  SelectionMode,
  TreeState
} from "../types";
import { build } from "./treeBuilder";

export const initTree = (
  treeName: string,
  dataItems: ItemWithParent[],
  selectionMode: SelectionMode = SelectionMode.SingleSelection,
  level: number = 1
): TreeState => {
  const items = build(dataItems);
  const { tree, expanded } = expandToLevel(items, level);

  return {
    treeName,
    items: items,
    expandedDefault: [...expanded],
    expandedBeforeFilter: [],
    unfilteredTree: tree,
    filteredTree: [],
    selectionMode,
    selectedItems: {},
    itemActions: {},
    filterValue: ""
  };
};

const expandToLevel = (
  items: NormalizedTreeItems,
  level: number
) => {
  const itemIds = Object.keys(items);
  const tree = itemIds.filter(id => items[id].level <= level);
  const expanded = tree
    .map(id => items[id])
    .filter(item => item.level <= level - 1)
    .map(item => {
      item.isExpanded = true;
      return item.treeId;
    });

  return { tree, expanded };
};
