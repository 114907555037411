import classNames from 'classnames';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '../../../../components/autocomplete';
import { CompanyInfoActions, CompanyInfoProps } from './company';
import { countries } from '../../../../dataStore/countries';
import { CountryInfo, NCompany } from '../../../../dataStore';
import { FastField, FieldProps } from 'formik';
import { FieldButton } from '../../../../components/fieldButton';
import { helperText, validateEmptyField } from '../../../../utils/formik';
import { InputAdornment } from '@material-ui/core';
import { useStyles } from './company.jss';
import { useTranslation } from 'react-i18next';

export const CompanyDetailsFields: React.FunctionComponent<
  CompanyInfoProps & CompanyInfoActions
> = props => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.fieldsContainer}>
      <div className={classes.horizontalContainer}>
        <div className={classNames([classes.vertical, classes.marginRight])}>
          <FastField
            name="name"
            render={({ field, form }: FieldProps<NCompany>) => {
              return (
                <TextField
                  autoFocus={true}
                  label={t('newnity.company.name')}
                  inputProps={{ maxLength: 255 }}
                  helperText={helperText(form, field, form.errors.name as string)}
                  error={validateEmptyField(form, field)}
                  autoComplete={'deny'}
                  {...field}
                  value={field.value || ''}
                  className={classes.formField}
                  margin="normal"
                />
              );
            }}
          />
          <FastField
            name="number"
            render={({ field, form }: FieldProps<NCompany>) => {
              return (
                <TextField
                  label={t('newnity.company.number')}
                  inputProps={{ maxLength: 255 }}
                  helperText={helperText(form, field, form.errors.number as string)}
                  {...field}
                  error={validateEmptyField(form, field)}
                  value={field.value || ''}
                  autoComplete={'deny'}
                  className={classes.formField}
                  margin="normal"
                />
              );
            }}
          />
        </div>
        <div className={classNames([classes.vertical, classes.marginLeft])}>
          <FastField
            name="parentId"
            render={({ field, form }: FieldProps<NCompany>) => (
              <TextField
                disabled
                label={t('newnity.company.parentWorkgroup')}
                {...field}
                error={validateEmptyField(form, field)}
                value={field.value || ''}
                className={classes.formField}
                helperText={helperText(form, field, form.errors.parentId as string)}
                autoComplete={'deny'}
                margin="normal"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <FieldButton
                        title={t('create.workgroup.select_btn_label')}
                        onClick={() => {}}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>
      <div className={classes.horizontalContainer}>
        <div className={classNames([classes.vertical, classes.marginRight])}>
          <FastField
            name="country"
            render={({ field, form }: FieldProps<NCompany>) => (
              <Autocomplete
                items={countries}
                initialValue={form.initialValues.country}
                minCharInput={2}
                textFieldProps={{
                  name: 'something',
                  label: t('newnity.company.address.country'),
                }}
                onChange={(selectedItem: CountryInfo | undefined) =>
                  form.setFieldValue(field.name, selectedItem ? selectedItem.name : '')
                }
              />
            )}
          />
          <FastField
            name="city"
            render={({ field, form }: FieldProps<NCompany>) => (
              <TextField
                label={t('newnity.company.address.city')}
                {...field}
                value={field.value || ''}
                autoComplete={'deny'}
                className={classes.formField}
                margin="normal"
              />
            )}
          />
          <FastField
            name="state"
            render={({ field, form }: FieldProps<NCompany>) => (
              <TextField
                label={t('newnity.company.address.state')}
                {...field}
                value={field.value || ''}
                autoComplete={'deny'}
                className={classes.formField}
                margin="normal"
              />
            )}
          />
        </div>
        <div className={classNames([classes.vertical, classes.marginLeft])}>
          <FastField
            name="zipCode"
            render={({ field, form }: FieldProps<NCompany>) => (
              <TextField
                label={t('newnity.company.address.zipCode')}
                {...field}
                value={field.value || ''}
                autoComplete={'deny'}
                className={classes.formField}
                margin="normal"
              />
            )}
          />
          <FastField
            name="streetAddress"
            render={({ field, form }: FieldProps<NCompany>) => (
              <TextField
                multiline
                label={t('newnity.company.address.streetAddress')}
                {...field}
                value={field.value || ''}
                autoComplete={'deny'}
                className={classes.formField}
                margin="normal"
              />
            )}
          />
        </div>
      </div>
      <div className={classes.horizontalContainer}>
        <div className={classNames([classes.vertical, classes.marginRight])}>
          <FastField
            name="firstName"
            render={({ field, form }: FieldProps<NCompany>) => (
              <TextField
                label={t('newnity.company.contact.firstName')}
                {...field}
                value={field.value || ''}
                autoComplete={'deny'}
                className={classes.formField}
                margin="normal"
              />
            )}
          />
          <FastField
            name="lastName"
            render={({ field, form }: FieldProps<NCompany>) => (
              <TextField
                label={t('newnity.company.contact.lastName')}
                {...field}
                value={field.value || ''}
                autoComplete={'deny'}
                className={classes.formField}
                margin="normal"
              />
            )}
          />
        </div>
        <div className={classNames([classes.vertical, classes.marginLeft])}>
          <FastField
            name="phone"
            render={({ field, form }: FieldProps<NCompany>) => (
              <TextField
                label={t('newnity.company.contact.phone')}
                {...field}
                value={field.value || ''}
                autoComplete={'deny'}
                className={classes.formField}
                margin="normal"
              />
            )}
          />
          <FastField
            name="email"
            render={({ field, form }: FieldProps<NCompany>) => (
              <TextField
                label={t('newnity.company.contact.email')}
                {...field}
                value={field.value || ''}
                autoComplete={'deny'}
                className={classes.formField}
                margin="normal"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
