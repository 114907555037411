import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Dispatch } from "redux";
import { cancelCloseBlade, forceCloseBlade, setBladeNewlyCreateState, setBladeDirtyState } from "../duck/actions";
import {
  selectActiveBladesDenormalized,
  selectBladeClosing,
  selectFrozenBlades
} from "../duck/selectors";
import { BladeRow, BladeRowActions, BladeRowProps } from "./bladeRow";

export const mapStateToProps: MapStateToProps<BladeRowProps, any, any> = (
  state,
  _
) => {
  return {
    blades: selectActiveBladesDenormalized(state),
    ...selectBladeClosing(state),
    highlightedBlades: selectFrozenBlades(state)
  };
};

export const mapDispatchToProps: MapDispatchToProps<BladeRowActions, any> = (
  dispatch: Dispatch
) => {
  return {
    forceCloseBlade: (bladeId: string) => dispatch(forceCloseBlade(bladeId)),
    cancelBladeClose: () => dispatch(cancelCloseBlade()),
    setBladeNewlyCreateState: (bladeId: string, isNewlyCreated: boolean) => dispatch(setBladeNewlyCreateState(bladeId, isNewlyCreated)),
    setBladeDirty: (bladeId: string, dirty: boolean) => dispatch(setBladeDirtyState(bladeId, dirty))
  };
};

export const BladeRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BladeRow);
