import { createSelector } from 'reselect';
import { getDataStore } from './genericSelectors';
import memoizeOne from 'memoize-one';
import { Player } from '../types';

export const selectPlayers = (state: any) => getDataStore(state).player.items;

export const selectPlayerItems = createSelector(
  [selectPlayers],
  players => {
    return Object.keys(players).map(key => players[Number(key)]);
  }
);

export const selectPlayersForWorkgroups = createSelector(
  [selectPlayerItems],
  (players: Player[]) =>
    memoizeOne((workgroupIds: number[]) =>
      players.filter(player => workgroupIds.indexOf(player.workgroupId) !== -1)
    )
);

export const selectPlayersForSites = createSelector(
  [selectPlayerItems],
  (players: Player[]) =>
    memoizeOne((siteIds: number[]) =>
      players.filter(player => siteIds.indexOf(player.siteId) !== -1)
    )
);
