import { TreeState } from '../../components/tree';
import { TreeItem } from '../../components/tree/duck/types';

export const areaName = 'workgroup';
export interface WorkgroupState {
  favoriteItems: number[];
  workgroupsTree: TreeState;
  pickSelection: {
    on: boolean;
    selectedWorkgroupId: number;
    selectedTreeItemId: string;
    previousTreeSelection: {};
  };
  createWorkgroup: {
    isSaving: boolean;
    savingError: string;
  };
}

export interface WorkgroupCreateObject {
  ParentId: number;
  BrandId: number;
  Name: string;
  LogoPath: string;
  TemplateLibraries: number[];
}

export enum WorkgroupTreeActionNames {
  ToggleFavorite = 'ToggleFavorite'
}

export enum ActionTypes {
  WORKGROUP_LIST_REQUEST = 'WORKGROUP_LIST_REQUEST',
  WORKGROUP_LIST_SUCCESS = 'WORKGROUP_LIST_SUCCESS',
  WORKGROUP_LIST_ERROR = 'WORKGROUP_LIST_ERROR',

  WORKGROUP_LIST_ENTER_PICK_MODE = 'WORKGROUP_LIST_ENTER_PICK_MODE',
  WORKGROUP_LIST_LEAVE_PICK_MODE = 'WORKGROUP_LIST_LEAVE_PICK_MODE',

  WORKGROUP_FAVORITE_REQUEST = 'WORKGROUP_FAVORITE_REQUEST',
  WORKGROUP_FAVORITE_SUCCESS = 'WORKGROUP_FAVORITE_SUCCESS',
  WORKGROUP_FAVORITE_ERROR = 'WORKGROUP_FAVORITE_ERROR',

  WORKGROUP_UNFAVORITE_REQUEST = 'WORKGROUP_UNFAVORITE_REQUEST',
  WORKGROUP_UNFAVORITE_SUCCESS = 'WORKGROUP_UNFAVORITE_SUCCESS',
  WORKGROUP_UNFAVORITE_ERROR = 'WORKGROUP_UNFAVORITE_ERROR',

  TEMPLATE_LIBRARIES_REQUEST = 'TEMPLATE_LIBRARIES_REQUEST',
  TEMPLATE_LIBRARIES_SUCCESS = 'TEMPLATE_LIBRARIES_SUCCESS',
  TEMPLATE_LIBRARIES_ERROR = 'TEMPLATE_LIBRARIES_ERROR',
  TEMPLATE_LIBRARIES_SELECT = 'TEMPLATE_LIBRARIES_SELECT',
  TEMPLATE_LIBRARIES_CANCEL = 'TEMPLATE_LIBRARIES_CANCEL',
  TEMPLATE_LIBRARIES_SAVE = 'TEMPLATE_LIBRARIES_SAVE',

  CREATE_WORKGROUP_REQUEST = 'CREATE_WORKGROUP_REQUEST',
  CREATE_WORKGROUP_SUCCESS = 'CREATE_WORKGROUP_SUCCESS',
  CREATE_WORKGROUP_ERROR = 'CREATE_WORKGROUP_ERROR',
  CREATE_WORKGROUP_CLEANUP = 'CREATE_WORKGROUP_CLEANUP',

}
