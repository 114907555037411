import React, { useState, memo } from 'react';
import { HeaderAndContent, HeaderAndContentProps } from './headerAndContent';
import { useStyles } from './tabsHeaderAndContent.jss';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export interface TabItem {
  label: string;
}

export interface TabsHeaderAndContentProps
  extends Pick<
    HeaderAndContentProps,
    Exclude<keyof HeaderAndContentProps, 'header' | 'content'>
  > {
  tabs: TabItem[];
  content: (selectedTabIndex: number) => JSX.Element;
}

export const TabsHeaderAndContent = (props: TabsHeaderAndContentProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const classes = useStyles();

  const header = () => (
    <Tabs
      className={classes.tabs}
      value={selectedTabIndex}
      onChange={(_, value: number) => setSelectedTabIndex(value)}
    >
      {props.tabs.map((t, i) => (
        <Tab key={`Tab${i}`} label={t.label} />
      ))}
    </Tabs>
  );

  return (
    <HeaderAndContent
      header={header}
      disabled={props.disabled}
      content={() => props.content(selectedTabIndex)}
      footer={props.footer}
      removePadding={props.removePadding}
      isBusy={props.isBusy}
    />
  );
};
