import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) => createStyles({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  headerButton: {
    marginRight: theme.spacing.unit
  },
  headerButtonIconSmall: {
    fontSize: 20,
    marginRight: theme.spacing.unit
  },
});

export const useStyles = makeStyles(styles);

