import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing.unit * 2,
     // zIndex: 2,
      minHeight: '36px',
      boxSizing: 'content-box'
    }
  });

export const useStyles = makeStyles(styles);
