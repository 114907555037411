import { connect } from 'react-redux';
import { IdName, DataStoreSelectors } from '../../dataStore';
import { DataGrid, DataGridProps, DataGridStateProps } from './dataGrid';

export interface DataGridContainerProps extends DataGridProps {
  items?: IdName[];
}

const mapStateToProps: (
  state: any,
  ownProps: DataGridContainerProps
) => DataGridStateProps & DataGridProps = (state, ownProps) => {
  if (!ownProps.items) {
    return {
      ...ownProps,
      items: DataStoreSelectors.getDataStoreItemsDenormalized(state, ownProps.entityType),
    };
  } else {
    return { ...ownProps, items: ownProps.items };
  }
};

export const DataGridContainer = connect(mapStateToProps)(DataGrid);
