import React from "react";
import { TreeItem } from "./duck/types";
import { IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";

interface ExpandButtonProps {
  item: TreeItem;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ExpandButton = ({ item, onClick }: ExpandButtonProps) => (
  <IconButton
    data-key={item.treeId}
    data-expanded={item.isExpanded}
    centerRipple={true}
    onClick={onClick}
    style={{ padding: "2px" }}
  >
    {item.isExpanded ? (
      <KeyboardArrowDown color="secondary" />
    ) : (
      <KeyboardArrowRight />
    )}
  </IconButton>
);
