export const timezones = [
  {
    id: 0,
    name: '(GMT-11:00) American Samoa',
  },
  {
    id: 1,
    name: '(GMT-11:00) International Date Line West',
  },
  {
    id: 2,
    name: '(GMT-11:00) Midway Island',
  },
  {
    id: 3,
    name: '(GMT-10:00) Hawaii',
  },
  {
    id: 4,
    name: '(GMT-09:00) Alaska',
  },
  {
    id: 5,
    name: '(GMT-08:00) Pacific Time (US & Canada)',
  },
  {
    id: 6,
    name: '(GMT-08:00) Tijuana',
  },
  {
    id: 7,
    name: '(GMT-07:00) Arizona',
  },
  {
    id: 8,
    name: '(GMT-07:00) Chihuahua',
  },
  {
    id: 9,
    name: '(GMT-07:00) Mazatlan',
  },
  {
    id: 10,
    name: '(GMT-07:00) Mountain Time (US & Canada)',
  },
  {
    id: 11,
    name: '(GMT-06:00) Central America',
  },
  {
    id: 12,
    name: '(GMT-06:00) Central Time (US & Canada)',
  },
  {
    id: 13,
    name: '(GMT-06:00) Guadalajara',
  },
  {
    id: 14,
    name: '(GMT-06:00) Mexico City',
  },
  {
    id: 15,
    name: '(GMT-06:00) Monterrey',
  },
  {
    id: 16,
    name: '(GMT-06:00) Saskatchewan',
  },
  {
    id: 17,
    name: '(GMT-05:00) Bogota',
  },
  {
    id: 18,
    name: '(GMT-05:00) Eastern Time (US & Canada)',
  },
  {
    id: 19,
    name: '(GMT-05:00) Indiana (East)',
  },
  {
    id: 20,
    name: '(GMT-05:00) Lima',
  },
  {
    id: 21,
    name: '(GMT-05:00) Quito',
  },
  {
    id: 22,
    name: '(GMT-04:00) Atlantic Time (Canada)',
  },
  {
    id: 23,
    name: '(GMT-04:00) Caracas',
  },
  {
    id: 24,
    name: '(GMT-04:00) Georgetown',
  },
  {
    id: 25,
    name: '(GMT-04:00) La Paz',
  },
  {
    id: 26,
    name: '(GMT-04:00) Santiago',
  },
  {
    id: 27,
    name: '(GMT-03:30) Newfoundland',
  },
  {
    id: 28,
    name: '(GMT-03:00) Brasilia',
  },
  {
    id: 29,
    name: '(GMT-03:00) Buenos Aires',
  },
  {
    id: 30,
    name: '(GMT-03:00) Greenland',
  },
  {
    id: 31,
    name: '(GMT-03:00) Montevideo',
  },
  {
    id: 32,
    name: '(GMT-02:00) Mid-Atlantic',
  },
  {
    id: 33,
    name: '(GMT-01:00) Azores',
  },
  {
    id: 34,
    name: '(GMT-01:00) Cape Verde Is.',
  },
  {
    id: 35,
    name: '(GMT+00:00) Edinburgh',
  },
  {
    id: 36,
    name: '(GMT+00:00) Lisbon',
  },
  {
    id: 37,
    name: '(GMT+00:00) London',
  },
  {
    id: 38,
    name: '(GMT+00:00) Monrovia',
  },
  {
    id: 39,
    name: '(GMT+00:00) UTC',
  },
  {
    id: 40,
    name: '(GMT+01:00) Amsterdam',
  },
  {
    id: 41,
    name: '(GMT+01:00) Belgrade',
  },
  {
    id: 42,
    name: '(GMT+01:00) Berlin',
  },
  {
    id: 43,
    name: '(GMT+01:00) Bern',
  },
  {
    id: 44,
    name: '(GMT+01:00) Bratislava',
  },
  {
    id: 45,
    name: '(GMT+01:00) Brussels',
  },
  {
    id: 46,
    name: '(GMT+01:00) Budapest',
  },
  {
    id: 47,
    name: '(GMT+01:00) Casablanca',
  },
  {
    id: 48,
    name: '(GMT+01:00) Copenhagen',
  },
  {
    id: 49,
    name: '(GMT+01:00) Dublin',
  },
  {
    id: 50,
    name: '(GMT+01:00) Europe/Berlin',
  },
  {
    id: 51,
    name: '(GMT+01:00) Ljubljana',
  },
  {
    id: 52,
    name: '(GMT+01:00) Madrid',
  },
  {
    id: 53,
    name: '(GMT+01:00) Paris',
  },
  {
    id: 54,
    name: '(GMT+01:00) Prague',
  },
  {
    id: 55,
    name: '(GMT+01:00) Rome',
  },
  {
    id: 56,
    name: '(GMT+01:00) Sarajevo',
  },
  {
    id: 57,
    name: '(GMT+01:00) Skopje',
  },
  {
    id: 58,
    name: '(GMT+01:00) Stockholm',
  },
  {
    id: 59,
    name: '(GMT+01:00) Vienna',
  },
  {
    id: 60,
    name: '(GMT+01:00) Warsaw',
  },
  {
    id: 61,
    name: '(GMT+01:00) West Central Africa',
  },
  {
    id: 62,
    name: '(GMT+01:00) Zagreb',
  },
  {
    id: 63,
    name: '(GMT+01:00) Zurich',
  },
  {
    id: 64,
    name: '(GMT+02:00) Athens',
  },
  {
    id: 65,
    name: '(GMT+02:00) Bucharest',
  },
  {
    id: 66,
    name: '(GMT+02:00) Cairo',
  },
  {
    id: 67,
    name: '(GMT+02:00) Harare',
  },
  {
    id: 68,
    name: '(GMT+02:00) Helsinki',
  },
  {
    id: 69,
    name: '(GMT+02:00) Jerusalem',
  },
  {
    id: 70,
    name: '(GMT+02:00) Kaliningrad',
  },
  {
    id: 71,
    name: '(GMT+02:00) Kyiv',
  },
  {
    id: 72,
    name: '(GMT+02:00) Pretoria',
  },
  {
    id: 73,
    name: '(GMT+02:00) Riga',
  },
  {
    id: 74,
    name: '(GMT+02:00) Sofia',
  },
  {
    id: 75,
    name: '(GMT+02:00) Tallinn',
  },
  {
    id: 76,
    name: '(GMT+02:00) Vilnius',
  },
  {
    id: 77,
    name: '(GMT+03:00) Baghdad',
  },
  {
    id: 78,
    name: '(GMT+03:00) Istanbul',
  },
  {
    id: 79,
    name: '(GMT+03:00) Kuwait',
  },
  {
    id: 80,
    name: '(GMT+03:00) Minsk',
  },
  {
    id: 81,
    name: '(GMT+03:00) Moscow',
  },
  {
    id: 82,
    name: '(GMT+03:00) Nairobi',
  },
  {
    id: 83,
    name: '(GMT+03:00) Riyadh',
  },
  {
    id: 84,
    name: '(GMT+03:00) St. Petersburg',
  },
  {
    id: 85,
    name: '(GMT+03:30) Tehran',
  },
  {
    id: 86,
    name: '(GMT+04:00) Abu Dhabi',
  },
  {
    id: 87,
    name: '(GMT+04:00) Baku',
  },
  {
    id: 88,
    name: '(GMT+04:00) Muscat',
  },
  {
    id: 89,
    name: '(GMT+04:00) Samara',
  },
  {
    id: 90,
    name: '(GMT+04:00) Tbilisi',
  },
  {
    id: 91,
    name: '(GMT+04:00) Volgograd',
  },
  {
    id: 92,
    name: '(GMT+04:00) Yerevan',
  },
  {
    id: 93,
    name: '(GMT+04:30) Kabul',
  },
  {
    id: 94,
    name: '(GMT+05:00) Ekaterinburg',
  },
  {
    id: 95,
    name: '(GMT+05:00) Islamabad',
  },
  {
    id: 96,
    name: '(GMT+05:00) Karachi',
  },
  {
    id: 97,
    name: '(GMT+05:00) Tashkent',
  },
  {
    id: 98,
    name: '(GMT+05:30) Chennai',
  },
  {
    id: 99,
    name: '(GMT+05:30) Kolkata',
  },
  {
    id: 100,
    name: '(GMT+05:30) Mumbai',
  },
  {
    id: 101,
    name: '(GMT+05:30) New Delhi',
  },
  {
    id: 102,
    name: '(GMT+05:30) Sri Jayawardenepura',
  },
  {
    id: 103,
    name: '(GMT+05:45) Kathmandu',
  },
  {
    id: 104,
    name: '(GMT+06:00) Almaty',
  },
  {
    id: 105,
    name: '(GMT+06:00) Astana',
  },
  {
    id: 106,
    name: '(GMT+06:00) Dhaka',
  },
  {
    id: 107,
    name: '(GMT+06:00) Urumqi',
  },
  {
    id: 108,
    name: '(GMT+06:30) Rangoon',
  },
  {
    id: 109,
    name: '(GMT+07:00) Bangkok',
  },
  {
    id: 110,
    name: '(GMT+07:00) Hanoi',
  },
  {
    id: 111,
    name: '(GMT+07:00) Jakarta',
  },
  {
    id: 112,
    name: '(GMT+07:00) Krasnoyarsk',
  },
  {
    id: 113,
    name: '(GMT+07:00) Novosibirsk',
  },
  {
    id: 114,
    name: '(GMT+08:00) Beijing',
  },
  {
    id: 115,
    name: '(GMT+08:00) Chongqing',
  },
  {
    id: 116,
    name: '(GMT+08:00) Hong Kong',
  },
  {
    id: 117,
    name: '(GMT+08:00) Irkutsk',
  },
  {
    id: 118,
    name: '(GMT+08:00) Kuala Lumpur',
  },
  {
    id: 119,
    name: '(GMT+08:00) Perth',
  },
  {
    id: 120,
    name: '(GMT+08:00) Singapore',
  },
  {
    id: 121,
    name: '(GMT+08:00) Taipei',
  },
  {
    id: 122,
    name: '(GMT+08:00) Ulaanbaatar',
  },
  {
    id: 123,
    name: '(GMT+09:00) Osaka',
  },
  {
    id: 124,
    name: '(GMT+09:00) Sapporo',
  },
  {
    id: 125,
    name: '(GMT+09:00) Seoul',
  },
  {
    id: 126,
    name: '(GMT+09:00) Tokyo',
  },
  {
    id: 127,
    name: '(GMT+09:00) Yakutsk',
  },
  {
    id: 128,
    name: '(GMT+09:30) Adelaide',
  },
  {
    id: 129,
    name: '(GMT+09:30) Darwin',
  },
  {
    id: 130,
    name: '(GMT+10:00) Brisbane',
  },
  {
    id: 131,
    name: '(GMT+10:00) Canberra',
  },
  {
    id: 132,
    name: '(GMT+10:00) Guam',
  },
  {
    id: 133,
    name: '(GMT+10:00) Hobart',
  },
  {
    id: 134,
    name: '(GMT+10:00) Melbourne',
  },
  {
    id: 135,
    name: '(GMT+10:00) Port Moresby',
  },
  {
    id: 136,
    name: '(GMT+10:00) Sydney',
  },
  {
    id: 137,
    name: '(GMT+10:00) Vladivostok',
  },
  {
    id: 138,
    name: '(GMT+11:00) Magadan',
  },
  {
    id: 139,
    name: '(GMT+11:00) New Caledonia',
  },
  {
    id: 140,
    name: '(GMT+11:00) Solomon Is.',
  },
  {
    id: 141,
    name: '(GMT+11:00) Srednekolymsk',
  },
  {
    id: 142,
    name: '(GMT+12:00) Auckland',
  },
  {
    id: 143,
    name: '(GMT+12:00) Fiji',
  },
  {
    id: 144,
    name: '(GMT+12:00) Kamchatka',
  },
  {
    id: 145,
    name: '(GMT+12:00) Marshall Is.',
  },
  {
    id: 146,
    name: '(GMT+12:00) Wellington',
  },
  {
    id: 147,
    name: '(GMT+12:45) Chatham Is.',
  },
  {
    id: 148,
    name: "(GMT+13:00) Nuku'alofa",
  },
  {
    id: 149,
    name: '(GMT+13:00) Samoa',
  },
  {
    id: 150,
    name: '(GMT+13:00) Tokelau Is.',
  },
];
