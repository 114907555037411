import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) => {
  const tabGrey = theme.palette.grey[400];
  const borderStyle = `1px solid ${tabGrey}`;
  
  return createStyles({
    tabsIndicator: {
      height: "0px"
    },
    tabRoot: {
      borderBottom: borderStyle,
      borderRadius: "4px 4px 0 0",
    },
    tabLabelContainer2: {
      width: "240px"
    },
    tabSelected: {
      border: borderStyle,
      borderBottomWidth: 0,
    },
    tabFirstSelected: {
      border: borderStyle,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
    },
    tabLastSelected: {
      border: borderStyle,
      borderBottomWidth: 0,
      borderRightWidth: 0
    },
    tabContent: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: theme.spacing.unit,
    },
    selectMargin: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit / 2
    },
  });
};

export const useStyles = makeStyles(styles);

