import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { BladeHeader, BladeHeaderActions, BladeHeaderProps } from './bladeHeader';
import { closeBlade } from './duck/actions';

export const mapStateToProps: MapStateToProps<BladeHeaderProps, BladeHeaderProps, any> = (state, ownProps) => {
    return {...ownProps};
}

export const mapDispatchToProps: MapDispatchToProps<BladeHeaderActions, BladeHeaderProps> = (dispatch: Dispatch) => ({
    closeBlade: (bladeId: string) => dispatch(closeBlade(bladeId))
})

export const BladeHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(BladeHeader);
