import React from 'react';
import { useStyles } from './results.jss';
import {
  DataGridContainer,
  DataGridSelectionType,
} from '../../../../components/dataGrid';
import { EntityType, IdName } from '../../../../dataStore';
import { Placeholder } from './placeholder';
import {
  NLocationSearchResult,
  NDeviceSearchResult,
} from '../../../../dataStore/types';
import { useTranslation } from 'react-i18next';
import { NoResults } from './noresults';
import { Loading } from './loading';
import { LocationListBladeType } from '../../..';
import { LocationEditBladeType } from '../../location/edit/locationEditContainer';
import { CompanyBladeType } from '../../company';

export interface ResultsActions {
  enterCompanyMode: (id: number, name: string) => void;
  openBlade: (parent: string, bladeType: string, props: object) => void;
  setSelectedLocations: (items: IdName[]) => void;
}

export interface ResultsProps {
  searchedEntity?: EntityType;
  isFetching: boolean;
  itemsCount: number;
}

const columnsConfig = {
  [EntityType.NCompanySearchResult]: ['number', 'name', 'city', 'country'],
  [EntityType.NLocationSearchResult]: [
    'locationNumber',
    'locationName',
    'siteNumber',
    'companyName',
  ],
  [EntityType.NDeviceSearchResult]: [
    'id',
    'serial',
    'salesOrder',
    'deviceDescription',
    'locationName',
    'locationNumber',
    'siteNumber',
    'companyName',
  ],
};

export const Results = (props: ResultsProps & ResultsActions) => {
  const classes = useStyles();
  const [] = useTranslation();

  const onSelect = (item: IdName) => {
    switch (props.searchedEntity) {
      case EntityType.NCompanySearchResult:
        props.enterCompanyMode(item.id, item.name);
        break;
      case EntityType.NLocationSearchResult:
        const locationItem = item as NLocationSearchResult;
        props.enterCompanyMode(locationItem.companyId, locationItem.companyName);
        props.openBlade(CompanyBladeType, LocationListBladeType, {});
        props.setSelectedLocations([{id: locationItem.id, name: locationItem.locationName}]);
        props.openBlade(LocationListBladeType, LocationEditBladeType, {
          locationSiteId: locationItem.id,
          locationName: locationItem.locationName
        });
        break;
      case EntityType.NDeviceSearchResult:
        const deviceItem = item as NDeviceSearchResult;
        props.enterCompanyMode(deviceItem.companyId, deviceItem.companyName);
        break;
      default:
        break;
    }
  };

  const content = () => {
    if (props.searchedEntity === undefined) {
      return <Placeholder />;
    } 
      
    if (props.isFetching) {
      return <Loading />;
    }

    if (props.itemsCount === 0) {
      return <NoResults />;
    }

    return (
      <div className={classes.gridContainer}>
        <DataGridContainer
          entityType={props.searchedEntity}
          onSelect={items => onSelect(items[0])}
          selectionType={DataGridSelectionType.Single}
          showSelectionColumn={false}
          //@ts-ignore
          visibleColumns={columnsConfig[props.searchedEntity]}
        />
      </div>
    );
  }

  return ( 
    <div className={classes.resultsContainer}>
      {content()}
    </div>
  );
};
