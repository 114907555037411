import React from 'react';
import { useStyles } from './tabs.jss';
import { Tabs, Tab } from '@material-ui/core';
import { CompanyTab } from './companyTab';
import { AddressTab } from './addressTab';
import { PhoneTab } from './phoneTab';
import { useTranslation } from 'react-i18next';
import { SearchFields } from '../../../duck/types';
import { LocationTab } from './locationTab';
import { DeviceTab } from './deviceTab';
import { EntityType, IdName } from '../../../../dataStore';

export interface TabsProps {
  entity: EntityType;
  fields: SearchFields;
  onChange(key: string, value: any): void;
  onTabChange(index: number): void;
  tabIndex: number;
  companies: IdName[];
}

const TabsComponent = (props: TabsProps) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const tabs = () => {
    switch (props.entity) {
      case EntityType.NCompanySearchResult:
        return [
          <Tab
            key="1.1"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabFirstSelected,
            }}
            label={t('newnity.company')}
          />,
          <Tab
            key="1.2"
            classes={{ 
              root: classes.tabRoot, 
              selected: classes.tabSelected }}
            label={t('newnity.search.tab.company.address')}
          />,
          <Tab
            key="1.3"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabLastSelected,
            }}
            label={t('newnity.phone')}
          />,
        ];
      case EntityType.NLocationSearchResult:
        return [
          <Tab
            key="2.1"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabFirstSelected,
              labelContainer: classes.tabLabelContainer2,
            }}
            label={t('newnity.location')}
          />,
          <Tab
            key="2.2"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
              labelContainer: classes.tabLabelContainer2,
            }}
            label={t('newnity.search.locationAddress')}
          />,
        ];
      case EntityType.NDeviceSearchResult:
        return [
          <Tab
            key="3.1"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabFirstSelected,
              labelContainer: classes.tabLabelContainer2,
            }}
            label={t('newnity.device')}
          />,
          <Tab
            key="3.2"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
              labelContainer: classes.tabLabelContainer2,
            }}
            label={t('newnity.search.locationAddress')}
          />,
        ];
    }
  };

  const tabContent = () => {
    switch (props.entity) {
      case EntityType.NCompanySearchResult:
        return (
          <React.Fragment>
            {props.tabIndex === 0 && (
              <CompanyTab
                {...props.fields.company}
                onChange={(k, v) => props.onChange(`company.${k}`, v)}
              />
            )}
            {props.tabIndex === 1 && (
              <AddressTab
                address={props.fields.company.address}
                city={props.fields.company.city}
                state={props.fields.company.state}
                zip={props.fields.company.zip}
                country={props.fields.company.country}
                onChange={(k, v) => props.onChange(`company.${k}`, v)}
              />
            )}
            {props.tabIndex === 2 && (
              <PhoneTab
                phone={props.fields.company.phone}
                email={props.fields.company.email}
                firstName={props.fields.company.firstName}
                lastName={props.fields.company.lastName}
                onChange={(k, v) => props.onChange(`company.${k}`, v)}
              />
            )}
          </React.Fragment>
        );
      case EntityType.NLocationSearchResult:
        return (
          <React.Fragment>
            {props.tabIndex === 0 && (
              <LocationTab
                {...props.fields.location}
                onChange={(k, v) => props.onChange(`location.${k}`, v)}
                companies={props.companies}
              />
            )}
            {props.tabIndex === 1 && (
              <AddressTab
                address={props.fields.location.address}
                city={props.fields.location.city}
                state={props.fields.location.state}
                zip={props.fields.location.zip}
                country={props.fields.location.country}
                onChange={(k, v) => props.onChange(`location.${k}`, v)}
              />
            )}
          </React.Fragment>
        );
      case EntityType.NDeviceSearchResult:
        return (
          <React.Fragment>
            {props.tabIndex === 0 && (
              <DeviceTab
                {...props.fields.device}
                onChange={(k, v) => props.onChange(`device.${k}`, v)}
              />
            )}
            {props.tabIndex === 1 && (
              <AddressTab
                address={props.fields.location.address}
                city={props.fields.location.city}
                state={props.fields.location.state}
                zip={props.fields.location.zip}
                country={props.fields.location.country}
                onChange={(k, v) => props.onChange(`location.${k}`, v)}
              />
            )}
          </React.Fragment>
        );
      default:
        return <div />;
    }
  };

  return (
    <React.Fragment>
      <Tabs
        value={props.tabIndex}
        onChange={(e, v) => props.onTabChange(v)}
        textColor="primary"
        variant="fullWidth"
        classes={{ indicator: classes.tabsIndicator }}
      >
        {tabs()}
      </Tabs>
      {tabContent()}
    </React.Fragment>
  );
};

export { TabsComponent as Tabs };
