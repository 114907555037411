import { DataStoreState, EntityType, areaName, IdName } from '../types';
import { NormalizedIdNames } from '..';
import { createSelector } from 'reselect';

export const getDataStore = (state: any) => state[areaName] as DataStoreState;

export const getDataStoreItems = (state: any, entityType: EntityType) => {
  return (state[areaName] as DataStoreState)[entityType as keyof DataStoreState]
    .items as NormalizedIdNames<IdName>;
};

export const getDataStoreItemsDenormalized = createSelector(
  [getDataStoreItems],
  normalizedItems => {
    return Object.keys(normalizedItems).map(
      key => normalizedItems[Number(key)]
    );
  }
);

export const getDataStoreItemsFetching = (
  state: any,
  entityType: EntityType
) => {
  const key = entityType as keyof DataStoreState;
  return (state[areaName] as DataStoreState)[key].itemsFetching;
};
