import { observer, observe } from '../utils/redux';
import { areaName as newnityArea } from '../newnity';
import { setBladeDirtyState } from '../blade/duck/actions';
import { CompanyBladeType } from '../newnity/blades/company';
import { Store } from 'redux';
import { areaName as dataStoreArea } from '../dataStore';

export const initObservers = (store: Store) => {

  let companySaveSuccess = false;
  let currentCompanyId = 0;

  const companyUpsertSuccessObserver = observer(
    state => state[dataStoreArea].nCompany.items,
    (dispatch, current, previous) => {
      if (companySaveSuccess && currentCompanyId > 0) {
        //dispatch(changeBladeTitle(CompanyBladeType, current[currentCompanyId].name))
        //dispatch(setBladeDirtyState(CompanyBladeType, false))
      }
    }
  );

  const companySaveSuccessObserver = observer(
    state => state[newnityArea].currentCompany,
    (dispatch, current, previous) => {
      companySaveSuccess = previous.isSaving && !current.isSaving && !current.savingError;
      currentCompanyId = current.companyId;
    }
  )


  return observe(store, [companySaveSuccessObserver, companyUpsertSuccessObserver]);
}


