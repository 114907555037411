import { FormikProps, FormikHandlers } from 'formik';

export const validateEmptyField = <T extends {}>(form: FormikProps<T>, field: {
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  value: any;
  name: string;
}): boolean | undefined => {
  return (
    (form.touched as any)[field.name] &&
    !field.value &&
    (form.errors as any)[field.name] !== undefined &&
    (form.errors as any)[field.name].length > 0
  );
}

export const helperText = <T extends {}>(form: FormikProps<T>, field: {
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  value: any;
  name: string;
}, text: string | undefined) => {
  if ((form.touched as any)[field.name] && !field.value) {
    return text;
  }

  return undefined;
}