import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing.unit * 2,
      // paddingLeft: theme.spacing.unit * 2,
      display: "flex",
      alignItems: "center",
      //zIndex: 2
    },
    dirtyIcon: {
      marginRight: theme.spacing.unit,
    },
    title: {
      flexGrow: 1,
      marginRight: theme.spacing.unit * 2
    },
    closeButton: {
      padding: '4px'
    }
  });

export const useStyles = makeStyles(styles);